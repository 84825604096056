import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  ReferenceLine,
  Label,
  ReferenceArea,
} from "recharts";
import { Spinner } from "reactstrap";
import { colors as muiColor } from "@mui/material";

const colors = {
  Temperature: "#f3c363",
  "Moisture 10 cm underground": "#8884d8",
  "Moisture 100 cm underground": "#82ca9d",
  "Moisture 150 cm underground": "#fc3903",
  Moisture: "#8884d8",
};

const referenceAreaColor = [
  muiColor.orange.A700,
  muiColor.lime.A700,
  muiColor.yellow[800],
  muiColor.cyan.A700,
];

const unitTransformer = {
  Voltage: "V",
  Temperature: "℃",
  Moisture: "%",
  "Prediction Moisture": "%",
  "Moisture 10 cm underground": "%",
  "Moisture 100 cm underground": "%",
  "Moisture 150 cm underground": "%",
  "Prediction of Moisture 10 cm underground": "%",
  "Prediction of Moisture 100 cm underground": "%",
  "Prediction of Moisture 150 cm underground": "%",
  "Prediction Temperature": "℃",
  Precipitation: "mm",
  Evaporation: "mm",
};

const nameWP = {
  "Moisture 10 cm underground": "wpoint_50",
  "Moisture 100 cm underground": "wpoint_100",
  "Moisture 150 cm underground": "wpoint_150",
  Moisture: "wpoint_50",
};

const nameFC = {
  "Moisture 10 cm underground": "fcapacity_50",
  "Moisture 100 cm underground": "fcapacity_100",
  "Moisture 150 cm underground": "fcapacity_150",
  Moisture: "wpoint_50",
};

const nameSaturation = {
  "Moisture 10 cm underground": "saturation_50",
  "Moisture 100 cm underground": "saturation_100",
  "Moisture 150 cm underground": "saturation_150",
  Moisture: "wpoint_50",
};

const abbrevOneDepth = {
  Temperature: "Soil Temperature",
  "Prediction Temperature": "Prediction Temperature",
  Moisture: "Moisture",
  "Prediction of Moisture 10 cm underground": "Prediction 10 cm",
  "Prediction of Moisture 100 cm underground": "Prediction 100 cm",
  "Prediction of Moisture 150 cm underground": "Prediction 150 cm",
  Voltage: "Voltage",
  Evaporation: "Evaporation",
};
const abbrevThreeDepth = {
  Temperature: "Soil Temperature",
  "Prediction Temperature": "Prediction Temperature",
  "Moisture 10 cm underground": "Moisture 30-50 cm",
  "Moisture 100 cm underground": "Moisture 100 cm",
  "Moisture 150 cm underground": "Moisture 150 cm",
  "Prediction of Moisture 10 cm underground": "Prediction 10 cm",
  "Prediction of Moisture 100 cm underground": "Prediction 100 cm",
  "Prediction of Moisture 150 cm underground": "Prediction 150 cm",
  Voltage: "Voltage",
  Evaporation: "Evaporation",
};

const monthTrans = {
  Jan: 1,
  Feb: 2,
  Mar: 3,
  Apr: 4,
  May: 5,
  Jun: 6,
  Jul: 7,
  Aug: 8,
  Sep: 9,
  Oct: 10,
  Nov: 11,
  Dec: 12,
};

const oneDigit = (value) => {
  if (typeof value === "number") {
    value = value.toFixed(1);
  }
  return value;
};

class SimpleLineChart extends PureComponent {
  static propTypes = {
    data: PropTypes.array,
    keys: PropTypes.array,
    unit: PropTypes.string,
    tick: PropTypes.object,
    unitRight: PropTypes.string,
    loading: PropTypes.bool,
    type: PropTypes.string,
    sensor: PropTypes.string,
  };
  generateRandom = () => {
    if (Math.random() < 0.5) {
      return Math.random() * 1.1 + 16;
    } else {
      return -(Math.random() * 1.1) + 16;
    }
  };

  generateRandomTemper = () => {
    if (Math.random() < 0.5) {
      return Math.random() * 5 + 10;
    } else {
      return -(Math.random() * 5) + 10;
    }
  };

  formatToSeries = (data, keys, content, predictionData) => {
    // 用keys控制要哪些資料
    var keys_array = [];
    var measure = [];
    var measure_50 = [];
    var measure_100 = [];
    var measure_150 = [];
    var temperature_array = [];
    var prediction = [];
    var prediction_50 = [];
    var prediction_100 = [];
    var prediction_150 = [];
    var prediction_temperature = [];
    var series = [];

    const moistture_depth_color = ["#f3c363", "#82ca9d", "#8884d8"];
    const moistture_depth_prediction_color = ["#db63f3", "#fc3903"];

    var pData_ST_arr = [];
    var pData_MD_arr = [];

    if (typeof predictionData !== "undefined") {
      // prediction data
      var pData = predictionData["data"];
      var pDataKeys = Object.keys(pData);
      if (pDataKeys.length > 0) {
        // ST = soil-moisture temperature
        // connect line for measure to prediction line
        const last_data = data[data.length - 1];

        pData_ST_arr.push({
          Moisture: last_data["Moisture"],
          "Moisture 10 cm underground": last_data["Moisture 10 cm underground"]
            ? last_data["Moisture 10 cm underground"]
            : last_data["Moisture"],
          "Moisture 100 cm underground": last_data[
            "Moisture 100 cm underground"
          ]
            ? last_data["Moisture 100 cm underground"]
            : last_data["Moisture"],
          "Moisture 150 cm underground": last_data[
            "Moisture 150 cm underground"
          ]
            ? last_data["Moisture 150 cm underground"]
            : last_data["Moisture"],
          Temperature: last_data["Temperature"],
          name: last_data["name"],
        });
        pDataKeys.forEach((timeSlot) => {
          var val = pData[timeSlot];
          var time = new Date(val["name"]);
          pData_ST_arr.push({
            Moisture: val["Layer_0"],
            "Moisture 10 cm underground": val["Layer_1"],
            "Moisture 100 cm underground": val["Layer_9"],
            "Moisture 150 cm underground": val["Layer_14"],
            Temperature: val["Temperature"],
            name: `${time.toDateString()} ${time.toLocaleTimeString()}`,
          });
        });
        console.log("pDataARR_ST:", pData_ST_arr);
        // MD = moisture depth
        for (var index = 0; index < pDataKeys.length; index += 12) {
          var val = pData[index];
          var time = new Date(val["name"]);
          pData_MD_arr.push({
            "Moisture 0 cm underground": val["Layer_0"],
            "Moisture 10 cm underground": val["Layer_1"],
            "Moisture 20 cm underground": val["Layer_2"],
            "Moisture 30 cm underground": val["Layer_3"],
            "Moisture 40 cm underground": val["Layer_4"],
            "Moisture 50 cm underground": val["Layer_5"],
            "Moisture 60 cm underground": val["Layer_6"],
            "Moisture 70 cm underground": val["Layer_7"],
            "Moisture 80 cm underground": val["Layer_8"],
            "Moisture 90 cm underground": val["Layer_9"],
            "Moisture 100 cm underground": val["Layer_10"],
            "Moisture 110 cm underground": val["Layer_11"],
            "Moisture 120 cm underground": val["Layer_12"],
            "Moisture 130 cm underground": val["Layer_13"],
            "Moisture 140 cm underground": val["Layer_14"],
            "Moisture 150 cm underground": val["Layer_15"],
            Temperature: val["Temperature"],
            name: `${time.toDateString()} ${time.toLocaleTimeString()}`,
          });
        }
        console.log("pDataARR_MD:", pData_MD_arr);
      }
    }

    keys.forEach((k) => {
      keys_array.push(k.label);
    });

    data.forEach((d) => {
      if (!Object.values(d).includes(Infinity)) {
        measure.push({
          value: d["Moisture"],
          name: d.name,
        });
        measure_50.push({
          value: d["Moisture 10 cm underground"],
          name: d.name,
        });
        measure_100.push({
          value: d["Moisture 100 cm underground"],
          name: d.name,
        });
        measure_150.push({
          value: d["Moisture 150 cm underground"],
          name: d.name,
        });
        temperature_array.push({ value: d["Temperature"], name: d.name });
      }
    });
    pData_ST_arr.forEach((d) => {
      prediction.push({
        value: d["Moisture"],
        name: d.name,
      });
      prediction_50.push({
        value: d["Moisture 10 cm underground"],
        name: d.name,
      });
      prediction_100.push({
        value: d["Moisture 100 cm underground"],
        name: d.name,
      });
      prediction_150.push({
        value: d["Moisture 150 cm underground"],
        name: d.name,
      });
      prediction_temperature.push({ value: d["Temperature"], name: d.name });
    });

    if (content === "moisture-depth") {
      var color_index = 0;
      for (var i = data.length - 17; i <= data.length - 1; i += 8) {
        if (!data[i] || data[i]["Moisture"] === Infinity) {
          continue;
        }
        series.push({
          title: data[i]["name"],
          data: [
            { name: "0cm", value: null },
            {
              name: "-10cm",
              value:
                data[i]["Moisture"] || data[i]["Moisture 10 cm underground"],
            },
            { name: "-20cm", value: null },
            { name: "-30cm", value: null },
            { name: "-40cm", value: null },
            { name: "-50cm", value: null },
            { name: "-60cm", value: null },
            { name: "-70cm", value: null },
            { name: "-80cm", value: null },
            { name: "-90cm", value: null },
            { name: "-100cm", value: data[i]["Moisture 100 cm underground"] },
            { name: "-110cm", value: null },
            { name: "-120cm", value: null },
            { name: "-130cm", value: null },
            { name: "-140cm", value: null },
            { name: "-150cm", value: data[i]["Moisture 150 cm underground"] },
          ],
          dash: "1 0",
          // color: "#8884d8",
          color: moistture_depth_color[color_index],
          YAxis: "left",
        });
        color_index = color_index + 1;
      }

      if (pData_MD_arr.length > 0) {
        pData_MD_arr.map((d, index) =>
          series.push({
            title: d["name"],
            data: Object.entries(d)
              .map(([k, v], i) => {
                if (k === "Temperature" || k === "name") return undefined;
                return {
                  name: i === 0 ? "0cm" : `-${i * 10}cm`,
                  value: v,
                };
              })
              .filter((v) => v !== undefined),
            dash: "6 4",
            // color: "#8884d8",
            color: moistture_depth_prediction_color[index],
            YAxis: "left",
          })
        );
      }
    }

    if (content === "moisture-depth-detail") {
      if (pData_MD_arr.length > 0) {
        pData_MD_arr.map((d, index) =>
          series.push({
            title: d["name"],
            data: [
              { name: "0cm", value: d["Moisture 0 cm underground"] },
              { name: "-10cm", value: d["Moisture 10 cm underground"] },
              { name: "-100cm", value: d["Moisture 100 cm underground"] },
              { name: "-150cm", value: d["Moisture 150 cm underground"] },
              { name: "-200cm", value: d["Moisture 200 cm underground"] },
            ],
            dash: "6 4",
            // color: "#8884d8",
            color: moistture_depth_prediction_color[index],
            YAxis: "left",
          })
        );
      }
    }

    if (content === "soil") {
      // console.log('in soil condition')
      if (keys_array.includes("Temperature")) {
        series.push(
          {
            title: "Temperature",
            data: temperature_array,
            dash: "1 0",
            color: "#f3c363",
            YAxis: "right",
          },
          {
            title: "Prediction Temperature",
            data: prediction_temperature,
            dash: "6 4",
            color: "#f3c363",
            YAxis: "right",
          }
        );
      }

      if (keys_array.includes("Moisture 10 cm underground")) {
        series.push(
          {
            title: "Moisture 10 cm underground",
            data: measure_50,
            dash: "1 0",
            color: "#8884d8",
            YAxis: "left",
          },
          {
            title: "Prediction of Moisture 10 cm underground",
            data: prediction_50,
            dash: "6 6",
            color: "#8884d8",
            YAxis: "left",
          }
        );
      }

      if (keys_array.includes("Moisture 100 cm underground")) {
        series.push(
          {
            title: "Moisture 100 cm underground",
            data: measure_100,
            dash: "1 0",
            color: "#82ca9d",
            YAxis: "left",
          },
          {
            title: "Prediction of Moisture 100 cm underground",
            data: prediction_100,
            dash: "6 6",
            color: "#82ca9d",
            YAxis: "left",
          }
        );
      }

      if (keys_array.includes("Moisture 150 cm underground")) {
        series.push(
          {
            title: "Moisture 150 cm underground",
            data: measure_150,
            dash: "1 0",
            color: "#fc3903",
            YAxis: "left",
          },
          {
            title: "Prediction of Moisture 150 cm underground",
            data: prediction_150,
            dash: "6 6",
            color: "#fc3903",
            YAxis: "left",
          }
        );
      }

      if (keys_array.includes("Moisture")) {
        series.push(
          {
            title: "Moisture",
            data: measure,
            dash: "1 0",
            color: "#8884d8",
            YAxis: "left",
          },
          {
            title: "Prediction of Moisture 10 cm underground",
            data: prediction_50,
            dash: "6 6",
            color: "#8884d8",
            YAxis: "left",
          },
          {
            title: "Prediction of Moisture 100 cm underground",
            data: prediction_100,
            dash: "6 6",
            color: "#82ca9d",
            YAxis: "left",
          },
          {
            title: "Prediction of Moisture 150 cm underground",
            data: prediction_150,
            dash: "6 6",
            color: "#fc3903",
            YAxis: "left",
          }
        );
      }
    }
    return series;
  };

  formatLabels = (series) => {
    var times = [];
    for (let s in series) {
      for (let d in series[s].data) {
        let name = series[s].data[d].name;
        if (times.indexOf(name) < 0) {
          times.push(name);
        }
      }
    }
    let label = [];
    times = times.sort((a, b) => new Date(a).getTime() - new Date(b).getTime());
    times.forEach((t) => {
      label.push({ name: t });
    });
    return label;
  };

  formatXAxis = (props) => {
    const xAxis = String(props);
    let result;
    let x = xAxis.split(" ");
    if (this.props.type === "html")
      result = x[2] + "/" + monthTrans[x[1]] + "/" + x[3];
    else result = x[2] + "/" + monthTrans[x[1]] + "/" + x[3] + " ";
    return result;
  };
  formatXAxis2 = (props) => {
    // console.log('fmXaix2 props:', props)
    return props.toFixed(1);
  };

  renderLegend = (value) => {
    let result;
    if (this.props.sensor !== undefined) {
      if (/([BCEF])/g.test(this.props.sensor.substr(1, 1))) {
        result = abbrevThreeDepth[value];
      } else {
        result = abbrevOneDepth[value];
      }
      // result = abbrevThreeDepth[value]
    } else {
      result = abbrevThreeDepth[value];
    }
    return result;
  };

  render() {
    const center = {
      position: "absolute",
      margin: "auto",
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
    };
    let interval = Math.trunc(this.props.data.length / 8);
    let height, tooltip, marginRight, marginLeft, top;
    if (this.props.type === "html") {
      top = 20;
      height = 400;
      tooltip = true;
      marginRight = 0;
      marginLeft = -10;
    } else {
      top = 0;
      height = 280;
      tooltip = false;
      marginRight = 20;
      marginLeft = 20;
    }
    if (this.props.loading) {
      return (
        <ResponsiveContainer height={100} width="100%">
          <Spinner style={center} color="primary" />
        </ResponsiveContainer>
      );
    }
    let lineSeries = this.formatToSeries(
      this.props.data,
      this.props.keys,
      this.props.content,
      this.props.predictionData
    );

    let labelSeries = this.formatLabels(lineSeries);
    debugger;
    return (
      <div>
        {this.props.data.length > 0 ? (
          <ResponsiveContainer height={height} width="100%">
            {this.props.content === "soil" ||
            this.props.content === "moisture-depth" ||
            this.props.content === "moisture-depth-detail" ? (
              <LineChart
                layout={
                  this.props.content === "soil" ? "horizontal" : "vertical"
                }
                margin={
                  this.props.content === "soil"
                    ? {
                        top: top,
                        left: marginLeft,
                        right: marginRight,
                        bottom: 0,
                      }
                    : {
                        top: -50,
                        left: marginLeft,
                        right: marginRight,
                        bottom: top,
                      }
                }
              >
                <CartesianGrid strokeDasharray="3 3" />
                {this.props.type === "report" ? (
                  <XAxis
                    dataKey="name"
                    type={this.props.content === "soil" ? "category" : "number"}
                    height={80}
                    interval={interval}
                    allowDuplicatedCategory={false}
                    tickFormatter={this.formatXAxis}
                    tick={{ fontSize: 13 }}
                  />
                ) : (
                  <XAxis
                    dataKey={this.props.content === "soil" ? "name" : "value"}
                    type={this.props.content === "soil" ? "category" : "number"}
                    domain={
                      this.props.content === "soil"
                        ? [0, "auto"]
                        : ["dataMin-0.2", "dataMax+0.2"]
                    }
                    height={80}
                    interval={this.props.content === "soil" ? interval : null}
                    tickCount={this.props.content === "soil" ? 0 : 18}
                    allowDuplicatedCategory={false}
                    orientation={
                      this.props.content === "soil" ? "bottom" : "top"
                    }
                    tickFormatter={
                      this.props.content === "soil"
                        ? this.formatXAxis
                        : this.formatXAxis2
                    }
                    unit={this.props.content === "soil" ? "" : "%"}
                    // ticks={[10, 15, 20, 25, 30, 40, 45, 50, 55]}
                  />
                )}
                {this.props.type === "report" ? (
                  <YAxis
                    width={80}
                    yAxisId="left"
                    dataKey={this.props.content === "soil" ? "value" : "name"}
                    unit={this.props.unit}
                    tick={{ fontSize: 13 }}
                    label={{
                      value: "Vol. Moisture",
                      angle: -90,
                      position: "insideLeft",
                      offset: 10,
                      fill: "#7d7c7c",
                    }}
                  />
                ) : (
                  <YAxis
                    tickCount={3}
                    width={80}
                    yAxisId="left"
                    dataKey={this.props.content === "soil" ? "value" : "name"}
                    unit={this.props.unit}
                    type={this.props.content === "soil" ? "number" : "category"}
                    allowDuplicatedCategory={false}
                  />
                )}
                {this.props.type === "report" ? (
                  <YAxis
                    yAxisId="right"
                    orientation="right"
                    unit={this.props.unitRight}
                    tick={{ fontSize: 13 }}
                    label={{
                      value: "Temperature",
                      angle: 90,
                      position: "insideRight",
                      offset: -10,
                      fill: "#7d7c7c",
                    }}
                  />
                ) : (
                  <YAxis
                    yAxisId="right"
                    orientation="right"
                    unit={this.props.unitRight}
                  />
                )}

                {this.props.content === "soil" ? (
                  <Tooltip
                    formatter={(value, title) =>
                      ` ${oneDigit(value)} ${unitTransformer[title]}`
                    }
                  />
                ) : (
                  <Tooltip
                    formatter={(value, title) => ` ${oneDigit(value)} ${"%"}`}
                  />
                )}

                {this.props.content === "soil" && (
                  <Legend
                    verticalAlign="top"
                    margin={{ bottom: 50 }}
                    formatter={this.renderLegend}
                  />
                )}

                <Line yAxisId="left" dataKey="value" data={labelSeries} />
                {lineSeries.map((s) => (
                  <Line
                    yAxisId={s.YAxis}
                    dataKey="value"
                    data={s.data}
                    name={s.title}
                    key={s.title}
                    stroke={s.color}
                    strokeWidth={1}
                    type="monotone"
                    strokeDasharray={s.dash}
                    dot={this.props.content === "soil" ? false : true}
                    connectNulls={true}
                  />
                ))}

                {this.props.keys &&
                  this.props.wPoints.data &&
                  this.props.wPoints.show[0] &&
                  this.props.keys.map((element, index) =>
                    this.props.content === "soil" ? (
                      <ReferenceLine
                        key={element.label}
                        y={this.props.wPoints.data[nameWP[element.label]]}
                        label={
                          <Label
                            value="WP"
                            fontSize={15}
                            fontWeight={"bold"}
                            fill={element.color || colors[element.label]}
                          />
                        }
                        stroke={element.color || colors[element.label]}
                        ifOverflow="extendDomain"
                        strokeWidth={0.5}
                        yAxisId="left"
                        strokeDasharray="3 3"
                      />
                    ) : (
                      <ReferenceLine
                        key={element.label}
                        x={this.props.wPoints.data[nameWP[element.label]]}
                        label={
                          <Label
                            value="WP"
                            fontSize={15}
                            fontWeight={"bold"}
                            fill={element.color || colors[element.label]}
                          />
                        }
                        stroke={element.color || colors[element.label]}
                        ifOverflow="extendDomain"
                        strokeWidth={0.5}
                        yAxisId="left"
                        strokeDasharray="3 3"
                      />
                    )
                  )}
                {this.props.keys &&
                  this.props.wPoints.data &&
                  this.props.wPoints.show[1] &&
                  this.props.keys.map((element, index) =>
                    this.props.content === "soil" ? (
                      <ReferenceLine
                        key={element.label + "fp"}
                        y={this.props.wPoints.data[nameFC[element.label]]}
                        stroke={element.color || colors[element.label]}
                        ifOverflow="extendDomain"
                        strokeWidth={0.5}
                        yAxisId="left"
                        strokeDasharray="4 2"
                        label={
                          <Label
                            value="FC"
                            fontSize={15}
                            fontWeight={"bold"}
                            fill={element.color || colors[element.label]}
                          />
                        }
                      />
                    ) : (
                      <ReferenceLine
                        key={element.label + "fp"}
                        x={this.props.wPoints.data[nameFC[element.label]]}
                        stroke={element.color || colors[element.label]}
                        ifOverflow="extendDomain"
                        strokeWidth={0.5}
                        yAxisId="left"
                        strokeDasharray="4 2"
                        label={
                          <Label
                            value="FC"
                            fontSize={15}
                            fontWeight={"bold"}
                            fill={element.color || colors[element.label]}
                          />
                        }
                      />
                    )
                  )}
                {this.props.keys &&
                  this.props.wPoints.data &&
                  this.props.wPoints.show[2] &&
                  this.props.keys.map((element, index) => (
                    <ReferenceLine
                      key={element.label + "satu"}
                      y={this.props.wPoints.data[nameSaturation[element.label]]}
                      // label="Saturation"
                      stroke={element.color || colors[element.label]}
                      ifOverflow="extendDomain"
                      strokeWidth={0.5}
                      yAxisId="left"
                      strokeDasharray="5 1"
                      label={
                        <Label
                          value="S"
                          fontSize={15}
                          fontWeight={"bold"}
                          fill={element.color || colors[element.label]}
                        />
                      }
                    />
                  ))}

                {/* Traffic Light Area */}
                {this.props.wPoints.data && this.props.content === "soil" ? (
                  Array.from([
                    [null, this.props.wPoints.data.wpoint_50],
                    [
                      this.props.wPoints.data.wpoint_50,
                      this.props.wPoints.data.fcapacity_50,
                    ],
                    [
                      this.props.wPoints.data.saturation_50,
                      this.props.wPoints.data.fcapacity_50,
                    ],
                    [this.props.wPoints.data.saturation_50, null],
                  ]).map(([y1, y2], i) => (
                    <ReferenceArea
                      key={i}
                      y1={y1}
                      y2={y2}
                      yAxisId={"left"}
                      fill={referenceAreaColor[i]}
                      fillOpacity={0.2}
                      ifOverflow="extendDomain"
                    ></ReferenceArea>
                  ))
                ) : (
                  <></>
                )}
              </LineChart>
            ) : (
              <LineChart
                data={this.props.data}
                margin={{
                  top: top,
                  left: marginLeft,
                  right: marginRight,
                  bottom: 0,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                {this.props.type === "report" ? (
                  <XAxis
                    dataKey="name"
                    height={80}
                    tickFormatter={this.formatXAxis}
                    interval={interval}
                    tick={{ fontSize: 13 }}
                  />
                ) : (
                  <XAxis
                    dataKey="name"
                    height={80}
                    tickFormatter={this.formatXAxis}
                    interval={interval}
                  />
                )}
                {this.props.type === "report" ? (
                  <YAxis
                    width={80}
                    yAxisId="left"
                    unit={this.props.unit}
                    tick={{ fontSize: 13 }}
                    label={{
                      value: "Vol. Moisture",
                      angle: -90,
                      position: "insideLeft",
                      offset: 10,
                      fill: "#7d7c7c",
                    }}
                  />
                ) : (
                  <YAxis width={80} yAxisId="left" unit={this.props.unit} />
                )}
                {this.props.type === "report" ? (
                  <YAxis
                    yAxisId="right"
                    orientation="right"
                    unit={this.props.unitRight}
                    tick={{ fontSize: 13 }}
                    label={{
                      value: "Temperature",
                      angle: 90,
                      position: "insideRight",
                      offset: -10,
                      fill: "#7d7c7c",
                    }}
                  />
                ) : (
                  <YAxis
                    yAxisId="right"
                    orientation="right"
                    unit={this.props.unitRight}
                  />
                )}

                {tooltip && (
                  <Tooltip
                    formatter={(value, name) =>
                      ` ${oneDigit(value)} ${unitTransformer[name]}`
                    }
                  />
                )}
                <Legend
                  verticalAlign="top"
                  margin={{ bottom: 50 }}
                  formatter={this.renderLegend}
                />

                {this.props.keys &&
                  this.props.keys.map((element, index) => (
                    // one for measure, another for prediction
                    <Line
                      yAxisId={element.yAxis ? "right" : "left"}
                      key={"other content type" + index}
                      type="monotone"
                      dataKey={element.label}
                      stroke={element.color || colors[element.label]}
                      strokeWidth={2}
                      dot={false}
                      // name={element.label}
                    />
                  ))}
                {this.props.keys &&
                  this.props.wPoints &&
                  this.props.wPoints.data &&
                  this.props.wPoints.show[0] &&
                  this.props.keys.map((element, index) => (
                    <ReferenceLine
                      key={"show0" + index}
                      y={this.props.wPoints.data[nameWP[element.label]]}
                      label={
                        <Label
                          value="WP"
                          fontSize={15}
                          fontWeight={"bold"}
                          fill={element.color || colors[element.label]}
                        />
                      }
                      stroke={element.color || colors[element.label]}
                      ifOverflow="extendDomain"
                      strokeWidth={0.5}
                      yAxisId="left"
                      strokeDasharray="3 3"
                    />
                  ))}
                {this.props.keys &&
                  this.props.wPoints &&
                  this.props.wPoints.data &&
                  this.props.wPoints.show[1] &&
                  this.props.keys.map((element, index) => (
                    <ReferenceLine
                      key={"show1" + index}
                      y={this.props.wPoints.data[nameFC[element.label]]}
                      // label="Wilting Point"
                      stroke={element.color || colors[element.label]}
                      ifOverflow="extendDomain"
                      strokeWidth={0.5}
                      yAxisId="left"
                      strokeDasharray="4 2"
                      label={
                        <Label
                          value="FC"
                          fontSize={15}
                          fontWeight={"bold"}
                          fill={element.color || colors[element.label]}
                        />
                      }
                    />
                  ))}
                {this.props.keys &&
                  this.props.wPoints &&
                  this.props.wPoints.data &&
                  this.props.wPoints.show[2] &&
                  this.props.keys.map((element, index) => (
                    <ReferenceLine
                      key={"show2" + index}
                      y={this.props.wPoints.data[nameSaturation[element.label]]}
                      // label="Saturation"
                      stroke={element.color || colors[element.label]}
                      ifOverflow="extendDomain"
                      strokeWidth={0.5}
                      yAxisId="left"
                      strokeDasharray="5 1"
                      label={
                        <Label
                          value="S"
                          fontSize={15}
                          fontWeight={"bold"}
                          fill={element.color || colors[element.label]}
                        />
                      }
                    />
                  ))}
                {/* Traffic Light Area */}
                {this.props.wPoints && this.props.wPoints.data && (
                  <>
                    <ReferenceArea
                      y2={this.props.wPoints.data.wpoint_50}
                      stroke={muiColor.orange.A700}
                      strokeOpacity={0.5}
                      ifOverflow="extendDomain"
                    ></ReferenceArea>
                    <ReferenceArea
                      y2={this.props.wPoints.data.wpoint_50 / 100}
                      stroke={muiColor.orange.A700}
                      strokeOpacity={0.5}
                      ifOverflow="extendDomain"
                    ></ReferenceArea>
                    <ReferenceArea
                      y1={this.props.wPoints.data.wpoint_50 / 100}
                      y2={this.props.wPoints.data.fcapacity_50 / 100}
                      stroke={muiColor.yellow[800]}
                      strokeOpacity={0.5}
                      ifOverflow="extendDomain"
                    ></ReferenceArea>
                    <ReferenceArea
                      y1={this.props.wPoints.data.fcapacity_50 / 100}
                      y2={this.props.wPoints.data.saturation_50 / 100}
                      stroke={muiColor.lime.A700}
                      strokeOpacity={0.5}
                      ifOverflow="extendDomain"
                    ></ReferenceArea>
                    <ReferenceArea
                      y1={this.props.wPoints.data.saturation_50 / 100}
                      stroke={muiColor.cyan.A700}
                      strokeOpacity={0.5}
                      ifOverflow="extendDomain"
                    ></ReferenceArea>
                  </>
                )}
              </LineChart>
            )}
          </ResponsiveContainer>
        ) : (
          <span className="text-center">
            <h3>No Sensor Data</h3>
          </span>
        )}
      </div>
    );
  }
}

export default SimpleLineChart;
