import { Modal, ModalBody, ModalHeader, Row, Col, Button } from "reactstrap";
import React, { useState } from "react";
import { useFieldMoistureDataContext } from "../../hooks/useFieldMoistureDataContext";
import DatePicker from "react-datepicker";
import {
  fetchMoistureDataByField,
  MoistureData,
  MoistureDataContent,
} from "src/actions/v2/moisture";
import { useDashboardHook } from "../../hooks/useDashboardHooks";
import { EMPTY_FIELD_DATA } from "src/actions/v2/field";
import {
  EvaporationDataEntryType,
  fetchEvaporation,
} from "src/actions/v2/evaporation";
import ReportMap from "../DisplayMap/ReportMap";
import { googleMapAPI } from "src/config/secret";

type DownloadReportModalProps = {
  isOpen: boolean;
  toggle: () => void;
};

const DownloadReportModal: React.FC<DownloadReportModalProps> = ({
  isOpen,
  toggle,
}) => {
  const { zones } = useFieldMoistureDataContext();
  const [fetching, setFetching] = useState(false);
  const { chosenField } = useDashboardHook();
  const [moistureData, setMoistureData] = useState<{
    [sensorId: string]: MoistureDataContent[];
  }>({});
  const [evpData, setEvpData] = useState<EvaporationDataEntryType[]>([]);

  const [start, setStart] = useState<Date | undefined | null>(
    new Date(Date.now() - 7 * 24 * 60 * 60 * 1000)
  ); // 7 days before
  const [end, setEnd] = useState<Date | undefined | null>(new Date());

  const fetchData = () => {
    if (
      !chosenField.field_id ||
      chosenField.field_id === EMPTY_FIELD_DATA.field_id
    )
      return;
    if (!start || !end) return;

    setFetching(true);

    let fetchMoistureData = fetchMoistureDataByField(chosenField.field_id, {
      params: { start: start.getTime() / 1000, end: end.getTime() / 1000 },
    });

    let fetchEvpData = fetchEvaporation(chosenField.field_id, { start, end });

    Promise.all([fetchMoistureData, fetchEvpData])
      .then(([fieldMoistureData, evpData]) => {
        setMoistureData(
          Object.fromEntries(
            Object.entries(fieldMoistureData).map(([senosrId, d]) => [
              senosrId,
              d.moisture,
            ])
          )
        );

        setEvpData(evpData);
      })
      .catch((e) => console.log(e))
      .finally(() => setFetching(true));
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle} size="lg">
      <ModalHeader toggle={toggle}>Generate a Report</ModalHeader>
      <ModalBody className="px-5">
        <Row>
          <Col sm={12} md={3}>
            Start Date:
          </Col>
          <Col sm={12} md={9}>
            <DatePicker
              selected={start}
              onChange={setStart}
              selectsStart
              startDate={start}
              endDate={end}
              placeholderText="Start Date"
              maxDate={new Date()}
              dateFormat={"dd/MM/yyyy"}
            />
          </Col>
        </Row>
        <Row>
          <Col sm={12} md={3}>
            End Date:
          </Col>
          <Col sm={12} md={5}>
            <DatePicker
              selected={end}
              onChange={setEnd}
              selectsEnd
              startDate={start}
              endDate={end}
              minDate={start}
              placeholderText="End Date"
              maxDate={new Date()}
              dateFormat={"dd/MM/yyyy"}
            />
          </Col>
          <Col sm={12} md={4}>
            <Button
              onClick={fetchData}
              color="primary"
              className="floar-right mr-1"
              outline
              disabled={fetching}
            >
              {fetching ? 'Loading Data...' : 'Confirm'}
            </Button>
          </Col>
        </Row>

        <Row className="mt-5">
          <ReportMap
            containerElement={<div style={{ height: "inherit" }} />}
            mapElement={<div style={{ height: "inherit" }} />}
            googleMapURL={googleMapAPI}
            loadingElement={
              <div style={{ height: "inherit", width: "inherit" }} />
            }
          />
        </Row>
      </ModalBody>
    </Modal>
  );
};

export default DownloadReportModal;
