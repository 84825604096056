import React from "react";
import { Button, Table, TableProps } from "reactstrap";

import cx from "classnames";

export interface FieldTableProps {
  actions: { [function_name: string]: (...args: any[]) => {} };
  fields: { [key: string]: string }[];
}

const tableKeys = [
  { key: "field_name", label: "field" },
  { key: "crop_type", label: "crop type" },
  { key: "farm_name", label: "farm" },
];

export const FieldTable = (props: FieldTableProps & TableProps) => {
  return (
    <Table className={cx(props.className)} borderless={props.borderless}>
      <thead>
        <tr>
          <th className="hidden">#</th>
          {tableKeys.map((key, i) => (
            <th className="align-middle" key={i}>
              {key.label}
            </th>
          ))}
          <th>Actions</th>
          <th />
        </tr>
      </thead>
      <tbody>
        {props.fields.map((field, i) => (
          <tr key={i}>
            <td className="align-middle">{i + 1}</td>
            {tableKeys.map((k, idx) => (
              <td className="align-middle" key={idx}>
                {field?.[k.key]}
              </td>
            ))}
            <td className="align-middle">
              <Button
                onClick={() => {
                  props.actions.editFieldToggle(field);
                }}
                className="mr-3"
                size="sm"
                color="success"
                outline
              >
                <span className="glyphicon glyphicon-edit mr-1 mt-1"></span>
                Edit
              </Button>
              <Button
                onClick={() => {
                  props.actions.deleteFieldToggle(field);
                }}
                className="mr-3"
                size="sm"
                color="danger"
                outline
              >
                <span className="glyphicon glyphicon-trash mr-1 mt-1" />
                Delete
              </Button>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};
