interface IFetchRecordParam {
  username: string;
  start: Date;
}

interface IFetchRecordResp {
  [sensor_id: string]: {
    [date: string]: { moisture?: number; prediction?: number };
  };
}

export async function fetchRecord(params: IFetchRecordParam) {
  let { username, start } = params;
  var config: RequestInit = {
    method: "GET",
    headers: { "Content-Type": "application/json" },
    credentials: "include",
  };

  let startString = start.toISOString();

  return fetch(`/api/record?username=${username}&start=${startString}`, config).then(async (res) => {
    if (res.ok) {
      return res
        .json()
        .then((result) => {
          return Promise.resolve<IFetchRecordResp>(result);
        })
        .catch((err) => {
          return Promise.reject(err);
        });
    } else {
      return res
        .json()
        .then((result) => {
          return Promise.reject(result);
        })
        .catch((err) => {
          return Promise.reject(err);
        });
    }
  });
}
