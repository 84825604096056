import React, { ReactNode } from "react";
import { DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";
import ButtonDropdownMenu from "src/components/dropdown-menu";

type DropDownSelectorType<T> = {
  chosenItem: T;
  items: T[];
  disabled?: boolean // use to control whether the toggle button is disabled
  onSelect: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void

  keyForText: keyof T
  keyForValue: keyof T

  classname?: string
};

export default function DropDownSelector<T = { [key: string]: string }>({
  chosenItem,
  items,
  disabled, onSelect,
  keyForText,
  keyForValue,
  classname
}: DropDownSelectorType<T>) {
  return (
    <ButtonDropdownMenu className={(classname || "mr-2 mb-1") + " d-inline-block"}>
      <DropdownToggle
        color="primary"
        caret
        outline
        disabled={disabled}
      >
        {chosenItem[keyForText] as ReactNode || chosenItem[keyForValue] as ReactNode}
      </DropdownToggle>

      {items?.length > 0 && (
        <DropdownMenu>
          {items.map((item, i) => (
            <DropdownItem
              onClick={onSelect}
              key={i}
              aria-valuetext={item[keyForValue] as string}
            >
              {item[keyForText] as ReactNode || item[keyForValue] as ReactNode}
            </DropdownItem>
          ))}
        </DropdownMenu>
      )}
    </ButtonDropdownMenu>);
}
