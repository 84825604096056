import React from "react";
import { Button, ButtonProps, Spinner } from "reactstrap";

type IButtonProps = ButtonProps & { isLoading?: boolean }

const IButton: React.FC<IButtonProps> = ({ isLoading, children, ...rest }) => {
  return (
    <Button {...rest}>
      {isLoading ? <Spinner /> : children}
    </Button>
  );
};

export default IButton;
