import { Reducer } from "redux";
export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";

const token = localStorage.getItem("token");

export type AuthReducerState = {
  isFetching: boolean,
  isAuthenticated: boolean,
  errorMessage: null | string,
  userName: string,
  idToken: string,
  userGroup: unknown,
  subscription: unknown,
}

const auth: Reducer<AuthReducerState, any> = (state = {
  isFetching: false,
  isAuthenticated: !!token,
  errorMessage: null,
  userName: "",
  idToken: "",
  userGroup: null,
  subscription: null,
}, action) => {

  const { type, ...payload } = action;
  switch (type) {
    case LOGIN_REQUEST:
    case LOGIN_FAILURE:
    case LOGOUT_SUCCESS:
    case LOGIN_SUCCESS:
      return Object.assign({}, state, payload);
    default:
      return state;
  }
}

export default auth
