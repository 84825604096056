import React from "react";
import { Marker, InfoWindow, Polygon } from "react-google-maps";
import MarkerClusterer from "react-google-maps/lib/components/addons/MarkerClusterer";
import { getCenter } from "../../util";
import { IComponentType } from "src/types";
import { withRouter } from "react-router";
import { useAppSelector } from "src/store";

const Map: IComponentType = ({ history, location }) => {
  const fields = useAppSelector((state) => state.field.fields || []);

  const setField = (fieldId: string) => {
    let search = new URLSearchParams(location.search)

    search.set('field', fieldId)
    history.push({ ...location, search: search.toString() })
  }

  return (
    <MarkerClusterer averageCenter enableRetinaIcons gridSize={60}>
      {fields.map((field, index) => (
        <div key={index}>
          <Marker
            onClick={() => {
              setField(field.field_id!);
            }}
            icon={{
              url: "https://maps.google.com/mapfiles/ms/icons/blue-pushpin.png",
            }}
            position={getCenter(field.coordinates!)}
          >
            <InfoWindow
              position={getCenter(field.coordinates!)}
              options={{ disableAutoPan: true }}
            >
              <span>{field.field_name}</span>
            </InfoWindow>
          </Marker>
          <Polygon
            options={{
              strokeColor: "#369BF7",
              fillOpacity: 0,
              zIndex: -1,
            }}
            path={field.coordinates!}
          />
        </div>
      ))}
    </MarkerClusterer>
  );
};

export const FieldInfoMap = withRouter(Map);
