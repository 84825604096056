import React from "react";
import { useCallback } from "react";
import { useHistory, useLocation } from "react-router-dom";

export const useParamSearch = () => {
  const location = useLocation();
  const history = useHistory();

  const navigatTo = React.useRef(history.push).current

  const setSearch = useCallback(
    (k: string, v: string | null) => {
      const search = new URLSearchParams(location.search);
      if (!v) search.delete(k);
      else if (search.get(k) === v) return
      else search.set(k, v);
      navigatTo({ ...location, search: search.toString() });
    },
    [location, navigatTo]
  );

  const getSearchState = useCallback(
    (key: string) => {
      const search = new URLSearchParams(location.search);
      return search.get(key);
    },
    [location]
  );

  const hasKey = useCallback(
    (key: string) => {
      const search = new URLSearchParams(location.search);
      return search.has(key);
    },
    [location]
  );

  return { setSearch, getSearchState, hasKey };
};
