import { connect } from "react-redux";
import cx from "classnames";
import React from "react";
import PropTypes from "prop-types";
import {
  Navbar,
  Nav,
  NavItem,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Breadcrumb,
  BreadcrumbItem,
} from "reactstrap";
import { NavLink } from "react-router-dom";

import photo from "../../images/default-user-image.png";
import s from "./Header.module.scss";
import { logout } from "../../actions/cognito";

class Header extends React.Component {
  static propTypes = {
    sidebarToggle: PropTypes.func,
    dispatch: PropTypes.func.isRequired,
  };

  static defaultProps = {
    sidebarToggle: () => {},
  };

  state = { isOpen: false };

  toggleDropdown = () => {
    this.setState((prevState) => ({
      isOpen: !prevState.isOpen,
    }));
  };

  doLogout = () => {
    this.props.dispatch(logout());
    window.location.reload();
  };

  constructor(props) {
    super(props);
    this.userName = localStorage.getItem("userName");
  }

  render() {
    const { isOpen } = this.state;
    const breadCrumb = this.props.location.pathname.split("/").slice(2);

    return (
      <Navbar className={s.root}>
        <Nav>
          <NavItem>
            <Breadcrumb>
              <BreadcrumbItem>YOU ARE HERE</BreadcrumbItem>
              {breadCrumb.map((b, i) => (
                <BreadcrumbItem key={i} active>{b}</BreadcrumbItem>
              ))}
            </Breadcrumb>
          </NavItem>
          {/* 
          <NavItem
            className={cx('visible-xs mr-4 d-sm-up-none', s.headerIcon, s.sidebarToggler)}
            href="#"
            onClick={this.props.sidebarToggle}
          >
            <i className="fa fa-bars fa-2x text-muted" />
          </NavItem>
          <NavItem>
            <InputGroup>
              <Input placeholder="Search for..." />
              <InputGroupAddon addonType="append" className="px-2">
                <i className="fa fa-search" />
              </InputGroupAddon>
            </InputGroup>
          </NavItem>
        */}
        </Nav>

        <Nav className="ml-auto">
          {/*
          <NavItem className={cx('', s.headerIcon)}>
            <Button>
              <Icon glyph="mail"/>
              <span>8</span>
            </Button>
          </NavItem>
          <NavItem className={cx('', s.headerIcon)}>
            <Button>
              <Icon glyph="notification"/>
              <span>13</span>
            </Button>
          </NavItem>
          <NavItem className={cx('', s.headerIcon)}>
            <Button>
              <Icon glyph="settings"/>
            </Button>
          </NavItem>
          */}
          <Dropdown isOpen={isOpen} toggle={this.toggleDropdown}>
            <DropdownToggle nav>
              <img
                className={cx("rounded-circle mr-sm", s.adminPhoto)}
                src={photo}
                alt="administrator"
              />
              <span className="text-body">Hi, {this.userName}</span>
              <i
                className={cx("fa fa-angle-down ml-sm", s.arrow, {
                  [s.arrowActive]: isOpen,
                })}
              />
            </DropdownToggle>
            <DropdownMenu style={{ width: "100%" }}>
              <DropdownItem>
                <NavLink to="/app/profile">Profile</NavLink>
              </DropdownItem>
              <DropdownItem onClick={this.doLogout}>Logout</DropdownItem>
            </DropdownMenu>
          </Dropdown>
        </Nav>
      </Navbar>
    );
  }
}

function mapStateToProps(state) {
  return {
    init: state.runtime.initialNow,
  };
}
export default connect(mapStateToProps)(Header);
