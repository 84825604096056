import React from "react";
import { connect } from "react-redux";
import { HashRouter, Switch, Route, Redirect } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import ErrorPage from "../pages/error/ErrorPage";

import { isAuthenticated, isValidSubscription } from "../actions/cognito";

import "../styles/theme.scss";
//import DocumentationLayoutComponent from '../documentation/DocumentationLayout';
import Login from "../pages/login/Login";
import Register from "../pages/register/Register";
import { logoutUser } from "../actions/user";
import { Home } from "../pages/user";
import { isAdmin } from "../actions/cognito";
import "src/actions/axios/global"

const PrivateRoute = ({ dispatch, component, isLoggedIn, isValidSubscriber, ...rest }) => {
  if (!isLoggedIn()) {
    dispatch(logoutUser());
    return <Redirect to="/login" />;
  } else {
    if (!isValidSubscriber()) window.location.href = "#/app/subscription";
    return <Route {...rest} render={(props) => React.createElement(component, props)} />;
  }
};
const CloseButton = ({ closeToast }) => <i onClick={closeToast} className="la la-close notifications-close" />;

class App extends React.PureComponent {
  render() {
    return (
      <div>
        <ToastContainer autoClose={5000} hideProgressBar closeButton={<CloseButton />} />
        <HashRouter>
          <Switch>
            <Route path="/" exact render={() => <Redirect to="/app/Home" />} />
            <Route path="/app" exact render={() => <Redirect to="/app/Home" />} />
            <PrivateRoute
              path="/app"
              dispatch={this.props.dispatch}
              isLoggedIn={isAuthenticated}
              isValidSubscriber={() => isValidSubscription() || isAdmin()}
              component={Home}
            />
            <Route
              path="/documentation"
              exact
              render={() => <Redirect to="/documentation/getting-started/overview" />}
            />
            {/* <Route path="/documentation" component={DocumentationLayoutComponent}/> */}
            <Route path="/register" exact component={Register} />
            <Route path="/login" exact component={Login} />
            <Route path="/error" exact component={ErrorPage} />
            <PrivateRoute
              path="/fieldwork-admin"
              dispatch={this.props.dispatch}
              isLoggedIn={() => isAuthenticated() && isAdmin()}
              isValidSubscriber={() => true}
              component={Home}
            />
          </Switch>
        </HashRouter>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps)(App);
