import Axios, { AxiosRequestConfig } from "axios";

export type EvaporationDataEntryType = {
  date: string;
  evaporation: number;
  field_id: string;
};

export const fetchEvaporation = async (
  field_id: string,
  query?: { start?: Date | number | string; end?: Date | number | string },
  config?: AxiosRequestConfig
) => {
  const params = new URLSearchParams()
  
  query && Object.entries(query).forEach(([k, v])=> {
    params.set(k, v.toString())
  })
  return Axios.get<{ data: EvaporationDataEntryType[] }>(
    `/api/v2/evaporation/${field_id}?${params.toString()}`,
    config
  )
    .then((res) => Promise.resolve(res.data.data))
    .catch((err) => Promise.reject(err.response.data.err));
};
