import React, { useState } from "react";
import { fetchMoistureDataBySensor } from "src/actions/v2/moisture";
import {
  Button,
  Col,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Spinner,
} from "reactstrap";
import DatePicker from "react-datepicker";
import { useCSVModalContext } from "../../hooks/useCSVModalContext";
import { useDashboardHook } from "../../hooks/useDashboardHooks";
import { EMPTY_FIELD_DATA } from "src/actions/v2/field";
import { toast } from "react-toastify";

const CSVModal = () => {
  const { sensor, isOpen, toggle } = useCSVModalContext();
  const { chosenField } = useDashboardHook();
  const [isDownloading, setDownloading] = useState(false);

  const [start, setStart] = useState<Date | undefined | null>(
    new Date(Date.now() - 7 * 24 * 60 * 60 * 1000)
  ); // 7 days before
  const [end, setEnd] = useState<Date | undefined | null>(new Date());

  const download = () => {
    if (!sensor) return;
    if (chosenField.field_id === EMPTY_FIELD_DATA) return;

    if (!start || !end) return;

    setDownloading(true);
    fetchMoistureDataBySensor<string>(sensor, chosenField.field_id!, {
      start: `${start.getTime() / 1000}`,
      end: `${end.getTime() / 1000}`,
      format: "csv",
    })
      .then((csv) => {
        if (csv == null) return;
        let filename = `${sensor}.csv`;
        if (!csv.match(/^data:text\/csv/i)) {
          csv = "data:text/csv;charset=utf-8," + csv;
        }
        let data = encodeURI(csv);
        let link = document.createElement("a");
        link.href = data;
        link.download = filename;
        link.click();

        toast.success(
          <div>
            <span className="glyphicon glyphicon-floppy-saved ml-2 mr-2" />
            File has downloaded
          </div>,
          {
            position: "top-right",
            autoClose: 5000,
            closeOnClick: false,
            pauseOnHover: false,
            draggable: true,
          }
        );
      })
      .catch((e) => {
        toast.error(<div>
          <span className="glyphicon glyphicon-floppy-remove ml-2 mr-2" />
          Fail to download CSV file
        </div>,
          {
            position: "top-right",
            autoClose: 5000,
            closeOnClick: false,
            pauseOnHover: true,
            draggable: true,
          });
        console.log(e);
      })
      .finally(() => setDownloading(false));
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle(sensor)}>
      <ModalHeader toggle={toggle()}>Download Data</ModalHeader>
      <ModalBody className="px-5">
        <Row>
          <Col sm={12} md={3}>
            Start Date:
          </Col>
          <Col sm={12} md={9}>
            <DatePicker
              selected={start}
              onChange={setStart}
              selectsStart
              startDate={start}
              endDate={end}
              placeholderText="Start Date"
              maxDate={new Date()}
              dateFormat={"dd/MM/yyyy"}
            />
          </Col>
        </Row>
        <Row>
          <Col sm={12} md={3}>
            End Date:
          </Col>
          <Col sm={12} md={9}>
            <DatePicker
              selected={end}
              onChange={setEnd}
              selectsEnd
              startDate={start}
              endDate={end}
              minDate={start}
              placeholderText="End Date"
              maxDate={new Date()}
              dateFormat={"dd/MM/yyyy"}
            />
          </Col>
        </Row>
        {isDownloading && <Spinner color="danger" />}
        <Row>
          <Col sm={12} md={4}>
            <Button onClick={download} color="danger" disabled={isDownloading}>
              {isDownloading ? "Downloading..." : "Download"}
            </Button>
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  );
};

export default CSVModal;
