import React from "react";

import cx from "classnames";
import "./Widget.scss";

export type WidgetProps = {
  title?: React.ReactNode;
  className?: string;
  children: React.ReactNode;
};

const Widget: React.FC<WidgetProps> = ({ title, className, children }) => {
  return (
    <section className={cx("widget", className)}>
      {title &&
        (typeof title === "string" ? (
          <h5 className="title">{title}</h5>
        ) : (
          <header className="title">{title}</header>
        ))}
      {children}
    </section>
  );
};

export default Widget;
