import React, { useCallback, useEffect, useState } from "react";
import { useDashboardHook } from "../../hooks/useDashboardHooks";
import Axios, { AxiosRequestConfig } from "axios";
import {
  Bar,
  BarChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { getCenter } from "../../util";
import { VisualCrossKey } from "src/config/secret";

const VisualCrossingURL =
  "https://weather.visualcrossing.com/VisualCrossingWebServices/rest/services/timeline";

const PrecipitationChart = () => {
  const { chosenField } = useDashboardHook();
  const [data, setData] = useState<{ datetime: string; precip: number }[]>();

  const getPrcipData = useCallback(
    async (config?: AxiosRequestConfig) => {
      const center = getCenter(chosenField.coordinates!);

      // need to find a better to match the axis with evp table, but taking 2 days in the future as an adhoc solution
      let endDate = new Date();
      endDate.setDate(endDate.getDate() + 2);

      let startDate = new Date(endDate);
      startDate.setDate(startDate.getDate() - 14);

      let [start, end] = [startDate, endDate].map(
        (date) => date.toISOString().split("T")[0]
      );

      let { data } = await Axios.get<{
        days: { datetime: string; precip: number }[];
      }>(
        `${VisualCrossingURL}/${center.lat},${center.lng}/${start}/${end}`,
        config
      );

      setData(data.days)
    },
    [chosenField]
  );

  useEffect(() => {
    const source = Axios.CancelToken.source();

    getPrcipData({
      params: {
        unitGroup: "uk",
        key: VisualCrossKey,
        include: "days",
        elements: "precip,datetime",
      },
      cancelToken: source.token,
    })
      .catch((err) => console.log(err));

    return () => source.cancel();
  }, [getPrcipData]);

  return (
    <ResponsiveContainer height={300} width="100%">
      <BarChart
        data={data}
        margin={{ top: 50, right: 30, bottom: 5, left: 30 }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="datetime" />
        <YAxis yAxisId="left" orientation="left" stroke="#5c5c5c" unit="mm" />
        <Tooltip />
        <Bar yAxisId="left" dataKey="precip" fill="#8884d8" />
      </BarChart>
    </ResponsiveContainer>
  );
};

export default PrecipitationChart;
