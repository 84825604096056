import React from "react";
import PropTypes from "prop-types";
import { Button, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row, Spinner, Alert, Input } from "reactstrap";
import { checkSensors, searchGateway, updateGatewayStatus } from "../../../actions/gateway";
import { connect } from "react-redux";
import Stepper from "react-stepper-horizontal";
import { insertSensor } from "../../../actions/sensor";
import s from "../FormInput/FormInput.module.scss";
import DisplayMap from "./DisplayMap";
import { googleMapAPI } from "../../../config/secret";
import { Link } from "react-router-dom";
import sensorButton from "../../../images/sensorButton.png";
import MapPin from "../../../images/MapPin.png";
//import MapWithADrawingManager from "../../gatewayManagement/FormInput/DrawingManager/DrawingManager";

class SensorRegistrationModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      marker: null,
      sensorID: "",
      gatewayID: "",
      sensors: [],
      isFound: false,
      isActivated: false,
      errMsg: "",
      gatewayIds: [],
      steps: [
        {
          title: "Searching",
          onClick: (e) => {
            e.preventDefault();
          },
        },
        {
          title: "Pairing",
          onClick: (e) => {
            e.preventDefault();
          },
        },
        {
          title: "Submit",
          onClick: (e) => {
            e.preventDefault();
          },
        },
      ],
      currentStep: 0,
      isLoading: false,
      result: null,
    };

    this.controller = new AbortController();
  }

  componentDidMount() {
    this.searchGateway(this.props.fieldId, this.props.userName);
  }

  drawComplete = (marker) => {
    marker.setMap(null);
    this.setState({ marker: marker });
  };

  onClickClear = () => {
    this.setState({ marker: null });
  };

  searchGateway = (fieldId) => {
    this.setState({ isLoading: true });
    searchGateway({ fieldId: fieldId, username: this.props.userName }, this.controller.signal)
      .then((res) => {
        let gatewayIds = res.map((gateway) => gateway.gateway_id);
        this.setState({ isFound: true, gatewayIds: gatewayIds });
      })
      .catch((err) => {
        this.setState({ errMsg: err + ", please try again." });
      })
      .finally(() => {
        this.setState({ isLoading: false });
      });
  };

  updateGateway = (status) => {
    this.setState({ isLoading: true });
    updateGatewayStatus(
      { gatewayIds: this.state.gatewayIds, status: status !== undefined ? status : true },
      this.controller.signal
    )
      .then((res) => {
        this.setState({ isActivated: true });
      })
      .catch((err) => {
        this.setState({ errMsg: err + ", please try again." });
      })
      .finally(() => {
        this.setState({ isLoading: false });
      });
  };

  checkSensors = () => {
    this.setState({ isLoading: true });
    checkSensors({ gatewayIds: this.state.gatewayIds, userName: this.props.userName }, this.controller.signal)
      .then((res) => {
        // 拿的是定義的座標
        let sensors = [];
        res.map((sensor) =>
          sensors.push({ sensorId: sensor.sensor_id, latlng: JSON.parse(sensor.points).coordinates })
        );
        console.log("sensors: ", sensors[0].sensorId);
        console.log("sensors: ", sensors[0].latlng);
        console.log("sensors: ", sensors[0].latlng[0]);
        this.setState({ sensors }); // {sensors: sensors} ？
      })
      .catch((err) => {
        this.setState({ errMsg: err + ", please try again." });
      })
      .finally(() => {
        this.setState({ isLoading: false });
      });
  };

  onClickNext = () => {
    const { currentStep } = this.state;
    this.controller = new AbortController();
    this.setState({
      currentStep: currentStep + 1,
    });
    if (currentStep === 0) {
      this.updateGateway();
    }
    if (currentStep === 1) {
      this.checkSensors();
    }
  };

  onClickClose = () => {
    updateGatewayStatus({ gatewayIds: this.state.gatewayIds, status: false });
    this.props.toggle("");
  };

  onClickBack = () => {
    let { currentStep } = this.state;
    this.controller.abort();
    this.controller = new AbortController()
    switch (currentStep) {
      case 1: // in pairing step, backing to searching gateways
        this.updateGateway(false);
        break;
      case 2: // in getting sensors, backing to pairing step
      default:
        break;
    }

    this.setState({
      currentStep: currentStep - 1,
      isLoading: false,
    });
  };

  onChangeValue(event) {
    console.log(event.target.value);
  }

  onClickSubmit = () => {
    this.setState({ isLoading: true });
    // eslint-disable-next-line
    let sensorIds = this.state.sensors.map((sensor) => sensor.sensorId);
    const { marker, sensorID, gatewayID, sensors } = this.state;
    let latLng = marker.getPosition();
    console.log("onClickSubmit, latLng = ", latLng);
    let GeoJSON = {
      type: "Feature",
      geometry: {
        type: "Point",
        coordinates: [parseFloat(latLng.lng()).toFixed(6), parseFloat(latLng.lat()).toFixed(6)],
      },
      properties: {},
    }; /*
    console.log(sensorID);
    console.log(this.props.fieldId);
    console.log(JSON.stringify(GeoJSON));*/
    this.onClickNext();
    this.props
      .dispatch(
        insertSensor({
          sensorId: sensorID.trim(),
          gatewayId: gatewayID.trim(),
          fieldId: this.props.fieldId,
          geom: JSON.stringify(GeoJSON),
        })
      )
      .then((res) => {
        this.setState({ result: true });
        console.log("SenoerRegistrationModel.js LINE 191 result: ", this.state.result);
        sensors.push(sensorID);
      })
      .catch((err) => {
        this.setState({ result: false });
      })
      .finally(() => {
        this.setState({ isLoading: false });
      });
  };

  render() {
    let { marker } = this.state;
    const { isOpen, toggle, polyline } = this.props;
    const { isFound, isActivated, sensors, errMsg, currentStep, gatewayIds, isLoading } = this.state;
    const nextEnabled = (currentStep === 0 && isFound) || (currentStep === 1 && isActivated);

    return (
      <Modal isOpen={isOpen} toggle={toggle}>
        <ModalHeader>Sensor Registration</ModalHeader>
        <ModalBody className="text-center">
          <Row>
            <Col sm={12} md={{ size: 12, offset: 0 }}>
              <Stepper
                size={24}
                circleFontSize={12}
                titleFontSize={12}
                steps={this.state.steps}
                activeStep={currentStep}
              />
            </Col>
          </Row>
          {currentStep === 0 && (
            <Row className={s.instructions} style={{ height: "100%", width: "100%" }}>
              <Col sm={12} md={{ size: 10, offset: 1 }}>
                {/*Step 1*/}
                {isLoading && (
                  <span>
                    Finding Gateways
                    <Spinner color="primary" />
                  </span>
                )}
                {!isLoading &&
                  (isFound ? (
                    <>
                      <span className="text-success">
                        <span className="glyphicon glyphicon-ok mr-2" />
                        {gatewayIds.length} Gateways Found
                      </span>
                      {/* <ul className="list-group">  */}
                      {gatewayIds.map((gatewayId, index) => (
                        <li className="list-group-item" style={{ color: "#000000" }} key={index}>
                          {gatewayId}
                        </li>
                      ))}
                      {/* </ul> */}
                    </>
                  ) : (
                    <>
                      <span className="text-danger">
                        <span className="glyphicon glyphicon-remove mr-2" />
                        {errMsg}
                      </span>
                      <span style={{ display: "inline-block" }}>
                        Go to <Link to="/app/Management/GatewayManagement">"Management {">"} My Gateways"</Link> to add
                        a new Gateway.
                      </span>
                    </>
                  ))}
              </Col>
            </Row>
          )}
          {currentStep === 1 && (
            <Row className={s.instructions}>
              <Col sm={12} md={{ size: 10, offset: 1 }}>
                {/*Step 2*/}
                {isLoading && (
                  <span>
                    Activating Gateways
                    <Spinner color="primary" />
                  </span>
                )}
                {!isLoading &&
                  (isActivated ? (
                    <div style={{ margin: "20px 0px 0px 0px" }}>
                      {/* <h5 className="text-success">
                                            Please press the button on the sensor to connect to the gateway.
                                        </h5> */}
                      <div>
                        <span className={s.text}>
                          <p style={{ textAlign: "left" }}>
                            • Press and hold the physical sensor button until it flashes, one sensor at a time.
                          </p>
                          <p style={{ textAlign: "left" }}>
                            • Wait about 60 seconds and the sensor will flash twice after the sensor has successfully
                            paired with the gateway.
                          </p>
                          <p style={{ textAlign: "left" }}>
                            • If pairing is unsuccessful, the sensor will flash rapidly, in that case, retry step 1 or
                            contact AquaTerra support
                          </p>
                          <img
                            src={sensorButton}
                            alt="sensorButton"
                            style={{
                              height: 300,
                              width: 200,
                            }}
                          ></img>
                        </span>
                        {/* <span className="text-success" >
                          <span className="glyphicon glyphicon-ok mr-2" />The
                          sensor paired with the gateway successfully
                        </span> */}
                      </div>
                    </div>
                  ) : (
                    <span className="text-danger">
                      <span className="glyphicon glyphicon-remove mr-2" />
                      {errMsg}
                    </span>
                  ))}
              </Col>
            </Row>
          )}
          {currentStep === 2 && (
            <Row className={s.instructions}>
              <Col sm={12} md={{ size: 10, offset: 1 }}>
                {/*Step 3*/}
                {isLoading && (
                  <span>
                    Loading
                    <Spinner color="primary" />
                  </span>
                )}
                {!isLoading &&
                  (sensors && sensors.length > 0 ? (
                    <>
                      <span className="text-info" style={{ display: "block" }}></span>
                      <span className="text-success" style={{ display: "block" }}>
                        You already have {sensors.length} sensor(s) registered.
                        {sensors.map((sensor) => (
                          <div>• {sensor.sensorId}</div>
                        ))}
                      </span>

                      <span className={s.text}>
                        Please select a gatewayID, enter the new sensorID and click sensor location.
                      </span>
                      {gatewayIds.map((gateway) => (
                        <div onChange={(e) => this.setState({ gatewayID: e.target.value })}>
                          {" "}
                          <input type="radio" value={gateway} name="gateway" /> {gateway}
                        </div>
                      ))}
                      <Input
                        type="text"
                        value={this.state.sensorID}
                        placeholder="Enter sensorId"
                        name="sensorID" //this.setState({ marker: null });
                        required
                        id="input-sensorID"
                        onChange={(e) => this.setState({ sensorID: e.target.value })}
                      />
                      <div style={{ margin: "25px 0px 0px 0px" }}>
                        <Alert color="primary">
                          • Please place a marker using <img src={MapPin} alt="" width="30" height="30" /> icon to
                          locate your sensor on the map.
                        </Alert>
                        <h6 className={s.handDown}>
                          Click <span className="glyphicon glyphicon-hand-down" />
                        </h6>
                        <DisplayMap
                          googleMapURL={googleMapAPI}
                          loadingElement={<div style={{ height: "100%", width: "100%" }} />}
                          containerElement={<div style={{ height: "200px", width: "100%" }} />}
                          mapElement={<div style={{ height: "100%", width: "100%" }} />}
                          drawComplete={this.drawComplete}
                          polyline={polyline}
                          sensors={sensors}
                          marker={marker}
                        />
                      </div>
                    </>
                  ) : (
                    <span className="text-danger">No sensor found.</span>
                  ))}
              </Col>
            </Row>
          )}
          {currentStep === 3 && (
            <Row className={s.instructions}>
              <Col className={s.InfoText} sm={{ size: 12, offset: 0 }}>
                {this.state.isLoading && <Spinner color="secondary" />}
                {!this.state.isLoading &&
                  (this.state.result ? (
                    <>
                      <span className="text-success">
                        <span className="glyphicon glyphicon-ok mr-2" />
                        The sensor is successfully paired with the gateway and located on the map.
                      </span>
                      <br />
                      <span className={s.text}>To install more sensors, repeat the whole process.</span>
                      <span className={s.text} style={{ display: "inline-block" }}>
                        Once done with all sensors, go to{" "}
                        <Link to="/app/Management/SensorManagement">"Management {">"} My Sensors"</Link> to check.
                      </span>
                      <span className={s.text}>Now, go to the field to install sensors on the ground.</span>
                    </>
                  ) : (
                    <span className="text-danger">
                      <span className="glyphicon glyphicon-remove mr-2" />
                      Registration error. Please try again later.
                    </span>
                  ))}
              </Col>
            </Row>
          )}
        </ModalBody>
        <ModalFooter>
          {0 < currentStep && currentStep < 3 && (
            <Button color="primary" className="mr-auto" onClick={this.onClickBack} outline>
              Back
            </Button>
          )}
          {currentStep < 3 && (
            <Button color="danger" onClick={this.onClickClose} outline>
              Cancel
            </Button>
          )}
          {currentStep === 2 && !isLoading && (
            <Button onClick={this.onClickClear} color="primary" className="floar-right mr-1" outline>
              Clear
            </Button>
          )}
          {currentStep < 2 && (
            <Button disabled={!nextEnabled} onClick={this.onClickNext} color="primary" className="floar-right" outline>
              Next
            </Button>
          )}
          {currentStep === 2 && (
            <Button
              onClick={
                this.state.sensorID !== "" && this.state.gatewayID !== "" && this.state.marker !== null
                  ? this.onClickSubmit
                  : () => {}
              } //{ this.onClickSubmit }
              color="primary"
              className="floar-right"
              disabled={this.state.sensorID === "" || this.state.gatewayID === "" || this.state.marker === null}
              outline
            >
              Submit
            </Button>
          )}
          {currentStep === 3 && (
            <Button onClick={this.onClickClose} color="danger" className="floar-right" outline>
              Close
            </Button>
          )}
        </ModalFooter>
      </Modal>
    );
  }
}

SensorRegistrationModal.propTypes = {
  fieldId: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  polyline: PropTypes.array.isRequired,
  userName: PropTypes.string.isRequired,
};

function mapStateToProps() {
  return {};
}

export default connect(mapStateToProps)(SensorRegistrationModal);
