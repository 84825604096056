import React, { PureComponent } from "react";
import { connect } from "react-redux";
import {
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Container,
  Card,
  CardBody,
  CardTitle,
  CardText,
} from "reactstrap";
import { retriveUser, updateUser } from "../../actions/cognito";
import { toast } from "react-toastify";

class Checkout extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      selectedOption: 1,
      step: 1,
      email: "",
      firstName: "",
      lastName: "",
      address: "",
      city: "",
      country: "",
      postcode: "",
      phone: "",
      cardNumber: "",
      cardName: "",
      expiryMonth: "",
      expiryYear: "",
      cvv: "",
      choosedSubscription: "",
    };
  }

  componentDidMount() {
    if (this.props.plan === 0) {
      this.setState({ price: "$199.00", choosedSubscription: "lite", requestPrice: "199" });
    } else if (this.props.plan === 1) {
      this.setState({ price: "$399.00", choosedSubscription: "full", requestPrice: "399" });
    }
    if (window.Square) {
      const payments = window.Square.payments("sandbox-sq0idb-abPSCM7IUm7YWzXsXsbNXg", "LTZZAZHEENTDA");
      // eslint-disable-next-line
      const card = payments.card().then((result) => {
        result.attach("#card-container");
        this.setState({ card: result });
      });

      // Continue your setup...
    }
  }
  goBack = () => {
    this.props.displayStepPage(0);
  };

  handleInputChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  goToStep2 = () => {
    this.setState({ step: 2 });
  };

  goToStep3 = () => {
    this.setState({ step: 3 });
  };

  goToStep1 = () => {
    this.setState({ step: 1 });
  };

  updateSub = (e) => {
    this.setState({ isFetching: true });
    e.preventDefault();
    this.props
      .dispatch(retriveUser())
      .then((res) => {
        let parts = res.expiredDate.split("-");
        let subDate = new Date(parts[2], parts[1] - 1, parts[0]);
        subDate.setFullYear(subDate.getFullYear() + 1);
        this.setState({
          subDate:
            ("0" + subDate.getDate()).slice(-2) +
            "-" +
            ("0" + (subDate.getMonth() + 1)).slice(-2) +
            "-" +
            subDate.getFullYear(),
        });
        this.requestPayment(e).then((data) => {
          console.log(data);
          if (data?.error) {
            toast.error(
              <div>
                <span className="glyphicon glyphicon-info-sign ml-2 mr-2" />
                Failed Payment. {data.error}
              </div>
            );
            return;
          }
          if (data.payment.status === "COMPLETED") {
            this.props
              .dispatch(
                updateUser({
                  expiredDate: this.state.subDate,
                  subscriptionType: this.state.choosedSubscription,
                })
              )
              .then((data) => {
                toast.success(
                  <div>
                    <span className="glyphicon glyphicon-info-sign ml-2 mr-2" />
                    Update was successful.
                  </div>
                );
              });
          } else {
            toast.error(
              <div>
                <span className="glyphicon glyphicon-info-sign ml-2 mr-2" />
                Failed Payment.
              </div>
            );
          }
        });
      })
      .finally(() => {
        this.setState({ isFetching: false });
      });
  };

  tokenize = async (paymentMethod) => {
    const tokenResult = await paymentMethod.tokenize();
    if (tokenResult.status === "OK") {
      return tokenResult.token;
    } else {
      let errorMessage = `Tokenization failed with status: ${tokenResult.status}`;
      if (tokenResult.errors) {
        errorMessage += ` and errors: ${JSON.stringify(tokenResult.errors)}`;
      }

      throw new Error(errorMessage);
    }
  };
  requestPayment = (e) => {
    this.setState({ isFetching: true });
    e.preventDefault();
    const config = {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: `price=${this.state.requestPrice}&token=${this.state.token}`,
    };

    return fetch("/api/user/square_payment", config).then((res) =>
      res
        .json()
        .then((data) => {
          console.log("data is ", data);
          let object = JSON.parse(data);
          return object;
        })
        .catch((err) => {
          console.log(err);
          toast.error(
            <div>
              <span className="glyphicon glyphicon-info-sign ml-2 mr-2" />
              Failed Payment.
            </div>
          );
        })
    );
  };

  render() {
    const { step } = this.state;
    return (
      <div className="checkout-page">
        <Container>
          <Row className="my-5">
            <Col md={{ size: 8, order: 2 }} className={step === 1 ? "" : "d-none"}>
              <Card>
                <CardBody>
                  <h4 className="mb-4">Shipping Information</h4>
                  <Form>
                    <Row form>
                      <Col md={6}>
                        <FormGroup>
                          <Label for="firstName">First Name</Label>
                          <Input
                            type="text"
                            name="firstName"
                            id="firstName"
                            placeholder="John"
                            value={this.state.firstName}
                            onChange={this.handleInputChange}
                          />
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup>
                          <Label for="lastName">Last Name</Label>
                          <Input
                            type="text"
                            name="lastName"
                            id="lastName"
                            placeholder="Doe"
                            value={this.state.lastName}
                            onChange={this.handleInputChange}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <FormGroup>
                      <Label for="address">Address</Label>
                      <Input
                        type="text"
                        name="address"
                        id="address"
                        placeholder="1234 Main St"
                        value={this.state.address}
                        onChange={this.handleInputChange}
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label for="city">City</Label>
                      <Input
                        type="text"
                        name="city"
                        id="city"
                        placeholder="San Francisco"
                        value={this.state.city}
                        onChange={this.handleInputChange}
                      />
                    </FormGroup>
                    <Row form>
                      <Col md={6}>
                        <FormGroup>
                          <Label for="country">Country</Label>
                          <Input
                            type="text"
                            name="country"
                            id="country"
                            placeholder="USA"
                            value={this.state.country}
                            onChange={this.handleInputChange}
                          />
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup>
                          <Label for="postcode">Postcode</Label>
                          <Input
                            type="text"
                            name="postcode"
                            id="postcode"
                            placeholder="12345"
                            value={this.state.postcode}
                            onChange={this.handleInputChange}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <FormGroup>
                      <Label for="phone">Phone</Label>
                      <Input
                        type="text"
                        name="phone"
                        id="phone"
                        placeholder="+1 123 456 7890"
                        value={this.state.phone}
                        onChange={this.handleInputChange}
                      />
                    </FormGroup>
                    <Button color="primary" onClick={this.goToStep2}>
                      Next
                    </Button>
                  </Form>
                </CardBody>
              </Card>
            </Col>
            <Col md={{ size: 4, order: 1 }} className="mb-4 mb-md-0">
              <Card>
                <CardBody>
                  <CardTitle tag="h5">Order Summary</CardTitle>
                  <CardText>
                    <div className="d-flex justify-content-between mb-2">
                      <div>Webapp subscription</div>
                      <div>{this.state.price}</div>
                    </div>
                    <div className="d-flex justify-content-between mb-2">
                      <div>Subtotal</div>
                      <div>{this.state.price}</div>
                    </div>
                    <div className="d-flex justify-content-between mb-2">
                      <div>Taxes</div>
                      <div>$0.00</div>
                    </div>
                    <hr />
                    <div className="d-flex justify-content-between mb-0">
                      <div>Total</div>
                      <div>{this.state.price}</div>
                    </div>
                  </CardText>
                </CardBody>
              </Card>
            </Col>
            <Col md={{ size: 8, order: 2 }} className={step === 2 ? "" : "d-none"}>
              <Card>
                <CardBody>
                  <h4 className="mb-4">Payment Information</h4>
                  <Form>
                    <Row form>
                      <div id="card-container"></div>
                      {/* <Col md={6}>
                        <FormGroup>
                          <Label for="cardNumber">Card Number</Label>
                          <Input
                            type="text"
                            name="cardNumber"
                            id="cardNumber"
                            placeholder="1234 5678 9101 1121"
                            value={this.state.cardNumber}
                            onChange={this.handleInputChange}
                          />
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup>
                          <Label for="cardName">Cardholder Name</Label>
                          <Input
                            type="text"
                            name="cardName"
                            id="cardName"
                            placeholder="John Doe"
                            value={this.state.cardName}
                            onChange={this.handleInputChange}
                          />
                        </FormGroup>
                      </Col> */}
                    </Row>
                    {/* <Row form>
                      <Col md={6}>
                        <FormGroup>
                          <Label for="expiryMonth">Expiry Month</Label>
                          <Input
                            type="text"
                            name="expiryMonth"
                            id="expiryMonth"
                            placeholder="MM"
                            value={this.state.expiryMonth}
                            onChange={this.handleInputChange}
                          />
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup>
                          <Label for="expiryYear">Expiry Year</Label>
                          <Input
                            type="text"
                            name="expiryYear"
                            id="expiryYear"
                            placeholder="YYYY"
                            value={this.state.expiryYear}
                            onChange={this.handleInputChange}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row form>
                      <Col md={6}>
                        <FormGroup>
                          <Label for="cvc">CVC</Label>
                          <Input
                            type="text"
                            name="cvc"
                            id="cvc"
                            placeholder="123"
                            value={this.state.cvc}
                            onChange={this.handleInputChange}
                          />
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup>
                          <Label for="postcode">Postcode</Label>
                          <Input
                            type="text"
                            name="postcode"
                            id="postcode"
                            placeholder="12345"
                            value={this.state.postcode}
                            onChange={this.handleInputChange}
                          />
                        </FormGroup>
                      </Col>
                    </Row> */}
                    <Button
                      color="primary"
                      onClick={() => {
                        console.log(this.state.card);
                        let token;
                        this.tokenize(this.state.card)
                          .then((result) => {
                            token = result;
                            this.goToStep3();
                            this.setState({ token: token });
                          })
                          .catch((err) => {
                            console.log(err.message);
                            toast.error(
                              <div>
                                <span className="glyphicon glyphicon-info-sign ml-2 mr-2" />
                                The payment request is failed.
                                {err.message}
                              </div>
                            );
                          });
                      }}
                    >
                      Next
                    </Button>
                  </Form>
                </CardBody>
              </Card>
            </Col>
            <Col md={{ size: 8, order: 2 }} className={step === 3 ? "" : "d-none"}>
              <Card>
                <CardBody>
                  <h4 className="mb-4">Order Review</h4>
                  <div className="d-flex justify-content-between mb-2">
                    <div>Email</div>
                    <div>{this.state.email}</div>
                  </div>
                  <div className="d-flex justify-content-between mb-2">
                    <div>First Name</div>
                    <div>{this.state.firstName}</div>
                  </div>
                  <div className="d-flex justify-content-between mb-2">
                    <div>Last Name</div>
                    <div>{this.state.lastName}</div>
                  </div>
                  <div className="d-flex justify-content-between mb-2">
                    <div>Address</div>
                    <div>{this.state.address}</div>
                  </div>
                  <div className="d-flex justify-content-between mb-2">
                    <div>City</div>
                    <div>{this.state.city}</div>
                  </div>
                  <div className="d-flex justify-content-between mb-2">
                    <div>Country</div>
                    <div>{this.state.country}</div>
                  </div>
                  <div className="d-flex justify-content-between mb-2">
                    <div>Postcode</div>
                    <div>{this.state.postcode}</div>
                  </div>
                  <div className="d-flex justify-content-between mb-2">
                    <div>Phone</div>
                    <div>{this.state.phone}</div>
                  </div>
                  <hr />
                  {/* <hr /> */}
                  <div className="d-flex justify-content-between mb-2">
                    <div>Order Total</div>
                    <div>{this.state.price}</div>
                  </div>
                  <Button color="primary" onClick={this.updateSub}>
                    Submit Order
                  </Button>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

export default connect(mapStateToProps)(Checkout);
