import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import {
  Col,
  Row,
  Alert,
  Button,
  Form,
  FormGroup,
  FormFeedback,
  Label,
  Input,
  Spinner,
  ToastHeader,
} from "reactstrap";
import s from "./FormInput.module.scss";
import Stepper from "react-stepper-horizontal";
import MapWithADrawingManager from "./DrawingManager/DrawingManager";
import { fetchField } from "../../../actions/field";
import { fetchSensorByField } from "../../../actions/sensor";
import { insertZone, updateZone } from "../../../actions/v2/zone";
import icon from "./polygon icon.png";
import soil_profile from "../../../images/Soil_profile_zone.png";
import { googleMapAPI } from "../../../config/secret";
import { connect } from "react-redux";
import { DEPTH_COLOR } from "../../../constants";

const defaultWP = {
  Clay: 20,
  Loam: 7,
  "Clay-Loam": 17,
  Sand: 2,
};

const defaultFC = {
  Clay: 32,
  Loam: 20,
  "Clay-Loam": 30,
  Sand: 7,
};

const steps = [
  {
    title: "Info",
    onClick: (e) => {
      e.preventDefault();
    },
  },
  {
    title: "Plot",
    onClick: (e) => {
      e.preventDefault();
    },
  },
  {
    title: "Submit",
    onClick: (e) => {
      e.preventDefault();
    },
  },
];

class FormInput extends PureComponent {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    userName: PropTypes.string.isRequired,
    modalClose: PropTypes.func.isRequired,
    zone: PropTypes.object,
    zones: PropTypes.array,
    tableReload: PropTypes.func,
  };

  state = {
    currentStep: 0,
    fields: {},
    choosenField: "",
    zonename: "",
    sensorArr: [],
    cropType: "Rice", // set to deafult values
    soil25: "Clay",
    soil75: "Clay",
    soil125: "Clay",
    wp50: defaultWP["Clay"],
    wp100: defaultWP["Clay"],
    wp150: defaultWP["Clay"],
    fc50: defaultFC["Clay"],
    fc100: defaultFC["Clay"],
    fc150: defaultFC["Clay"],
    saturation50: 30,
    saturation100: 30,
    saturation150: 30,
    polygon: {},
    postLoading: false,
    postStatus: false,
    soilOptions: ["Clay", "Loam", "Clay-Loam", "Sand"],
    interest_layer: "50",
  };

  constructor() {
    super();
    this.onClickNext = this.onClickNext.bind(this);
    this.onClickClear = this.onClickClear.bind(this);
    this.onClickSubmit = this.onClickSubmit.bind(this);
    this.onClickClose = this.onClickClose.bind(this);
    this.getFieldPoints = this.getFieldPoints.bind(this);
    this.getZonePoints = this.getZonePoints.bind(this);
  }

  /**
   * @param {string} id
   */
  getFieldById = (id) => {
    return Object.values(this.state.fields)
      .flat()
      .find((f) => f.field_id === id);
  };

  getFieldPoints = () => {
    if (this.state.choosenField) {
      const field = this.getFieldById(this.state.choosenField);
      let points = JSON.parse(field.points).coordinates[0];
      let coodArr = points.map((p) => ({ lat: p[1], lng: p[0] }));
      return coodArr;
    }
  };

  getZonePoints = () => {
    if (this.state.choosenField && this.props.zones) {
      let zones = this.props.zones.filter((zone) => {
        if (this.props.chosenZone) {
          return (
            zone.field_id === this.props.chosenZone.field_id &&
            zone.id !== this.props.chosenZone.id
          );
        } else {
          return (
            zone.field_id ===
              this.getFieldById(this.state.choosenField).field_id &&
            zone.id !== this.state.choosenField
          );
        }
      });
      let zonArr = {};
      zones.forEach((zone) => {
        zonArr[zone.zonename] = zone.points;
      });
      return zonArr;
    }
    return {};
  };

  getSensorPoints = (val) => {
    let fieldId = val.field_id;
    fetchSensorByField({ fieldId }).then((sensors) => {
      let sensorArr = sensors.map((sensor) => {
        let point = {
          sensorID: sensor.sensor_id,
          lat: JSON.parse(sensor.points).coordinates[1],
          lng: JSON.parse(sensor.points).coordinates[0],
        };
        return point;
      });
      this.setState({
        sensorArr: sensorArr,
      });
    });
  };

  drawComplete = (polygon) => {
    polygon.setMap(null);
    this.setState({ polygon });
  };

  onOptionChange = (key, val) => {
    this.setState({ [key]: val }, () => {
      switch (key) {
        case "choosenFarm":
          this.onOptionChange(
            "choosenField",
            this.state.fields[val][0].field_id
          );
          break;
        case "choosenField":
          this.getSensorPoints(val);
          break;
        case "soil25":
          this.setState({
            wp50: defaultWP[val],
            fc50: defaultFC[val],
            saturation50: 30,
          });
          break;
        case "soil75":
          this.setState({
            wp100: defaultWP[val],
            fc100: defaultFC[val],
            saturation100: 30,
          });
          break;
        case "soil125":
          this.setState({
            wp150: defaultWP[val],
            fc150: defaultFC[val],
            saturation150: 30,
          });
          break;
        default:
          break;
      }
    });
  };

  changeField = (event) => {
    this.setState({ fieldText: event.target.value });
  };

  changeFarm = (event) => {
    if (event) this.setState({ farmText: event.value });
    else this.setState({ farmText: "" });
  };

  onClickClear() {
    this.setState({ polygon: {} });
  }

  onClickNext() {
    const { currentStep } = this.state;
    this.setState({
      currentStep: currentStep + 1,
    });
  }

  onClickClose() {
    this.props.modalClose();
  }

  onClickSubmit() {
    let GeoJSON = {
      type: "Feature",
      properties: {},
    };
    try {
      // try get the coordinate from
      let points = this.state.polygon
        .getPath()
        .getArray()
        .map((p) => [p.lng(), p.lat()]);
      let point = points[0];
      if (point !== points[points.length - 1]) points.push(point);
      GeoJSON["geometry"] = {
        type: "Polygon",
        coordinates: [points],
      };
    } catch (_) {
      if (this.props.zones.includes(this.props.chosenZone)) {
        GeoJSON["geometry"] = {
          type: "Polygon",
          coordinates: [this.props.chosenZone.points.map(({lat, lng}) => [lng, lat])],
        }
      }
    }
    let data = {
      zoneName: this.state.zonename,
      cropType: this.state.cropType,
      soilType25: this.state.soil25,
      soilType75: this.state.soil75,
      soilType125: this.state.soil125,
      wiltingPoint50: this.state.wp50,
      wiltingPoint100: this.state.wp100,
      wiltingPoint150: this.state.wp150,
      fieldCapacity50: this.state.fc50,
      fieldCapacity100: this.state.fc100,
      fieldCapacity150: this.state.fc150,
      saturation50: this.state.saturation50,
      saturation100: this.state.saturation100,
      saturation150: this.state.saturation150,
      geom: JSON.stringify(GeoJSON),
      interest_layer: this.state.interest_layer,
    };
    this.onClickNext();
    this.setState({ postLoading: true });
    if (!this.props.chosenZone) {
      insertZone(this.state.choosenField, data)
        .then((data) => {
          if (data) this.setState({ postStatus: true });
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.props.tableReload();
          this.setState({ postLoading: false });
        });
    } else {
      updateZone(this.props.chosenZone.id, {
        ...data,
        fieldId: this.state.choosenField,
      })
        .then((data) => {
          if (data) this.setState({ postStatus: true });
        })
        .catch((err) => console.log(err))
        .finally(() => {
          this.props.tableReload();
          this.setState({ postLoading: false });
        });
    }
  }

  componentDidMount() {
    const setFields = (fields) => {
      let farms = Object.keys(fields);
      this.setState({ fields });
      if (this.props.chosenZone) {
        let state = {};
        const { chosenZone } = this.props;
        state["zonename"] = chosenZone["zonename"];
        state["choosenField"] = chosenZone["field_id"];
        state["cropType"] = chosenZone["croptype"];
        state["interest_layer"] = chosenZone["interest_layer"];
        for (var k of ["fcapacity", "wpoint", "saturation", "soiltype"]) {
          let statek;
          for (var i of [50, 100, 150]) {
            if (k === "soiltype") i -= 25;
            switch (k) {
              case "soiltype":
                statek = "soil";
                break;
              case "saturation":
                statek = k;
                break;
              default:
                statek = k.substring(0, 2);
                break;
            }
            state[`${statek}${i}`] = chosenZone[`${k}_${i}`];
          }
        }
        this.setState(state);
      } else {
        if (fields[farms[0]])
          this.setState({ choosenField: fields[farms[0]][0].field_id });
      }
    };

    if (this.props.fields) {
      setFields(this.props.fields);
    } else {
      fetchField({ userName: this.props.userName })(this.props.dispatch).then(
        setFields
      );
    }
  }

  render() {
    let {
      currentStep,
      polygon,
      zonename,
      wp50,
      wp100,
      wp150,
      fc50,
      fc100,
      fc150,
      saturation50,
      saturation100,
      saturation150,
      choosenField,
      interest_layer,
    } = this.state;
    const reg = /^[a-zA-Z]+[a-zA-z0-9]{2,}/;
    const zoneNameValid = zonename.length > 0 ? reg.exec(zonename) : true;
    const wp50Valid = wp50 >= 0 && wp50 < 100;
    const wp100Valid = wp100 >= 0 && wp100 < 100;
    const wp150Valid = wp150 >= 0 && wp150 < 100;
    const fc50Valid = fc50 >= 0 && fc50 < 100;
    const fc100Valid = fc100 >= 0 && fc100 < 100;
    const fc150Valid = fc150 >= 0 && fc150 < 100;
    const saturation50Valid = saturation50 >= 0 && saturation50 < 100;
    const saturation100Valid = saturation100 >= 0 && saturation100 < 100;
    const saturation150Valid = saturation150 >= 0 && saturation150 < 100;
    const nextEnabled =
      (currentStep === 0 && zoneNameValid && zonename.length > 2) ||
      (currentStep === 1 &&
        (Object.keys(polygon).length > 0 ||
          this.props.zones.includes(this.props.chosenZone)));
    return (
      <div>
        <Row>
          <Col sm={12} md={{ size: 12, osffset: 0 }}>
            <Stepper
              size={24}
              circleFontSize={12}
              titleFontSize={12}
              steps={steps}
              activeStep={currentStep}
            />
          </Col>
        </Row>

        {/*Step 1 form input*/}
        {currentStep === 0 && (
          <div>
            {/* <div className={s.bdcallout} > */}
            <ToastHeader className={s.title}>
              Zone Basic Information
            </ToastHeader>
            <Row>
              <Col sm={12} md={{ size: 10 }}>
                <Form className="mt-3">
                  <FormGroup row>
                    <Label className="text-right" for="farmSelect" sm={3}>
                      Farm
                    </Label>
                    <Col sm={9}>
                      <Input
                        onChange={(e) =>
                          this.onOptionChange("choosenFarm", e.target.value)
                        }
                        type="select"
                        name="select"
                        id="farmSelect"
                        value={
                          this.getFieldById(choosenField)?.field_name || ""
                        }
                      >
                        {Object.keys(this.state.fields).map((farm, index) => (
                          <option key={index}>{farm}</option>
                        ))}
                      </Input>
                    </Col>
                  </FormGroup>
                </Form>
              </Col>
            </Row>
            <Row>
              <Col sm={12} md={{ size: 10 }}>
                <Form className="mt-3">
                  <FormGroup row>
                    <Label className="text-right" for="fieldSelect" sm={3}>
                      Field
                    </Label>
                    <Col sm={9}>
                      <Input
                        onChange={(e) =>
                          this.onOptionChange("choosenField", e.target.value)
                        }
                        type="select"
                        name="select"
                        id="fieldSelect"
                        value={choosenField}
                      >
                        {choosenField &&
                          this.state.fields[
                            this.getFieldById(choosenField).farm_name
                          ].map((field, index) => (
                            <option value={field.field_id} key={index}>
                              {field.field_name}
                            </option>
                          ))}
                      </Input>
                    </Col>
                  </FormGroup>
                </Form>
              </Col>
            </Row>
            <Row>
              <Col sm={12} md={{ size: 10 }}>
                <Form className="mt-3">
                  <FormGroup row>
                    <Label className="text-right" for="fieldSelect" sm={3}>
                      Zone Name
                    </Label>
                    <Col sm={9}>
                      <Input
                        onChange={(e) =>
                          this.onOptionChange("zonename", e.target.value)
                        }
                        invalid={!zoneNameValid}
                        id="fieldSelect"
                        value={zonename}
                      ></Input>
                      {!zoneNameValid && (
                        <FormFeedback>
                          * Minimum 3 characters, and must start with letters
                        </FormFeedback>
                      )}
                    </Col>
                  </FormGroup>
                </Form>
              </Col>
            </Row>
            <Row>
              <Col sm={12} md={{ size: 10 }}>
                <Form className="mt-3">
                  <FormGroup row>
                    <Label className="text-right" for="cropSelect" sm={3}>
                      Crop Type
                    </Label>
                    <Col sm={9}>
                      <Input
                        type="select"
                        name="select"
                        onChange={(e) =>
                          this.onOptionChange("cropType", e.target.value)
                        }
                        id="cropSelect"
                        value={this.state.cropType}
                      >
                        {/* TODO: add few more options */}
                        <option>Wheet</option>
                        <option>Canola</option>
                        <option>Rice</option>
                        <option>Corn</option>
                        <option>Soy</option>
                        <option>Spinach</option>
                        <option>Rocket</option>
                        <option>Lettuce</option>
                        <option>Kale</option>
                        <option>Mizuna</option>
                        <option>Tatsoi and herbs</option>
                        <option>Coriander</option>
                        <option>Parsley</option>
                        <option>Dill</option>
                        <option>Spring onion</option>
                        <option>Zucchini</option>
                        <option>Cauliflower</option>
                        <option>Pumpkin</option>
                        <option>Others</option>
                      </Input>
                    </Col>
                  </FormGroup>
                </Form>
              </Col>
            </Row>

            <Row>
              <Col sm={12} md={{ size: 10 }}>
                <img
                  src={soil_profile}
                  alt=""
                  style={{
                    width: "450px",
                    height: "auto",
                    marginLeft: "20%",
                    paddingTop: "1.5em",
                  }}
                ></img>
              </Col>
            </Row>
            {/* </div> */}
            {/* ///// */}
            <ToastHeader className={s.title}>
              Soil Type, Wilting Point, Field Capacity, Saturation Point
            </ToastHeader>
            {/* TODO: Add different color for different depth */}
            <div className={[s.bdcallout, s.bdcalloutColor].join(" ")} style={{backgroundColor: DEPTH_COLOR[0]}}>
              {/* <div style={{ border: "2px solid #9fcae3" }}> */}
              <Row>
                <Col sm={12} md={{ size: 10 }}>
                  {/* <ToastBody> */}
                  <Form className="mt-3">
                    <FormGroup row>
                      <Label className="text-right" for="soil25Select" sm={3}>
                        Soil Type 25cm Underground
                      </Label>
                      <Col sm={9}>
                        <Input
                          type="select"
                          name="select"
                          onChange={(e) =>
                            this.onOptionChange("soil25", e.target.value)
                          }
                          id="soil25Select"
                          value={this.state.soil25}
                        >
                          {this.state.soilOptions.map((type, index) => (
                            <option key={index}>{type}</option>
                          ))}
                        </Input>
                      </Col>
                    </FormGroup>
                  </Form>
                  {/* </ToastBody> */}
                  {/* </Toast> */}
                  {/* </div> */}
                </Col>
              </Row>

              <Row>
                <Col sm={12} md={{ size: 10 }}>
                  <Form className="mt-3">
                    <FormGroup row>
                      <Label className="text-right" for="fieldSelect" sm={3}>
                        Wilting Point 25cm
                      </Label>
                      <Col sm={9}>
                        <Input
                          onChange={(e) =>
                            this.onOptionChange("wp50", e.target.value)
                          }
                          value={this.state.wp50}
                          invalid={!wp50Valid}
                          id="wPoint50"
                        ></Input>
                        {!wp50Valid && (
                          <FormFeedback>
                            * Please enter a number between 0 - 100
                          </FormFeedback>
                        )}
                      </Col>
                    </FormGroup>
                  </Form>
                </Col>
              </Row>

              <Row>
                <Col sm={12} md={{ size: 10 }}>
                  <Form className="mt-3">
                    <FormGroup row>
                      <Label className="text-right" for="fieldSelect" sm={3}>
                        Field Capacity 25cm
                      </Label>
                      <Col sm={9}>
                        <Input
                          onChange={(e) =>
                            this.onOptionChange("fc50", e.target.value)
                          }
                          value={this.state.fc50}
                          invalid={!fc50Valid}
                          id="fc50"
                        ></Input>
                        {!fc50Valid && (
                          <FormFeedback>
                            * Please enter a number between 0 - 100
                          </FormFeedback>
                        )}
                      </Col>
                    </FormGroup>
                  </Form>
                </Col>
              </Row>

              <Row>
                <Col sm={12} md={{ size: 10 }}>
                  <Form className="mt-3">
                    <FormGroup row>
                      <Label className="text-right" for="fieldSelect" sm={3}>
                        Saturation Point 25cm
                      </Label>
                      <Col sm={9}>
                        <Input
                          onChange={(e) =>
                            this.onOptionChange("saturation50", e.target.value)
                          }
                          value={this.state.saturation50}
                          invalid={!saturation50Valid}
                          id="saturation50"
                        ></Input>
                        {!saturation50Valid && (
                          <FormFeedback>
                            * Please enter a number between 0 - 100
                          </FormFeedback>
                        )}
                      </Col>
                    </FormGroup>
                  </Form>
                </Col>
              </Row>

              <Row>
                <Col sm={12} md={10}>
                  <Form>
                    <FormGroup check inline>
                      <Input
                        type="checkbox"
                        checked={interest_layer === "50"}
                        onChange={() => this.setState({ interest_layer: "50" })}
                      />
                      <Label check>Make this layer primary</Label>
                    </FormGroup>
                  </Form>
                </Col>
              </Row>
            </div>

            {/* ///// */}
            <div className={[s.bdcallout, s.bdcalloutColor].join(" ")} style={{backgroundColor: DEPTH_COLOR[1]}}>
              <Row>
                <Col sm={12} md={{ size: 10 }}>
                  <Form className="mt-3">
                    <FormGroup row>
                      <Label className="text-right" for="soil75Select" sm={3}>
                        Soil Type 75cm Underground
                      </Label>
                      <Col sm={9}>
                        <Input
                          type="select"
                          name="select"
                          onChange={(e) =>
                            this.onOptionChange("soil75", e.target.value)
                          }
                          id="soil75Select"
                          value={this.state.soil75}
                        >
                          {this.state.soilOptions.map((type, index) => (
                            <option key={index}>{type}</option>
                          ))}
                        </Input>
                      </Col>
                    </FormGroup>
                  </Form>
                </Col>
              </Row>
              <Row>
                <Col sm={12} md={{ size: 10 }}>
                  <Form className="mt-3">
                    <FormGroup row>
                      <Label className="text-right" for="fieldSelect" sm={3}>
                        Wilting Point 75cm
                      </Label>
                      <Col sm={9}>
                        <Input
                          onChange={(e) =>
                            this.onOptionChange("wp100", e.target.value)
                          }
                          invalid={!wp100Valid}
                          value={this.state.wp100}
                          id="wPoint100"
                        ></Input>
                        {!wp100Valid && (
                          <FormFeedback>
                            * Please enter a number between 0 - 100
                          </FormFeedback>
                        )}
                      </Col>
                    </FormGroup>
                  </Form>
                </Col>
              </Row>
              <Row>
                <Col sm={12} md={{ size: 10 }}>
                  <Form className="mt-3">
                    <FormGroup row>
                      <Label className="text-right" for="fieldSelect" sm={3}>
                        Field Capacity 75cm
                      </Label>
                      <Col sm={9}>
                        <Input
                          onChange={(e) =>
                            this.onOptionChange("fc100", e.target.value)
                          }
                          value={this.state.fc100}
                          invalid={!fc100Valid}
                          id="fc100"
                        ></Input>
                        {!fc100Valid && (
                          <FormFeedback>
                            * Please enter a number between 0 - 100
                          </FormFeedback>
                        )}
                      </Col>
                    </FormGroup>
                  </Form>
                </Col>
              </Row>
              <Row>
                <Col sm={12} md={{ size: 10 }}>
                  <Form className="mt-3">
                    <FormGroup row>
                      <Label className="text-right" for="fieldSelect" sm={3}>
                        Saturation Point 75cm
                      </Label>
                      <Col sm={9}>
                        <Input
                          onChange={(e) =>
                            this.onOptionChange("saturation100", e.target.value)
                          }
                          value={this.state.saturation100}
                          invalid={!saturation100Valid}
                          id="saturation100"
                        ></Input>
                        {!saturation100Valid && (
                          <FormFeedback>
                            * Please enter a number between 0 - 100
                          </FormFeedback>
                        )}
                      </Col>
                    </FormGroup>
                  </Form>
                </Col>
              </Row>
              <Row>
                <Col sm={12} md={10}>
                  <Form>
                    <FormGroup check inline>
                      <Input
                        type="checkbox"
                        checked={interest_layer === "100"}
                        onChange={() =>
                          this.setState({ interest_layer: "100" })
                        }
                      />
                      <Label check>Make this layer primary</Label>
                    </FormGroup>
                  </Form>
                </Col>
              </Row>
            </div>

            <div className={[s.bdcallout, s.bdcalloutColor].join(" ")} style={{backgroundColor: DEPTH_COLOR[2]}}>
              <Row>
                <Col sm={12} md={{ size: 10 }}>
                  <Form className="mt-3">
                    <FormGroup row>
                      <Label className="text-right" for="soil125Select" sm={3}>
                        Soil Type 125cm Underground
                      </Label>
                      <Col sm={9}>
                        <Input
                          type="select"
                          name="select"
                          onChange={(e) =>
                            this.onOptionChange("soil125", e.target.value)
                          }
                          id="soil125Select"
                          value={this.state.soil125}
                        >
                          {this.state.soilOptions.map((type, index) => (
                            <option key={index}>{type}</option>
                          ))}
                        </Input>
                      </Col>
                    </FormGroup>
                  </Form>
                </Col>
              </Row>
              <Row>
                <Col sm={12} md={{ size: 10 }}>
                  <Form className="mt-3">
                    <FormGroup row>
                      <Label className="text-right" for="fieldSelect" sm={3}>
                        Wilting Point 125cm
                      </Label>
                      <Col sm={9}>
                        <Input
                          onChange={(e) =>
                            this.onOptionChange("wp150", e.target.value)
                          }
                          invalid={!wp150Valid}
                          value={this.state.wp150}
                          id="wPoint150"
                        ></Input>
                        {!wp150Valid && (
                          <FormFeedback>
                            * Please enter a number between 0 - 100
                          </FormFeedback>
                        )}
                      </Col>
                    </FormGroup>
                  </Form>
                </Col>
              </Row>
              <Row>
                <Col sm={12} md={{ size: 10 }}>
                  <Form className="mt-3">
                    <FormGroup row>
                      <Label className="text-right" for="fieldSelect" sm={3}>
                        Field Capacity 125cm
                      </Label>
                      <Col sm={9}>
                        <Input
                          onChange={(e) =>
                            this.onOptionChange("fc150", e.target.value)
                          }
                          value={this.state.fc150}
                          invalid={!fc150Valid}
                          id="fc150"
                        ></Input>
                        {!fc150Valid && (
                          <FormFeedback>
                            * Please enter a number between 0 - 100
                          </FormFeedback>
                        )}
                      </Col>
                    </FormGroup>
                  </Form>
                </Col>
              </Row>
              <Row>
                <Col sm={12} md={{ size: 10 }}>
                  <Form className="mt-3">
                    <FormGroup row>
                      <Label className="text-right" for="fieldSelect" sm={3}>
                        Saturation Point 125cm
                      </Label>
                      <Col sm={9}>
                        <Input
                          onChange={(e) =>
                            this.onOptionChange("saturation150", e.target.value)
                          }
                          value={this.state.saturation150}
                          invalid={!saturation150Valid}
                          id="saturation150"
                        ></Input>
                        {!saturation150Valid && (
                          <FormFeedback>
                            * Please enter a number between 0 - 100
                          </FormFeedback>
                        )}
                      </Col>
                    </FormGroup>
                  </Form>
                </Col>
              </Row>
              <Row>
                <Col sm={12} md={10}>
                  <Form>
                    <FormGroup check inline>
                      <Input
                        type="checkbox"
                        checked={interest_layer === "150"}
                        onChange={() =>
                          this.setState({ interest_layer: "150" })
                        }
                      />
                      <Label check>Make this layer primary</Label>
                    </FormGroup>
                  </Form>
                </Col>
              </Row>
            </div>
          </div>
        )}

        {/*Step 2 Map Drawer*/}
        {/* TODO: Improve the drawing mechanism */}
        {currentStep === 1 && (
          <Row>
            {/* <Col sm={{ size: 12, offset: 0 }}> */}
            {/* <Col>
								<Alert color="primary">
										This is a secondary alert — check it out!
								</Alert>
							</Col> */}
            <div className={s.mapContainer}>
              <Alert color="primary">
                • Please draw a polygon shape on the map using the{" "}
                <img src={icon} alt="" width="30" height="30" /> to outline your
                zone.
              </Alert>
              <h6 className={s.handDown}>
                Click <span className="glyphicon glyphicon-hand-down" />
              </h6>
              <MapWithADrawingManager
                googleMapURL={googleMapAPI}
                loadingElement={
                  <div style={{ height: "100%", width: "100%" }} />
                }
                containerElement={
                  <div style={{ height: "100%", width: "100%" }} />
                }
                mapElement={<div style={{ height: "100%", width: "100%" }} />}
                drawComplete={this.drawComplete}
                fieldPolygon={this.getFieldPoints()}
                zoneArr={this.getZonePoints()}
                sensorArr={this.state.sensorArr}
                polygon={polygon}
                chosenZonePolygon={
                  this.props.chosenZone?.points
                    ? {
                        zonename: this.props.chosenZone.zonename,
                        points: this.props.chosenZone.points,
                      }
                    : undefined
                }
              />
            </div>
          </Row>
        )}

        {/*Step 3 Confirm Page */}
        {currentStep === 2 && (
          <Row>
            <Col className={s.InfoText} sm={{ size: 12, offset: 0 }}>
              <span>
                Click "Submit" to finish registering your zone:{" "}
                <span className="text-success" style={{ display: "block" }}>
                  {zonename}
                </span>
              </span>
            </Col>
          </Row>
        )}

        {/*Step 4 Result Page */}
        {currentStep === 3 && (
          <Row>
            <Col className={s.InfoText} sm={{ size: 12, offset: 0 }}>
              {this.state.postLoading && <Spinner color="secondary" />}
              {!this.state.postLoading &&
                (this.state.postStatus ? (
                  <div style={{ margin: "25px 0px 0px 0px" }}>
                    <span className="text-success">
                      <span className="glyphicon glyphicon-ok mr-2" />
                      Zone registered successfully
                    </span>
                  </div>
                ) : (
                  <span className="text-danger">
                    <span className="glyphicon glyphicon-remove mr-2" />
                    Zone create error. Please try again later.
                  </span>
                ))}
            </Col>
          </Row>
        )}

        {/*Button */}

        <Row className="mb-3 mt-2">
          <Col sm={12} md={{ size: 3, offset: 9 }}>
            {currentStep === 1 && (
              <Button
                onClick={this.onClickClear}
                color="primary"
                className="floar-right mr-1"
                outline
              >
                Undo
              </Button>
            )}
            {currentStep < 2 && (
              <Button
                disabled={!nextEnabled}
                onClick={this.onClickNext}
                color="primary"
                className="floar-right"
                outline
              >
                Next
              </Button>
            )}
            {currentStep === 2 && (
              <Button
                onClick={this.onClickSubmit}
                color="primary"
                className="floar-right"
                outline
              >
                Submit
              </Button>
            )}
            {currentStep === 3 && (
              <Button
                onClick={this.onClickClose}
                color="danger"
                className="floar-right"
                outline
              >
                Close
              </Button>
            )}
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  fields: state.field.farmsWithFields,
});

export default connect(mapStateToProps)(FormInput);
