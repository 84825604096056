/**
 * Fetch Farm Data
 * @param  Post request parameters
 * @return Farm data
 */
export function fetchFarm(params) {
  let keys = Object.keys(params);
  const config = {
    method: "POST",
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
    credentials: "include",
    body: keys.map((k) => `${k}=${params[k]}`).join("&"),
  };

  // We dispatch requestFetchField to kickoff the call to the API
  //dispatch(requestFetchField());

  return fetch("/api/farm", config)
    .then((res) =>
      res.json().then((responseJson) => ({
        farm: responseJson.data,
        error: responseJson.err,
      }))
    )
    .then((data) => {
      if (data.error) {
        // If there was a problem, we want to
        // dispatch the error condition
        //dispatch(fetchFieldError(data.error));
        return Promise.reject(data.error);
      }
      //dispatch(fetchFieldSuccess(data.fields));
      return Promise.resolve(data.farm);
    })
    .catch((err) => console.error("Error: ", err));
}

/**
 * Insert Farm Data
 * @param  Post request parameters
 * @return Farm data
 */
export function insertFarm(params) {
  let keys = Object.keys(params);
  const config = {
    method: "POST",
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
    credentials: "include",
    body: keys.map((k) => `${k}=${params[k]}`).join("&"),
  };

  return fetch("/api/farm/addFarm", config)
    .then((res) =>
      res.json().then((responseJson) => ({
        farm: responseJson.data,
        error: responseJson.err,
      }))
    )
    .then((data) => {
      if (data.error) {
        // If there was a problem, we want to
        return Promise.reject(data.error);
      }
      return Promise.resolve(data.farm);
    })
    .catch((err) => console.error("Error: ", err));
}

/**
 * Delete farm
 * @param params.userName-required params.farmname-required
 * @returns {function(*): Promise<{field: *, error} | void>}
 */
export function deleteFarm(params) {
  const config = {
    method: "DELETE",
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
    credentials: "include",
  };

  return (dispatch) => {
    return fetch(`/api/farm/${params.userName}/${params.farmname}`, config)
      .then((res) =>
        res.json().then((responseJson) => ({
          farm: responseJson.data,
          error: responseJson.err,
        }))
      )
      .then((data) => {
        if (data.error) {
          return Promise.reject(data.error);
        }
        return Promise.resolve(data.farm);
      })
      .catch((err) => console.error("Error: ", err));
  };
}
