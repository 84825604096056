/**
 * Get all formula, update formula, create formula
 * @param {method, param, body} params
 */
export function formulaEndpoint(params) {
  let { method, param, body } = params;
  var config = {
    method: method,
    headers: { "Content-Type": "application/json" },
    credentials: "include",
  };
  param = param ? param : "";
  if (body) {
    config.body = JSON.stringify(body);
  }

  return (dispatch) => {
    return fetch(`/api/sensorformula${"/" + param}`, config)
      .then((res) =>
        res.json().then((result) => ({
          data: result.data,
          error: result.error,
        }))
      )
      .then((data) => {
        if (data.error) {
          return Promise.reject(data.error);
        }
        return Promise.resolve(data);
      })
      .catch((err) => Promise.reject(err));
  };
}
