import React, { useCallback, useEffect, useMemo, useState } from "react";
import ReactWeather from "react-open-weather";
import { useDashboardHook } from "../hooks/useDashboardHooks";
import axios, { AxiosRequestConfig } from "axios";
import svgIcons from "react-open-weather/src/js/svgIcons";
import { getCenter } from "../util";
import { VisualCrossKey } from "src/config/secret";

const mapToIcon: { [key: string]: string } = {
  "clear-day": "sunny",
  "clear-night": "sunny",
  cloudy: "cloudy",
  "partly-cloudy-day": "cloudy",
  "partly-cloudy-night": "cloudy",
  wind: "cloudy",
  rain: "rain",
  fog: "fog",
  snow: "snow",
  "showers-day": "showers",
  "showers-night": "showers",
  "snow-showers-day": "snow",
  "snow-showers-night": "snow",
  "thunder-rain": "thunderstorms",
  "thunder-showers-day": "thunderstorms",
  "thunder-showers-night": "thunderstorms",
};

const VisualCrossingURL =
  "https://weather.visualcrossing.com/VisualCrossingWebServices/rest/services/timeline";

const WeatherTable = () => {
  const { chosenField } = useDashboardHook();

  const [data, setData] = useState<unknown>();
  const [location, setLocation] = useState("");

  const center = useMemo(
    () =>
      chosenField.coordinates
        ? getCenter(chosenField.coordinates)
        : { lat: 0, lng: 0 },
    [chosenField]
  );

  const fetchWeather = useCallback(
    async (config?: AxiosRequestConfig) => {
      let today = new Date();
      let startDate = `${today.getFullYear()}-${today.getMonth() + 1
        }-${today.getDate()}`;

      let fourthDay = new Date();
      fourthDay.setDate(today.getDate() + 4);
      let endDate = `${fourthDay.getFullYear()}-${fourthDay.getMonth() + 1
        }-${fourthDay.getDate()}`;

      // TODO: fetch weather according to farm or field location
      let res = await axios.get(
        `${VisualCrossingURL}/${center.lat.toString()},${center.lng.toString()}/${startDate}/${endDate}`,
        config
      );
      const { days, currentConditions } = res.data;
      // format the forcast weather data
      const forecast = days.map(
        (day: { icon: string;[key: string]: any }) => ({
          date: new Date(day.datetime).toDateString(),
          description: day.conditions,
          icon: svgIcons[mapToIcon[day.icon]],
          temperature: {
            min: Math.round(day.tempmin),
            max: Math.round(day.tempmax),
          },
          wind: Math.round(day.windspeed * 1.60934 * 10) / 10, // miles/h to km/h, 1 decimal place
          humidity: day.humidity,
        })
      );
      // format the current weather data
      let current = forecast[0];
      current.temperature.current = Math.round(currentConditions.temp);
      const data = {
        forecast,
        current,
      };

      setData(data);
      setLocation(res.data.timezone.split("/")[1]);
    },
    [center]
  );

  useEffect(() => {
    const source = axios.CancelToken.source();
    fetchWeather({
      params: {
        unitGroup: "uk",
        key: VisualCrossKey,
      },
      cancelToken: source.token,
    })
      .catch(console.log);
    return () => source.cancel();
  }, [fetchWeather]);

  return (
    <ReactWeather isLoading={false} data={data} locationLabel={location} />
  );
};

export default WeatherTable;
