export const formatDate = (d: string) => {
  const date = new Date(d);
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();

  return `${day}/${month}/${year}`;
};

// referent to: https://en.wikipedia.org/wiki/Centroid#Of_a_polygon
export const getCenter = (
  path: { lat: number; lng: number }[] | undefined
): { lat: number; lng: number } => {
  if (!path || path.length === 0) {
    // Return a default center if the path is empty
    return { lat: 0, lng: 0 };
  }

  let area = 0,
    a = 0,
    center = { lat: 0, lng: 0 };

  for (let i = 0; i < path.length - 1; i++) {
    let p1 = path[i],
      p2 = path[i + 1];
    a = p1.lat * p2.lng - p2.lat * p1.lng;
    area += a;

    center.lng += (p1.lng + p2.lng) * a;
    center.lat += (p1.lat + p2.lat) * a;
  }

  area *= 0.5
  center.lng /= (6 * area)
  center.lat /= (6*area)
  return center
};
