import React from "react";
import PropTypes from "prop-types";
import {
  Button,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Alert,
  Input,
  Label,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { connect } from "react-redux";
import {
  fetchSensorByField,
  fetchSensorById,
  insertNewSensor,
  updateSensorData,
} from "../../../actions/sensor";
import s from "./FormInput.module.scss";
import SensorMap from "./SensorMap";
import { googleMapAPI } from "../../../config/secret";
import MapPin from "../../../images/MapPin.png";
import { fetchField, fetchFieldById } from "../../../actions/field";
import { toast } from "react-toastify";
import ButtonDropdownMenu from "../../../components/dropdown-menu";
import { PostgresError } from "pg-error-enum";

class SensorRegistrationModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      marker: null,
      sensors: [],
      errMsg: "",
      isLoading: false,
      polyline: [],
      newSensor: "",
      sensor: { sensor_id: "" },
      showSensors: true,
      loaded: false,
      update: { ali: "", sleeping: 7200, battery_threshold: 80 },
      choosenField: {},
      step: 1,
    };

    this.showSensors = this.showSensors.bind(this);
  }

  componentDidMount() {
    this.setState({ isLoading: true });
    if (!this.props.fields)
      fetchField({ userName: this.props.userName })(this.props.dispatch);
    fetchFieldById({ fieldId: this.props.fieldId })
      .then((res) => {
        let points = JSON.parse(res.points).coordinates[0];
        let polyline = points.map((point) => ({
          lng: point[0],
          lat: point[1],
        }));
        this.setState({ choosenField: res, polyline: polyline });
      })
      .finally(() => {
        if (this.props.sensor) {
          fetchSensorById(
            this.props.sensor,
            this.props.userName,
            this.props.fieldId
          ).then((res) => {
            this.setState({
              sensor: res,
              sensors: [
                {
                  sensorId: res["sensor_id"],
                  latlng: JSON.parse(res.points).coordinates,
                },
              ],
              update: {
                lng: JSON.parse(res.points).coordinates[0],
                lat: JSON.parse(res.points).coordinates[1],
                ali: res.alias,
                sleeping: res.sleeping,
                field_id: res.field_id,
                battery_threshold: res.battery_threshold
              },
              loaded: true,
            });
          });
        } else {
          fetchSensorByField({ fieldId: this.props.fieldId }).then((res) => {
            let sensors = [];
            res.map((sensor) =>
              sensors.push({
                sensorId: sensor.sensor_id,
                latlng: JSON.parse(sensor.points).coordinates,
                alias: sensor.alias,
              })
            );
            this.setState({ sensors });
          });
        }
      });
    this.setState({ isLoading: false });
  }

  updateInput(inputId, value) {
    let { update } = this.state;
    update[inputId] = value;
    if (
      inputId === "lat" ||
      (inputId === "lng" && update["lat"] && update["lng"])
    ) {
      class Marker {
        constructor(location) {
          this.lng = parseFloat(location.lng);
          this.lat = parseFloat(location.lat);
        }
        getPosition() {
          return { lng: this.lng, lat: this.lat };
        }
      }
      let marker = new Marker(update);
      this.setState({ marker });
    }
    this.setState({ update });
  }

  updateField(field) {
    let points = JSON.parse(field.points).coordinates[0];
    let polyline = points.map((point) => ({
      lng: point[0],
      lat: point[1],
    }));
    this.setState({ choosenField: field, polyline: polyline });
  }

  showSensors() {
    this.setState({ showSensors: !this.state.showSensors });
  }

  drawComplete = (marker) => {
    marker.setMap(null);
    let latLng = marker.getPosition();
    let { update } = this.state;
    update["lng"] = parseFloat(latLng.lng()).toFixed(6);
    update["lat"] = parseFloat(latLng.lat()).toFixed(6);
    this.setState({ update });
    this.setState({ marker: marker });
  };

  onClickSubmit = () => {
    let sensorId = this.state.sensor.sensor_id;
    if (sensorId === "") {
      this.setState({ errMsg: "Missing sensor id" });
    } else {
      const { userName, fieldId } = this.props;
      let GeoJSON = {
        type: "Feature",
        geometry: {
          type: "Point",
          coordinates: [
            parseFloat(this.state.update.lng).toFixed(6),
            parseFloat(this.state.update.lat).toFixed(6),
          ],
        },
        properties: {},
      };
      var action = this.state.loaded ? `Updating ${sensorId}` : "Inserting new";
      var func = this.state.loaded ? updateSensorData : insertNewSensor;
      var config = this.state.loaded
        ? {
            sensorId: sensorId,
            fieldId: fieldId,
            username: userName,
            values: {
              geom: JSON.stringify(GeoJSON),
              sleeping: this.state.update.sleeping,
              alias: this.state.update.ali,
              field_id: this.state.update.field_id,
              battery_threshold: this.state.update.battery_threshold,
            },
          }
        : {
            sensorId: sensorId,
            fieldId: fieldId,
            username: userName,
            geom: JSON.stringify(GeoJSON),
            sleeping: this.state.update.sleeping,
            alias: this.state.update.ali,
            battery_threshold: this.state.update.battery_threshold,
          };
      func(config)
        .then((res) => {
          toast.success(
            <div>
              <span className="glyphicon glyphicon-info-sign ml-2 mr-2" />
              {action} sensor successed
            </div>,
            {
              position: "top-right",
              autoClose: 5000,
              closeOnClick: false,
              pauseOnHover: false,
              draggable: true,
            }
          );
          this.props.tableReload();
          if (this.state.loaded) {
            this.props.toggle();
          } else {
            let sensors = this.state.sensors;
            sensors.push({
              sensorId,
              latlng: GeoJSON.geometry.coordinates.map((i) => +i),
              alias: this.state.update.ali,
            });
            this.setState({ step: 2, sensors });
          }
        })
        .catch((err) => {
          toast.error(
            <div>
              <span className="glyphicon glyphicon-info-sign ml-2 mr-2" />
              {action} sensor failed <br />
              {err.code === PostgresError.UNIQUE_VIOLATION &&
                "Sensor has been installed in another field"}
            </div>,
            {
              position: "top-right",
              autoClose: 5000,
              closeOnClick: false,
              pauseOnHover: false,
              draggable: true,
            }
          );
        });
    }
  };

  render() {
    let { marker } = this.state;
    const { isOpen, toggle } = this.props;
    const { sensors, polyline, showSensors, sensor, loaded } = this.state;

    return (
      <Modal isOpen={isOpen} toggle={toggle}>
        <ModalHeader>
          {sensor ? "View and Edit Sensor" : "Sensor Registration"}
        </ModalHeader>
        <ModalBody className="text-center">
          {this.state.step === 1 && (
            <>
              <Row
                className={s.instructions}
                style={{ height: "100%", width: "100%" }}
              >
                <Col sm={12} md={{ size: 10, offset: 1 }}>
                  <table>
                    <tbody>
                      <tr>
                        <td>
                          <Label htmlFor="sensorID">Sensor Id</Label>
                        </td>
                        <td>
                          {/* TODO: might want to check value of gateway ID */}
                          <Input
                            type="text"
                            value={sensor.sensor_id}
                            disabled={loaded}
                            placeholder="Enter sensorId"
                            name="sensorID" //this.setState({ marker: null });
                            required
                            id="sensorID"
                            onChange={(e) => {
                              e.preventDefault();
                              let sensor_id = e.target.value;

                              this.setState(({ sensor, ...rest }) => ({
                                ...rest,
                                sensor: {
                                  ...sensor,
                                  sensor_id,
                                },
                              }));
                            }}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={2} style={{ height: "2rem" }}></td>
                      </tr>
                      <tr className="mt-3">
                        <td>
                          <Label htmlFor="ali">Alias</Label>
                        </td>
                        <td>
                          <Input
                            type="text"
                            value={this.state.update["ali"]}
                            name="ali"
                            id="ali"
                            onChange={(e) =>
                              this.updateInput("ali", e.target.value)
                            }
                          />
                        </td>
                      </tr>
                      {loaded && (
                        <tr>
                          <td>
                            <Label htmlFor="lat">Latitude</Label>
                          </td>
                          <td>
                            <Input
                              type="text"
                              value={
                                sensor && sensor.points
                                  ? this.state.update["lat"]
                                  : ""
                              }
                              name="lat"
                              id="lat"
                              onChange={(e) =>
                                this.updateInput("lat", e.target.value)
                              }
                            />
                          </td>
                        </tr>
                      )}
                      {loaded && (
                        <tr>
                          <td>
                            <Label htmlFor="lng">Longitude</Label>
                          </td>
                          <td>
                            <Input
                              type="text"
                              value={
                                sensor && sensor.points
                                  ? this.state.update["lng"]
                                  : ""
                              }
                              name="lng"
                              id="lng"
                              onChange={(e) =>
                                this.updateInput("lng", e.target.value)
                              }
                            />
                          </td>
                        </tr>
                      )}
                      <tr>
                        <td>
                          <Label
                            htmlFor="sleeping"
                            style={{ textWrap: "nowrap" }}
                          >
                            Sleeping Time &#40;hr&#41;
                          </Label>
                        </td>
                        <td>
                          <Input
                            disabled
                            type="select"
                            value={this.state.update["sleeping"]}
                            name="sleeping"
                            id="sleeping"
                            onChange={(e) =>
                              this.updateInput("sleeping", e.target.value)
                            }
                          >
                            <option value={30}>30 &#40;sec&#41;</option>
                            <option value={60}>1 &#40;min&#41;</option>
                            <option value={120}>2 &#40;min&#41;</option>
                            {[0.5, 1, 2, 3, 4, 5, 6, 8, 10, 12].map((v, i) => (
                              <option value={v * 3600} key={i}>
                                {v} &#40;hr&#41;
                              </option>
                            ))}
                          </Input>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <Label
                            htmlFor="batteryThreshold"
                            style={{ textWrap: "nowrap" }}
                          >
                            Threshold of Low Battery (%)
                          </Label>
                        </td>
                        <td>
                          <Input
                            type="number"
                            min={0}
                            max={100}
                            // step={1}
                            name="batteryThreshold"
                            id="batteryThreshold"
                            value={this.state.update["battery_threshold"]}
                            onChange={(e) =>
                              this.updateInput(
                                "battery_threshold",
                                e.target.value
                              )
                            }
                          />
                        </td>
                      </tr>
                      <tr> </tr>
                      {loaded && this.props.fields && (
                        <tr>
                          <td>
                            <Label style={{ textWrap: "nowrap" }}>Field</Label>
                          </td>
                          <td>
                            <ButtonDropdownMenu className="float-right w-75 ">
                              <DropdownToggle color="light" caret>
                                <span className="glyphicon glyphicon-th-large mr-2 mt-1"></span>
                                {this.state.choosenField.field_name}
                              </DropdownToggle>
                              <DropdownMenu>
                                {Object.entries(this.props.fields).map(
                                  ([farm, fields], i) => (
                                    <div key={`farm-${i}`}>
                                      <DropdownItem
                                        className={"font-weight-bold"}
                                      >
                                        {farm.toUpperCase()}
                                      </DropdownItem>
                                      {fields.map((field, i) => (
                                        <DropdownItem
                                          onClick={() => {
                                            this.updateInput(
                                              "field_id",
                                              field.field_id
                                            );
                                            this.updateField(field);
                                          }}
                                          key={"field" + i}
                                        >
                                          {field.field_name}
                                        </DropdownItem>
                                      ))}
                                      {i <
                                        Object.keys(this.props.fields).length -
                                          1 && <DropdownItem divider />}
                                    </div>
                                  )
                                )}
                              </DropdownMenu>
                            </ButtonDropdownMenu>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </Col>
              </Row>
              <Row className={s.instructions} style={{ height: "450px" }}>
                <Col sm={12} md={{ size: 10, offset: 1 }}>
                  <div>
                    <Alert color="primary">
                      • Please place a marker using{" "}
                      <img src={MapPin} alt="" width="30" height="30" /> icon to
                      locate your sensor on the map.
                    </Alert>
                    <Button
                      onClick={this.showSensors}
                      color="info"
                      className="float-left"
                      outline
                      active={showSensors}
                    >
                      {showSensors
                        ? "Hide Installed Sensors"
                        : "Show Installed Sensors"}
                    </Button>
                    <h6 className={s.handDown}>
                      Click <span className="glyphicon glyphicon-hand-down" />
                    </h6>
                    <SensorMap
                      googleMapURL={googleMapAPI}
                      loadingElement={
                        <div style={{ height: "100%", width: "100%" }} />
                      }
                      containerElement={
                        <div style={{ height: "300px", width: "100%" }} />
                      }
                      mapElement={
                        <div style={{ height: "100%", width: "100%" }} />
                      }
                      drawComplete={this.drawComplete}
                      polyline={polyline}
                      sensors={showSensors ? sensors : []}
                      marker={marker}
                    />
                  </div>
                </Col>
              </Row>
            </>
          )}
          {this.state.step === 2 && (
            <>
              <Row className={s.instructions} style={{ margin: "auto 0" }}>
                <div className="text-center w-100">
                  Now you can go to your field and plug this sensor into the
                  field Or Install Another Sensor
                </div>
              </Row>
            </>
          )}
        </ModalBody>
        <ModalFooter>
          <Button
            onClick={toggle}
            color="danger"
            className="floar-right"
            outline
          >
            Close
          </Button>
          {this.state.step === 1 && (
            <Button
              onClick={this.onClickSubmit} //{ this.onClickSubmit }
              color="primary"
              className="floar-right"
              outline
              disabled={
                !this.state.update.lat ||
                !this.state.update.lng ||
                !sensor.sensor_id
              }
            >
              {loaded ? "Update" : "Install"}
            </Button>
          )}
          {this.state.step === 2 && (
            <Button
              className="floar-right"
              color="primary"
              outline
              onClick={() => {
                this.setState({
                  update: { ali: "", sleeping: 7200 },
                  sensor: { sensor_id: "" },
                  step: 1,
                  marker: null,
                });
                this.props.tableReload();
              }}
            >
              Install Another Sensor
            </Button>
          )}
        </ModalFooter>
      </Modal>
    );
  }
}

SensorRegistrationModal.propTypes = {
  fieldId: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  userName: PropTypes.string.isRequired,
  tableReload: PropTypes.func.isRequired,
  sensor: PropTypes.string,
};

function mapStateToProps(state) {
  return {
    fields: state.field.farmsWithFields,
  };
}

export default connect(mapStateToProps)(SensorRegistrationModal);
