import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import {
  FieldMoistureData,
  MoistureDataContent,
  fetchMoistureDataByField,
} from "src/actions/v2/moisture";
import { useDashboardHook } from "./useDashboardHooks";
import Axios from "axios";
import { PredictionData } from "src/actions/v2/prediction";
import { Zone, ZoneSoilProfile, fetchZone } from "src/actions/v2/zone";
import { EMPTY_SENSOR_DATA } from "src/actions/v2/sensor";

export type SensorData = {
  moisture?: SensorMoistureData[];
  prediction?: PredictionData[];
  zoneProfile?: ZoneSoilProfile;
};

export type SensorMoistureData = {
  time: number;
  temperature: number;
  cap50_percent: number;
  cap100_percent: number;
  cap150_percent: number;
};

type MoistureDataContextType = {
  data: FieldMoistureData;
  zones: Zone[]
  isFetching: boolean;
  /**
   * Get Sensor Data by providing the entry name.
   *
   * For example, getting battery data of the sensor by passing 'battery_vol' as a key
   */
  getSensorData: <K extends keyof MoistureDataContent>(
    sensorId: string,
    ...keys: K[]
  ) => MoistureDataContent[] | Pick<MoistureDataContent, "time" | K>[] | null;

  /**
   * Get prepared moisture data for drawing the moisture table
   */
  getMoistureData: (sensor_id: string) => SensorData;

  getOneDataBySensor: (sensor_id: string) => MoistureDataContent | null;
};

const MoistureDataContext = createContext<MoistureDataContextType>({
  data: {},
  zones: [],
  isFetching: false,
  getSensorData: () => [],
  getMoistureData: () => ({}),
  getOneDataBySensor: () => null
});

export const FieldMoistureDataContextProvider = ({
  children,
}: {
  children?: React.ReactNode;
}) => {
  const [data, setData] = useState<FieldMoistureData>({});
  const [zones, setZone] = useState<Zone[]>([]);
  const { chosenField: field, inDetailPage } = useDashboardHook();
  const [isFetching, setFetching] = useState(false);

  useEffect(() => {
    if (!inDetailPage) return;

    if (!field.field_id) return;

    const source = Axios.CancelToken.source();

    setFetching(true);
    fetchMoistureDataByField(field.field_id, {
      cancelToken: source.token,
    })
      .then((data) => {
        setData(data);
      })
      .catch((err) => console.log(err))
      .finally(() => setFetching(false));

    return () => source.cancel();
  }, [field.field_id, inDetailPage]);

  useEffect(() => {
    if (!inDetailPage) return;

    if (!field.field_id) return;

    const source = Axios.CancelToken.source();

    setFetching(true);
    fetchZone({
      cancelToken: source.token,
      params: {
        withSensor: true,
        field: field.field_id,
      },
    })
      .then(setZone)
      .catch((err) => console.log(err))
      .finally(() => setFetching(false));

    return () => source.cancel();
  }, [field.field_id, inDetailPage]);

  const getSensorData = useCallback(
    <K extends keyof MoistureDataContent>(
      sensorId: string,
      ...keys: K[]
    ):
      | MoistureDataContent[]
      | Pick<MoistureDataContent, "time" | K>[]
      | null => {
      if (!data) return null
      // if no data return empty list
      if (!data[sensorId]) return [];

      // pick the right attributes
      const result = data[sensorId].moisture.map((d) => {
        const partial: Pick<MoistureDataContent, "time" | K> = {
          time: d.time,
        } as Pick<MoistureDataContent, "time" | K>;

        keys.forEach((k) => (partial[k] = d[k]));
        return partial;
      });

      // return the list sorted by ascend time
      return result.sort(
        (a, b) => new Date(a.time).getTime() - new Date(b.time).getTime()
      );
    },
    [data]
  );

  const getPrediction = useCallback(
    (sensorId: string) => {
      if (!data[sensorId]) return []
      return data[sensorId].prediction;
    },
    [data]
  );

  const getWPoints = useCallback(
    (sensorId: string) => {
      return zones?.filter((zone) =>
        zone.sensors?.includes(sensorId)
      )[0] as ZoneSoilProfile;
    },
    [zones]
  );

  const prepareMoistureData = useCallback(
    (sensor_id: string) => {
      // if (fetchingSensor[sensor_id]) return;
      let sensordata: SensorData = {};
      let moisture = getSensorData(
        sensor_id,
        "cap50_percent",
        "cap100_percent",
        "cap150_percent",
        "temperature"
      );

      // fetching prediction data and zone profile only if moisture is present
      if (moisture) {
        sensordata.moisture = moisture.map((d) => ({
          ...d,
          time: new Date(d.time).getTime(),
        }));

        sensordata.prediction = getPrediction(sensor_id);
        sensordata.zoneProfile = getWPoints(sensor_id);
        return sensordata
      }
      return {} as SensorData
    },
    [getPrediction, getSensorData, getWPoints]
  );

  const getMoistureData: (sensor_id: string) => SensorData = useCallback(
    (sensor_id: string) => {
      if (sensor_id === EMPTY_SENSOR_DATA.sensor_id) return {};
      return prepareMoistureData(sensor_id)
    },
    [prepareMoistureData]
  );

  const getOneDataBySensor: (sensor_id: string) => MoistureDataContent | null = useCallback((sensor_id: string) => {
    if (!data[sensor_id] || !data[sensor_id].moisture) return null

    return data[sensor_id].moisture.sort(
      (a, b) => new Date(b.time).getTime() - new Date(a.time).getTime()
    )[0]
  }, [data])

  return (
    <MoistureDataContext.Provider
      value={{ data, getSensorData, isFetching, getMoistureData, zones, getOneDataBySensor }}
    >
      {children}
    </MoistureDataContext.Provider>
  );
};

export const useFieldMoistureDataContext = () =>
  useContext(MoistureDataContext);
