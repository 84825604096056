import React, { useEffect, useRef } from "react";
import {
  withGoogleMap,
  withScriptjs,
  GoogleMap,
  Polygon,
  Polyline,
  Marker,
  InfoWindow,
} from "react-google-maps";
import { compose, lifecycle } from "recompose";
import DrawingManager from "react-google-maps/lib/components/drawing/DrawingManager";
import MapControl from "../../../../components/map-control";

/* eslint-disable */
const autoZoom = (map, points) => {
  if (map && points) {
    let bounds = new google.maps.LatLngBounds();
    for (let point of points) {
      bounds.extend(point);
    }
    map.fitBounds(bounds, 0);
  }
};

const getCenter = (polygon) => {
  const bounds = new google.maps.LatLngBounds();
  polygon.forEach((coord) => bounds.extend(coord));
  return bounds.getCenter();
};

const MapWithADrawingManager = compose(
  withScriptjs,
  withGoogleMap,
  lifecycle({
    componentDidMount() {
      const refs = {};
      this.setState({
        center: {
          lat: 0,
          lng: 0,
        },
        onPlacesChanged: (place) => {
          let latValue = place.geometry.location.lat();
          let lngValue = place.geometry.location.lng();
          this.setState({ center: { lat: latValue, lng: lngValue } });
        },
      });
    },
  })
)((props) => {
  const mapRef = useRef(null);

  useEffect(() => {
    if (mapRef.current) {
      autoZoom(
        mapRef.current,
        props.chosenZonePolygon?.points || props.fieldPolygon
      );
    }
  }, []);

  return (
    <GoogleMap
      ref={mapRef}
      mapTypeId={"hybrid"}
      center={props.fieldPolygon ? props.fieldPolygon[0] : { lat: 0, lng: 0 }}
      tilt={0}
    >
      <MapControl position={google.maps.ControlPosition.LEFT_TOP}>
        <div className="mt-1 ml-2">
          <button
            className="btn btn-light rounded-sm"
            onClick={() => {
              autoZoom(mapRef.current, props.fieldPolygon);
            }}
          >Move to Field</button>
        </div>
      </MapControl>
      <DrawingManager
        onPolygonComplete={props.drawComplete}
        defaultOptions={{
          drawingControl: Object.keys(props.polygon).length == 0,
          drawingMode: google.maps.drawing.OverlayType.POLYGON,
          drawingControlOptions: {
            position: google.maps.ControlPosition.TOP_RIGHT,
            drawingModes: [google.maps.drawing.OverlayType.POLYGON],
          },
          polygonOptions: {
            strokeColor: "#bd377a",
          },
        }}
      />
      {/* Field Polygon */}
      <Polyline
        options={{ strokeColor: "#369BF7" }}
        path={props.fieldPolygon}
      />
      {/* Existing Zones */}
      {Object.values(props.zoneArr).length > 0 &&
        Object.entries(props.zoneArr).map(([zonename, points], index) => (
          <React.Fragment key={index}>
            <Polyline options={{ strokeColor: "#bd377a" }} path={points} />
            <InfoWindow
              position={getCenter(points)}
              options={{ disableAutoPan: true }}
            >
              <span>{zonename}</span>
            </InfoWindow>
          </React.Fragment>
        ))}
      {/* Chosen Zone Polygon */}
      {props.chosenZonePolygon && (
        <>
          <Polyline
            options={{ strokeColor: "#CAD2C5" }}
            path={props.chosenZonePolygon.points}
          />

          <InfoWindow
            position={getCenter(props.chosenZonePolygon.points)}
            options={{ disableAutoPan: true }}
          >
            <span>{props.chosenZonePolygon.zonename}</span>
          </InfoWindow>
        </>
      )}
      {/* Sensors if any */}
      {props.sensorArr.length > 0 &&
        props.sensorArr.map((sensor, index) => (
          <div key={index}>
            <Marker
              key={index}
              icon={{
                url: "https://maps.google.com/mapfiles/ms/icons/blue-pushpin.png",
              }}
              position={{ lat: sensor.lat, lng: sensor.lng }}
            >
              <InfoWindow
                position={{ lat: sensor.lat, lng: sensor.lng }}
                options={{ disableAutoPan: true }}
              >
                <span>{sensor.sensorID}</span>
              </InfoWindow>
            </Marker>
          </div>
        ))}
      {Object.keys(props.polygon).length > 0 && (
        <Polygon
          options={{ strokeColor: "#bd377a" }}
          path={props.polygon.getPath()}
        />
      )}
    </GoogleMap>
  );
});

export default MapWithADrawingManager;
