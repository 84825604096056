import React from "react";
import {
  withGoogleMap,
  withScriptjs,
  GoogleMap,
  Polyline,
  Marker,
  InfoWindow,
} from "react-google-maps";
import { compose, lifecycle } from "recompose";
import DrawingManager from "react-google-maps/lib/components/drawing/DrawingManager";

const autoZoom = (map, points) => {
  if (map && points) {
    let bounds = new window.google.maps.LatLngBounds();
    for (let point of points) {
      bounds.extend(point);
    }
    map.fitBounds(bounds, 0);
  }
};

const MapWithADrawingManager = compose(
  withScriptjs,
  withGoogleMap,
  lifecycle({
    componentDidMount() {
      this.setState({
        center: {
          lat: 0,
          lng: 0,
        },
        onPlacesChanged: (place) => {
          let lat = place.geometry.location.lat();
          let lng = place.geometry.location.lng();
          this.setState({ center: { lat, lng } });
        },
      });
    },
  })
)((props) => (
  <GoogleMap
    ref={(ref) => {
      autoZoom(ref, props.polyline);
    }}
    mapTypeId={"hybrid"}
    center={props.polyline ? props.polyline[0] : { lat: 0, lng: 0 }}
    tilt={0}
    options={{ disableDefaultUI: true }}
  >
    <DrawingManager
      onMarkerComplete={props.drawComplete}
      defaultOptions={{
        drawingControl: !props.marker,
        drawingControlOptions: {
          position: window.google.maps.ControlPosition.TOP_RIGHT,
          drawingModes: [window.google.maps.drawing.OverlayType.MARKER],
        },
      }}
    />
    {props.marker && <Marker position={props.marker.getPosition()} />}
    <Polyline options={{ strokeColor: "#369BF7" }} path={props.polyline} />
    {props.sensors &&
      props.sensors.map((sensor, index) => (
        <Marker
          key={index}
          icon={{
            url: "https://maps.google.com/mapfiles/ms/icons/blue-pushpin.png",
          }}
          position={{ lng: sensor.latlng[0], lat: sensor.latlng[1] }}
        >
          <InfoWindow
            position={{ lng: sensor.latlng[0], lat: sensor.latlng[1] }}
          >
            <span>{sensor.sensorId}</span>
          </InfoWindow>
        </Marker>
      ))}
  </GoogleMap>
));

export default MapWithADrawingManager;
