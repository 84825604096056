import React, { PureComponent } from "react";
import { connect } from "react-redux";

import s from "./Subscription.module.scss";
import { Row, Col, FormGroup, Label, Input, Button, Container } from "reactstrap";
import Widget from "../../components/Widget";
import Checkout from "../checkout";
import { Carousel } from "3d-react-carousal";

class Subscription extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      selectedOption: 1,
      displayStepPage: 0,
    };
  }

  onChangeOption = (index) => {
    if (index !== this.state.selectedOption) {
      this.setState({ selectedOption: index });
      console.log("onChangeOption", index);
    }
  };
  onCheckout = (price) => {
    console.log("onCheckout", parseInt(price));
    this.setPage(1);
  };
  setPage = (pageId) => {
    this.setState({ displayStepPage: pageId });
  };

  render() {
    const price = ["199.00", "399.00", "599.00"];
    const slides = [
      <img src="/webapp.jpeg" alt="1" />,
      <img src="/dashboard.jpeg" alt="2" />,
      <img src="/moistureTem.png" alt="3" />,
    ];
    const callback = function (index) {
      console.log("callback", index);
    };
    return (
      <div>
        {this.state.displayStepPage === 0 && (
          <div className={s.root}>
            <Container fluid className="bg-white ">
              <Row>
                <Col>
                  <Widget title={<h1 className="mb-lg">Subscription</h1>} />
                </Col>
              </Row>
              <Row>
                <Col className={s.margin} xs="8">
                  <Carousel slides={slides} autoplay={true} interval={5000} onSlideChange={callback} />
                </Col>
                <Col className={s.margin} xs="4">
                  <h1>A${price[this.state.selectedOption]}</h1>
                  <hr />
                  <div>
                    Annual "lite" subscription for data visualisation and data download from AquaTerra's webapp, per
                    sensor ('"full" subscription option for up to 5 sensors, and *pro* subscription option that includes
                    data access via API available as well). (price incl. GST 10%)
                  </div>
                  <hr />
                  <FormGroup tag="fieldset">
                    <legend>No. of sensors</legend>
                    <FormGroup check>
                      <Label check>
                        <Input type="radio" name="radio1" onClick={() => this.onChangeOption(0)} /> Lite subscription
                        (best for single sensors)
                      </Label>
                    </FormGroup>
                    <FormGroup check>
                      <Label check>
                        <Input type="radio" name="radio1" defaultChecked onClick={() => this.onChangeOption(1)} /> Full
                        subscription (for up to 5 sensors)
                      </Label>
                    </FormGroup>
                    <FormGroup check disabled>
                      <Label check>
                        <Input type="radio" name="radio1" onClick={() => this.onChangeOption(2)} disabled /> Pro
                        subscription (integrates to your management system via API)
                      </Label>
                    </FormGroup>
                  </FormGroup>
                  {/* <NavLink to="/app/checkout" className={s.NavLink}> */}
                  <Button
                    onClick={() => this.onCheckout(price[this.state.selectedOption])}
                    color="primary"
                    className="mt-2"
                  >
                    Checkout
                  </Button>
                  {/* </NavLink> */}
                </Col>
              </Row>
            </Container>
          </div>
        )}
        {this.state.displayStepPage === 1 && (
          <Checkout displayStepPage={this.setPage} plan={this.state.selectedOption} />
        )}
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {};
}

export default connect(mapStateToProps)(Subscription);
