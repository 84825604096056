import React, { useEffect, useRef } from "react";
import Autocomplete from "react-google-autocomplete";
import {
  withGoogleMap,
  withScriptjs,
  GoogleMap,
  Polygon,
  Marker,
  InfoWindow,
} from "react-google-maps";
import { compose, lifecycle } from "recompose";
import DrawingManager from "react-google-maps/lib/components/drawing/DrawingManager";

/* eslint-disable */
const MapWithADrawingManager = compose(
  withScriptjs,
  withGoogleMap,
  lifecycle({
    componentDidMount() {
      const refs = {};
      this.setState({
        center: {
          lat: 0,
          lng: 0,
        },
        onPlacesChanged: (place) => {
          if (!place.geometry) return;
          let latValue = place.geometry.location.lat();
          let lngValue = place.geometry.location.lng();
          this.setState({ center: { lat: latValue, lng: lngValue } });
        },
      });
    },
  })
)((props) => {
  const autoZoom = (map, points) => {
    if (!map) return;

    if (points?.length > 0) {
      let bounds = new google.maps.LatLngBounds();
      for (let point of points) {
        bounds.extend(point);
      }
      map.fitBounds(bounds);
      return;
    }

    if (props.points || props.polygon) {
      let polygon = props.points || props.polygon;
      let bounds = new google.maps.LatLngBounds();
      polygon.forEach((p) => bounds.extend(p));
      map.fitBounds(bounds);
      return;
    }

    if (props.fields && props.fields.length > 0) {
      let bounds = new google.maps.LatLngBounds();
      props.fields.forEach(({ points }) => {
        points.forEach((point) => {
          bounds.extend(point);
        });
      });
      map.fitBounds(bounds);
      return;
    }
  };

  const mapRef = useRef(null);

  useEffect(() => {
    if (mapRef.current) {
      autoZoom(mapRef.current, props.points || props.polygon);
    }
  }, [props.points, props.polygon]);

  const getCenter = (polygon) => {
    const bounds = new google.maps.LatLngBounds();
    polygon.forEach((coord) => bounds.extend(coord));
    return bounds.getCenter();
  };

  return (
    <GoogleMap
      defaultZoom={12}
      mapTypeId={"hybrid"}
      center={
        props.center?.lat === 0
          ? props.polygon?.length > 0
            ? getCenter(props.polygon).toJSON()
            : props.CurrentLocation
          : props.center
      }
      ref={mapRef}
    >
      <Autocomplete
        style={{
          width: "30%",
          position: "absolute",
          top: "230px",
          left: "40%",
        }}
        onPlaceSelected={props.onPlacesChanged}
        types={["address"]}
      />
      <DrawingManager
        onPolygonComplete={props.drawComplete}
        defaultOptions={{
          drawingControl: true,
          drawingControlOptions: {
            position: google.maps.ControlPosition.TOP_RIGHT,
            drawingModes: [google.maps.drawing.OverlayType.POLYGON],
          },
          polygonOptions: {
            strokeColor: "#bd374b",
          },
        }}
      />
      {props.polygon && (
        <Polygon options={{ strokeColor: "#bd374b" }} path={props.polygon} />
      )}
      {props.points && (
        <Polygon options={{ strokeColor: "#F9B4ED" }} path={props.points} />
      )}
      {props.fields &&
        props.fields.map(({ field_name, points }, i) => (
          <React.Fragment key={i}>
            <Marker
              icon={{
                url: "https://maps.google.com/mapfiles/ms/icons/blue-pushpin.png",
              }}
              onClick={(marker) => {
                autoZoom(mapRef.current, points);
              }}
              position={getCenter(points)}
            >
              <InfoWindow
                position={getCenter(points)}
                options={{ disableAutoPan: true }}
              >
                <span>{field_name}</span>
              </InfoWindow>
            </Marker>
            <Polygon options={{ strokeColor: "#CA6680" }} path={points} />
          </React.Fragment>
        ))}
    </GoogleMap>
  );
});

export default MapWithADrawingManager;
