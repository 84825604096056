import React, { useMemo } from "react";
import { Spinner } from "reactstrap";
import Widget from "src/components/Widget";
import SensorSelector from "../selectors/SensorSelectorV2";
import s from "../../Dashboard.module.scss";
import {
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { formatDate } from "../../util";
import { useFieldMoistureDataContext } from "../../hooks/useFieldMoistureDataContext";
import { IComponentType } from "src/types";
import { withRouter } from "react-router";

const BatteryLevelChart: IComponentType = ({ location }) => {
  const sensor = location.state?.sensor;
  const { getSensorData, isFetching } =
    useFieldMoistureDataContext();
  const data = useMemo(() => {
    if (!sensor) return null;
    return getSensorData(sensor.sensor_id, "battery_vol");
  }, [sensor, getSensorData]);

  if (isFetching) return <Spinner className="mx-auto d-block" />;

  return (
    <Widget className={s.stretch} title={<h4>Battery Level</h4>}>
      {/* Sensor Selector */}
      <SensorSelector />

      <ResponsiveContainer height={400} width={"100%"}>
        {isFetching || !data ? (
          <div className="d-flex flex-column h-100 justify-content-center ">
            <Spinner className="mx-auto d-block" />
          </div>
        ) : data.length === 0 ? (
          <div className="d-flex flex-column h-100 justify-content-center ">
            <div className="text-center h3 mt-4 d-block">No Battery Data</div>
          </div>
        ) : (
          <LineChart
            data={data}
            margin={{ top: 20, right: 20, left: -10, bottom: 0 }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            {/* x-axis */}
            <XAxis
              dataKey={"time"}
              height={80}
              interval={Math.trunc(data.length / 8)}
              tickFormatter={formatDate}
            />
            {/* left y axis */}
            <YAxis width={80} yAxisId={"left"} unit={"V"} />
            <YAxis yAxisId="right" orientation="right" />
            <Tooltip
              labelFormatter={(label) =>
                new Date(label).toString().substring(0, 25)
              }
              formatter={(value) => `${value} V`}
            />
            <Line
              dataKey="battery_vol"
              yAxisId="left"
              type="monotone"
              strokeWidth={2}
              dot={false}
              name="Voltage"
            />
          </LineChart>
        )}
      </ResponsiveContainer>
    </Widget>
  );
};

export default withRouter(BatteryLevelChart);
