import { AxiosRequestConfig } from "axios";
import axiosInstance from "../axios/global";
import { Dispatch } from "redux";
import {
  FETCH_FIELD_FAILURE,
  FETCH_FIELD_REQUEST,
  FETCH_FIELD_SUCCESS,
  FieldActionType,
} from "src/reducers/field";
import { groupBy } from "src/utilities";
import { ThunkAction } from "redux-thunk";
import { RootState } from "src/store";

const reqConfig: AxiosRequestConfig = {
  headers: {
    "Content-Type": "application/json",
  },
  withCredentials: true,
};

export type Field = {
  field_name?: string;
  crop_type?: string | null;
  soil_type?: string | null;
  elevation?: number | null;
  farm_name?: string;
  username?: string;
  geom?: string;
  points: string;
  field_id?: string;
  created?: Date;
  updated?: Date;
  coordinates: google.maps.LatLngLiteral[];
};

export const EMPTY_FIELD_DATA: Partial<Field> = {
  field_id: "0",
  field_name: "No Fields",
  farm_name: "No Farms",
};

export const fetchFields =
  (): ThunkAction<void, RootState, unknown, FieldActionType> =>
  async (dispatch: Dispatch, getState) => {
    if (getState().field.isFetching) return;
    dispatch({ type: FETCH_FIELD_REQUEST });
    try {
      const res = await axiosInstance.get<{ data: Partial<Field>[] }>(
        "/api/v2/field",
        reqConfig
      );
      let fields = res.data.data;
      fields = fields.map((field) => {
        const path = JSON.parse(field.points!).coordinates as number[][][];
        const coordinates: google.maps.LatLngLiteral[] = path[0].map(
          (point) => ({ lat: point[1], lng: point[0] })
        );
        field.coordinates = coordinates;
        return field;
      });
      dispatch({
        type: FETCH_FIELD_SUCCESS,
        fields: groupBy<Partial<Field>>(fields, (f) => f.farm_name!),
      });
    } catch (err) {
      dispatch({ type: FETCH_FIELD_FAILURE, message: err });
    }
  };
