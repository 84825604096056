/**
 * update pairing state of gateways
 * @param params state, gatewayIds
 * @param signal {AbortController.signal}
 * @returns {Promise<any>}
 */
export async function updateGatewayStatus(params, signal) {
  const config = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    credentials: "include",
    body: JSON.stringify({ status: params.status, gatewayIds: params.gatewayIds }),
  };

  if (!!signal) config.signal = signal;
  return fetch("/api/gateway/setup", config)
    .then((res) => res.json())
    .then((resJson) => {
      if (resJson.error) {
        return Promise.reject(resJson.error);
      }
      return Promise.resolve(resJson.data);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
}

/**
 * Fetch paired sensors from the gateway
 * @param params
 * @param signal {AbortSignal}
 * @returns {Promise<any>}
 */
export async function checkSensors(params, signal) {
  const config = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    credentials: "include",
    body: JSON.stringify({ gatewayIds: params.gatewayIds, userName: params.userName }),
  };
  if (signal) config.signal = signal;
  return fetch("/api/gateway/sensors", config)
    .then((res) => res.json())
    .then((resJson) => {
      if (resJson.error) {
        return Promise.reject(resJson.error);
      }
      return Promise.resolve(resJson.data);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
}

// using signal to control the action (mainly abort)
export async function searchGateway(params, signal) {
  const config = {
    method: "POST",
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
    credentials: "include",
    body: `fieldId=${params.fieldId}&username=${params.username}`,
  };

  if (!!signal) config.signal = signal;
  return fetch("/api/gateway/field", config)
    .then((res) => res.json())
    .then((resJson) => {
      if (resJson.error) {
        return Promise.reject(resJson.error);
      }
      return Promise.resolve(resJson.data);
    })
    .catch((err) => Promise.reject(err));
}

export function fetchGateway(params) {
  const config = {
    method: "POST",
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
    credentials: "include",
    body: `userName=${params.userName}`,
  };

  return (dispatch) => {
    return fetch("/api/gateway/", config)
      .then((res) =>
        res.json().then((responseJson) => ({
          data: responseJson.data,
          error: responseJson.err,
        }))
      )
      .then((data) => {
        if (data.error) {
          return Promise.reject(data.error);
        }
        let gateways = [];
        data.data.map((gateway) => gateways.push(gateway.gateway_id));
        return Promise.resolve(gateways);
      })
      .catch((err) => {
        console.log(err);
      });
  };
}

export function deleteGateway(params) {
  const config = {
    method: "POST",
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
    credentials: "include",
    body: `userName=${params.userName}&gatewayId=${params.gatewayId}`,
  };
  return (dispatch) => {
    return fetch("/api/gateway/delete", config)
      .then((res) =>
        res.json().then((responseJson) => ({
          data: responseJson.data,
          error: responseJson.error,
        }))
      )
      .then((data) => {
        if (data.error) {
          return Promise.reject(data.error);
        }
        return Promise.resolve();
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  };
}

export function insertGateway(params) {
  let keys = Object.keys(params);
  const config = {
    method: "POST",
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
    credentials: "include",
    body: keys.map((k) => `${k}=${params[k]}`).join("&"),
  };

  //console.log(config)
  return (dispatch) => {
    return fetch("/api/gateway/new", config)
      .then(
        (res) => res.json()
        // .then(responseJson => (
        //     {
        //         gateway: responseJson.data,
        //         error: responseJson.err
        //     })
        // )
      )
      .then((data) => {
        if (data.error || data.err) {
          return Promise.reject(data.error || data.err);
        }
        return Promise.resolve(data.gateway);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  };
}
