import { Slider, colors } from "@mui/material";
import React from "react";
import { Colorscale } from "react-colorscales";
import {
  ButtonGroup,
  Button,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import ButtonDropdownMenu from "src/components/dropdown-menu";
import MapControl from "src/components/map-control";
import { ShowingControlType } from "./DetailedMap";
import cx from "classnames";
import s from "./DisplayMap.module.scss";
import { Moisture_Colors } from "../../constants";

export const COLOR_SCALE_TITLE: Record<ShowingControlType, string> = {
  info: "",
  battery_vol: "Battery Voltage",
  temperature: "Soil Temperature",
  cap50_percent: "Soil Moisture -  10cm",
  cap100_percent: "Soil Moisture - 100cm",
  cap150_percent: "Soil Moisture - 150cm",
};

export const COLOR_SCALE: Record<ShowingControlType, string[]> = {
  info: [],
  battery_vol: ["#fafa6e", "#9cdf7c", "#4abd8c", "#00968e"],
  temperature: ["#a59c74", "#8a8678", "#707173", "#575d6d"],
  cap50_percent: Moisture_Colors,
  cap100_percent: Moisture_Colors,
  cap150_percent: Moisture_Colors,
};

export const TopCenterMapControl = ({
  showingDataType,
  setShowingDataType,
}: {
  showingDataType: ShowingControlType;
  setShowingDataType: React.Dispatch<ShowingControlType>;
}) => (
  <>
    <MapControl position={google.maps.ControlPosition.TOP_CENTER}>
      <div
        className="d-flex"
        style={{ transform: "translateX(8%)", marginTop: "10px", gap: "20px" }}
      >
        {/* Showing Type Control */}
        <ButtonGroup style={{ height: "fit-content" }}>
          <Button
            color="info"
            onClick={() => setShowingDataType("info")}
            active={showingDataType === "info"}
          >
            Info
          </Button>
          <Button
            color="info"
            onClick={() => setShowingDataType("battery_vol")}
            active={showingDataType === "battery_vol"}
          >
            Battery
          </Button>
          <Button
            color="info"
            onClick={() => setShowingDataType("temperature")}
            active={showingDataType === "temperature"}
          >
            Temperature
          </Button>

          <ButtonDropdownMenu>
            <DropdownToggle
              color="info"
              caret
              active={showingDataType.startsWith("cap")}
            >
              Moisture
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem
                onClick={() => setShowingDataType("cap50_percent")}
                active={showingDataType === "cap50_percent"}
              >
                10cm Underground
              </DropdownItem>
              <DropdownItem
                onClick={() => setShowingDataType("cap100_percent")}
                active={showingDataType === "cap100_percent"}
              >
                100cm Underground
              </DropdownItem>
              <DropdownItem
                onClick={() => setShowingDataType("cap150_percent")}
                active={showingDataType === "cap150_percent"}
              >
                150cm Underground
              </DropdownItem>
            </DropdownMenu>
          </ButtonDropdownMenu>
        </ButtonGroup>
        {/* Colorscale hinter shown when showing sensor data */}
        <ColorPanel
          className={cx(
            "bg-white px-2 py-1",
            showingDataType === "info" ? "invisible " : ""
          )}
          style={{ width: "200px" }}
          colorTitle={COLOR_SCALE_TITLE[showingDataType]}
          colorScale={COLOR_SCALE[showingDataType]}
        >
          <div className="d-flex justify-content-between">
            {showingDataType.startsWith("cap") ? (
              <>
                <span></span>
                <span>WP</span>
                <span>FC</span>
                <span>SAT</span>
                <span></span>
              </>
            ) : (
              <>
                <span>Low</span>
                <span>High</span>
              </>
            )}
          </div>
        </ColorPanel>
      </div>
    </MapControl>
  </>
);

export const SliderControl = ({
  current,
  setCurrent,
  max,
  placeholder,
}: {
  current: number;
  setCurrent: React.Dispatch<number>;
  placeholder: string;
  max: number;
}) => (
  <MapControl position={google.maps.ControlPosition.BOTTOM_CENTER}>
    <div
      className="mb-2 bg-light py-1 px-3 rounded"
      style={{ width: "15vw", transform: "translateX(-80%)" }}
    >
      <span className=" py-1 px-2 m3-2" style={{ fontSize: "1rem" }}>
        {placeholder}
        {/* {current !== -1 ? data[current] : data[data.length - 1]} */}
      </span>
      <Slider
        size="small"
        sx={{ color: colors.lightBlue[400] }}
        onChange={(_e, val, _i) => {
          setCurrent(val as number);
        }}
        max={max}
        value={current}
      />
    </div>
  </MapControl>
);

export const ColorPanel = ({
  children,
  colorScale,
  colorTitle,
  ...props
}: React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
> & {
  children?: React.ReactNode;
  colorScale: string[];
  colorTitle: string;
}) => (
  <div {...props}>
    <span className={s.heading}>{colorTitle}</span>
    <Colorscale colorscale={colorScale} onClick={() => { }} width={150} />
    {children}
  </div>
);
