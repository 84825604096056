import React from "react";
import { withGoogleMap, withScriptjs, GoogleMap, Marker } from "react-google-maps";

import s from "./Google.module.scss";
require("dotenv").config();
const BasicMap = withScriptjs(
  withGoogleMap(() => (
    <GoogleMap defaultZoom={12} defaultCenter={{ lat: parseFloat(-37.813179), lng: parseFloat(144.950259) }}>
      <Marker position={{ lat: -37.813179, lng: 144.950259 }} />
    </GoogleMap>
  ))
);

class Maps extends React.Component {
  render() {
    return (
      <div>
        <div className={s.MapContainer}>
          <BasicMap
            googleMapURL={process.env.REACT_APP_API_GOOGLE2}
            loadingElement={<div style={{ height: "inherit", width: "inherit" }} />}
            containerElement={<div style={{ height: "inherit" }} />}
            mapElement={<div style={{ height: "inherit" }} />}
          />
        </div>
      </div>
    );
  }
}

export default Maps;
