import React, { Component } from "react";
import PropTypes from "prop-types";
import { Button, Input } from "reactstrap";
import { connect } from "react-redux";
import { formulaEndpoint } from "../../../actions/admin/formula";
import style from "./formula.module.scss";

const keys = ["sensor_id", "formula", "parameter", "mode", "lowest_adt", "h_parameter", "h_formula"];

class FormulaTable extends Component {
  static propType = {
    dispatch: PropTypes.func.isRequired,
  };

  state = {
    formulas: [],
    isLoading: false,
    errMsg: "",
    isUpdating: 0,
    updatingFormula: {
      sensor_id: "",
      formula: "",
      parameter: "",
      mode: "",
      lowest_adt: "",
      h_formula: "",
      h_parameter: "",
    },
  };

  getAllFormula() {
    this.setState({ isLoading: true });
    this.props
      .dispatch(formulaEndpoint({ method: "GET" }))
      .then((res) => {
        this.setState({ formulas: res.data });
        this.setState({ isLoading: false });
      })
      .catch((error) => {
        this.setState({ errMsg: error.message() });
      });
  }

  updateFormula(id, updating) {
    this.setState({ isLoading: true });
    if (updating) {
      if (this.state.isUpdating === id) {
        let body = this.state.updatingFormula;
        if (body["formula_id"]) delete body["formula_id"];
        const config =
          id === -1
            ? { method: "POST", body: body }
            : {
                method: "PUT",
                param: id,
                body: body,
              };
        this.props
          .dispatch(formulaEndpoint(config))
          .then((res) => {
            if (res.data) {
              this.setState({ isUpdating: 0 });
              this.setState({ updatingFormula: {} });
              this.getAllFormula();
            }
          })
          .catch((error) => {
            this.setState({ errMsg: error });
          });
      } else {
        let updatingFormula = this.state.formulas.filter((e) => e.formula_id === id)[0];

        this.setState({ updatingFormula });
        this.setState({ isUpdating: id });
      }
    } else {
      if (id === -1) {
        let formulas = this.state.formulas;
        formulas.shift();
        this.setState({ formulas });
      }
      this.setState({ updatingFormula: {} });
      this.setState({ isUpdating: 0 });
    }
    this.setState({ isLoading: false });
  }

  deleteFormula(id) {
    this.props
      .dispatch(formulaEndpoint({ method: "DELETE", param: id }))
      .then((res) => {
        if (res.data) {
          this.getAllFormula();
        }
      })
      .catch((error) => {
        this.setState({ errMsg: error });
      });
  }

  createFormula() {
    let formulas = this.state.formulas;
    formulas.unshift({
      formula_id: -1,
      formula: "x1*(val^x2)/100",
      mode: "specific",
      sensor_id: "",
      parameter: "",
      lowest_adt: "",
      h_formula: "",
      h_parameter: "",
    });
    this.setState({ formulas });
    this.setState({ isUpdating: -1 });
  }

  componentDidMount() {
    this.getAllFormula();
  }

  changeUpdating(key, e) {
    e.preventDefault();
    let formula = this.state.updatingFormula;
    formula[key] = e.target.value;
    this.setState({ updatingFormula: formula });
  }

  changeCred(key, value) {
    var { cred } = this.state;
    cred[key] = value;
    this.setState({ cred });
  }

  constructor(props) {
    super(props);
    this.updateFormula = this.updateFormula.bind(this);
    this.deleteFormula = this.deleteFormula.bind(this);
    this.createFormula = this.createFormula.bind(this);
  }

  render() {
    const { formulas, isUpdating } = this.state;
    return (
      <table className={style.table}>
        <caption>
          Sensor Formula Table
          {this.state.errMsg !== "" && (
            <span style={{ color: "red" }}>
              <br></br>ERROR: {this.state.errMsg}
            </span>
          )}
        </caption>
        <thead>
          <tr>
            {keys.map((k) => (
              <th key={"title-" + k}>{k}</th>
            ))}
            <th key={"action"}>action</th>
          </tr>
        </thead>
        <tbody>
          {isUpdating !== -1 ? (
            <tr>
              <td colSpan={8}>
                <Button className="mr-3" size="sm" color="success" outline onClick={this.createFormula}>
                  <span className="glyphicon glyphicon-plus mr-1 mt-1"></span>
                </Button>
              </td>
            </tr>
          ) : (
            <></>
          )}
          {Object.values(formulas).map((row, i) => (
            <tr key={"row-" + i}>
              {keys.map((k, j) => (
                <td key={"row-" + i + "-col-" + j}>
                  {isUpdating !== row.formula_id ? (
                    <>
                      {typeof row[k] !== "string" || row[k].length < 30
                        ? row[k]
                        : row[k]
                            .split(",")
                            .map(parseFloat)
                            .map((e) => e + "~")
                            .join(",")}
                    </>
                  ) : (
                    <>
                      <Input
                        onChange={(e) => this.changeUpdating(k, e)}
                        name={"updating-" + k}
                        id={"updating-" + k}
                        type="text"
                        style={{ width: "100%", padding: ".1rem" }}
                        value={this.state.updatingFormula[k] ? this.state.updatingFormula[k] : ""}
                      ></Input>
                    </>
                  )}
                </td>
              ))}
              <td>
                <Button
                  className="mr-3"
                  size="sm"
                  color="success"
                  outline
                  onClick={() => this.updateFormula(row.formula_id, true)}
                >
                  {this.state.isUpdating !== row.formula_id ? (
                    <span className="glyphicon glyphicon-edit mr-1 mt-1"></span>
                  ) : (
                    <span className="glyphicon glyphicon-ok mr-1 mt-1"></span>
                  )}
                </Button>
                {this.state.isUpdating === row.formula_id ? (
                  <Button
                    className="mr-3"
                    size="sm"
                    color="danger"
                    outline
                    onClick={() => this.updateFormula(row.formula_id, false)}
                  >
                    <span className="glyphicon glyphicon-remove mr-1 mt-1"></span>
                  </Button>
                ) : (
                  <Button
                    className="mr-3"
                    size="sm"
                    color="danger"
                    outline
                    onClick={() => this.deleteFormula(row.formula_id)}
                  >
                    <span className="glyphicon glyphicon-trash mr-1 mt-1"></span>
                  </Button>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  }
}

function mapStateToProps() {
  return {};
}

export default connect(mapStateToProps)(FormulaTable);
