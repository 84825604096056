import React, { useEffect, useMemo } from "react";
import { createPortal } from "react-dom";
import { MAP } from "react-google-maps/lib/constants";
import PropTypes from "prop-types";

type MapControlProps = {
  position: google.maps.ControlPosition;
} & React.ComponentPropsWithRef<"div">;

const MapControl: React.FC<MapControlProps> = (
  { children, position },
  context
) => {
  const controlDiv = useMemo(() => document.createElement("div"), []);
  const map = context[MAP];

  useEffect(() => {
    const controls = map.controls[position];
    const index = controls.length;
    controls.push(controlDiv);
    return () => {
      controls.removeAt(index);
    };
  }, [map, controlDiv, position]);
  return createPortal(children, controlDiv);
};

MapControl.contextTypes = {
  [MAP]: PropTypes.object,
};

export default MapControl;
