import React, { createContext, useContext, useState } from "react"

type CSVModalContextType = {
  sensor?: string
  isOpen: boolean
  toggle: (sensorId?: string) => () => void
}

const CSVModalContext = createContext<CSVModalContextType>({
  toggle: () => { return () => { } },
  isOpen: false
})

export const CSVModalContextProvider = ({ children }: { children?: React.ReactNode }) => {
  const [sensor, setSensor] = useState<string>()
  const [isOpen, setOpen] = useState<boolean>(false)

  const toggle = (sensorId?: string) => () => {
    setSensor(sensorId)
    setOpen(prev => !prev)
  }

  return <CSVModalContext.Provider value={{ toggle, sensor, isOpen }}>
    {children}
  </CSVModalContext.Provider>
}

export const useCSVModalContext = () => useContext(CSVModalContext)