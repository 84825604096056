import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import {
  Col,
  Row,
  Alert,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  Spinner,
} from "reactstrap";
import s from "./FormInput.module.scss";
import CreatableSelect from "react-select/creatable";
import Stepper from "react-stepper-horizontal";
import MapWithADrawingManager from "./DrawingManager/DrawingManager";
import { fetchFarm, insertFarm } from "../../../actions/farm";
import { fieldAction } from "../../../actions/field";
import { googleMapAPI } from "../../../config/secret";
import icon from "./polygon icon.png";
import { Link } from "react-router-dom";

class FormInput extends PureComponent {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    userName: PropTypes.string.isRequired,
    modalClose: PropTypes.func.isRequired,
    tableReload: PropTypes.func.isRequired,
    field: PropTypes.object,
  };

  state = {
    steps: [
      {
        title: "Info",
        onClick: (e) => {
          e.preventDefault();
        },
      },
      {
        title: "Plot",
        onClick: (e) => {
          e.preventDefault();
        },
      },
      {
        title: "Submit",
        onClick: (e) => {
          e.preventDefault();
        },
      },
    ],
    currentStep: 0,
    farms: [],
    currentLatLng: {},
    field: {
      polygon: [], // drawing polygon
      fieldName: "",
      farmName: "",
    },
    postLoading: false,
    postStatus: false,
    fields: [],
  };

  constructor() {
    super();
    this.onClickNext = this.onClickNext.bind(this);
    this.onClickClear = this.onClickClear.bind(this);
    this.onClickSubmit = this.onClickSubmit.bind(this);
    this.onClickClose = this.onClickClose.bind(this);
    this.getGeoLocation();
  }

  drawComplete = (drawing) => {
    drawing.setMap(null);
    let polygon = drawing
      .getPath()
      .getArray()
      .map((p) => [p.lng(), p.lat()]);
    let point = polygon[0];
    if (point !== polygon[polygon.length - 1]) polygon.push(point);
    let { field } = this.state;
    field = { ...field, polygon: polygon };
    this.setState({ field });
  };

  getGeoLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.setState({
          currentLatLng: {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          },
        });
      });
    }
  };

  changeField = (event) => {
    let { field } = this.state;
    field.fieldName = event.target.value;
    this.setState(field);
  };

  changeFarm = (event) => {
    let { field } = this.state;
    field.farmName = event ? event.value : "";
    this.setState(field);
  };

  onClickClear() {
    this.setState((prev) => ({
      ...prev,
      field: { ...prev.field, polygon: [] },
    }));
  }

  onClickNext() {
    const { currentStep } = this.state;
    if (currentStep === 0) {
      const { fields, field } = this.state;
      this.setState({
        fields: fields.filter((f) => f.farm_name === field.farmName),
      });
    }
    this.setState({
      currentStep: currentStep + 1,
    });
  }

  onClickClose() {
    this.props.modalClose();
  }

  postField() {
    // fill the geo json object
    let GeoJSON = {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [this.state.field.polygon],
      },
      properties: {},
    };
    fieldAction({
      ...this.state.field,
      userName: this.props.userName,
      geom: JSON.stringify(GeoJSON),
    }).then((data) => {
      if (data) this.setState({ postStatus: true });
      this.setState({ postLoading: false });
      this.props.tableReload();
    });
  }

  onClickSubmit() {
    this.onClickNext();
    this.setState({ postLoading: true });
    // when a new farm is created, insert the farm as well
    let curFarms = this.state.farms.map((farm) => farm.value);
    if (!curFarms.includes(this.state.field.farmName)) {
      insertFarm({
        userName: this.props.userName,
        farmName: this.state.field.farmName,
      }).then((data) => {
        if (data) this.postField();
        this.setState({ postLoading: false });
        this.props.tableReload();
      });
    } else {
      this.postField();
    }
  }

  componentDidMount() {
    fetchFarm({ userName: this.props.userName }).then((res) => {
      this.setState({
        farms: res.map((r) => ({ label: r.farm_name, value: r.farm_name })),
      });
    });
    if (this.props.field) {
      let { field } = this.props;
      let points = JSON.parse(field.points).coordinates[0];

      field = {
        ...field,
        points,
        fieldName: field.field_name,
        farmName: field.farm_name,
      };
      delete field.field_name;
      delete field.farm_name;
      this.setState({ field });
    }

    if (this.props.fields) {
      let { fields } = this.props;
      fields = fields.map((field) => {
        let points = JSON.parse(field.points).coordinates[0];
        return { ...field, points };
      });
      this.setState({ fields });
    }
  }

  render() {
    let { currentStep, field } = this.state;
    let { fieldName: fieldText, farmName: farmText, polygon } = field;
    const reg = /^[a-zA-z]+[a-zA-z0-9\s]+[a-zA-z0-9]/;
    const fieldValid = fieldText.length > 0 ? reg.exec(fieldText) : true;
    const farmValid = farmText.length > 0 ? reg.exec(farmText) : true;

    const nextEnabled =
      (currentStep === 0 &&
        farmValid &&
        fieldValid &&
        fieldText.length > 2 &&
        farmText.length > 2) ||
      (currentStep === 1 && polygon?.length > 0);
    return (
      <div>
        <Row>
          <Col sm={12} md={{ size: 12, offset: 0 }}>
            <Stepper
              size={24}
              circleFontSize={12}
              titleFontSize={12}
              steps={this.state.steps}
              activeStep={currentStep}
            />
          </Col>
        </Row>

        {/*Step 1 name form input*/}
        {currentStep === 0 && (
          <Row>
            <Col sm={12} md={{ size: 10, offset: 1 }}>
              {/*Step 1*/}
              <Form className="mt-3">
                <div className="text-center text-primary h5 my-3">
                  Select an existing "Farm" OR create a new Farm by typing its
                  name.
                </div>
                <FormGroup row>
                  <Label className="text-center" for="examplePassword" sm={3}>
                    Farm Name
                  </Label>
                  <Col sm={9}>
                    <CreatableSelect
                      placeholder={"Select or Create ..."}
                      options={this.state.farms}
                      onChange={this.changeFarm}
                      value={
                        farmText ? { label: farmText, value: farmText } : null
                      }
                      isClearable
                    />
                    {!farmValid && (
                      <span style={{ color: "red" }}>
                        {" "}
                        * Minimum 3 characters, and must start with letters
                      </span>
                    )}
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Label className="text-center" for="fieldName" sm={3}>
                    Field Name
                  </Label>
                  <Col sm={9}>
                    <Input
                      className={s.Input}
                      type="field"
                      name="field"
                      id="examplefield"
                      placeholder="Create Field ..."
                      value={fieldText}
                      onChange={this.changeField}
                      invalid={!fieldValid}
                    />
                    {!fieldValid && (
                      <span style={{ color: "red" }}>
                        {" "}
                        * Minimum 3 characters, and must start with letters
                      </span>
                    )}
                  </Col>
                </FormGroup>
              </Form>
            </Col>
          </Row>
        )}

        {/*Step 2 Map Drawer*/}
        {currentStep === 1 && (
          <Row>
            {/* <Col className={s.InfoText} sm={{size: 12, offset: 0}}>
                        <h3>Please Drag Polygon Icon <img src={icon} alt=''width="30" height="30"/> on the Map to Locate Field Ranges.</h3>
                    </Col>
                    <Col sm={{size: 10, offset: 1}}> */}
            <div className={s.mapContainer}>
              <Alert color="primary">
                <span style={{ display: "block" }}>
                  • Please draw a polygon shape on the map using the{" "}
                  <img src={icon} alt="" width="30" height="30" /> to outline
                  your field.
                </span>
                <span style={{ display: "block" }}>
                  • Install within the{" "}
                  <Link to="https://www.telstra.com.au/business-enterprise/about-enterprise/our-network/iot-coverage-map">
                    Telstra's IoT network
                  </Link>
                </span>
              </Alert>
              <h6 className={s.handDown}>
                Click <span className="glyphicon glyphicon-hand-down" />
              </h6>
              <MapWithADrawingManager
                googleMapURL={googleMapAPI}
                loadingElement={
                  <div style={{ height: "100%", width: "100%" }} />
                }
                containerElement={
                  <div style={{ height: "100%", width: "100%" }} />
                }
                mapElement={<div style={{ height: "100%", width: "100%" }} />}
                CurrentLocation={
                  polygon?.length > 0
                    ? { lat: polygon[0][1], lng: polygon[0][0] }
                    : this.state.currentLatLng
                }
                drawComplete={this.drawComplete}
                polygon={
                  polygon?.length > 0
                    ? polygon.map((point) => ({ lat: point[1], lng: point[0] }))
                    : undefined
                }
                points={this.state.field.points?.map((p) => ({
                  lat: p[1],
                  lng: p[0],
                }))}
                fields={this.state.fields.map(({ field_name, points }) => ({
                  field_name,
                  points: points.map((point) => ({
                    lat: point[1],
                    lng: point[0],
                  })),
                }))}
              />
            </div>
            {/* </Col> */}
          </Row>
        )}

        {/*Step 3 Confirm Page */}
        {currentStep === 2 && (
          <Row>
            <Col className={s.InfoText} sm={{ size: 12, offset: 0 }}>
              <span>
                Please submit to finish registering your field:
                <span className="text-success" style={{ display: "block" }}>
                  {fieldText}
                </span>
              </span>
            </Col>
          </Row>
        )}

        {/*Step 4 Result Page */}
        {currentStep === 3 && (
          <Row>
            <Col className={s.InfoText} sm={{ size: 12, offset: 0 }}>
              {this.state.postLoading && <Spinner color="secondary" />}
              {!this.state.postLoading &&
                (this.state.postStatus ? (
                  <div style={{ margin: "25px 0px 0px 0px" }}>
                    <span className="text-success">
                      <span className="glyphicon glyphicon-ok mr-2" />
                      Field {this.props.field
                        ? "is updated"
                        : "registered"}{" "}
                      successfully
                    </span>
                    {!this.props.field && (
                      <span className={s.text}>
                        Go to{" "}
                        <Link to="/app/Management/ZoneManagement">
                          "Management &gt; My Zones"
                        </Link>{" "}
                        to register your irrigation/soil zones.
                      </span>
                    )}
                  </div>
                ) : (
                  <span className="text-danger">
                    <span className="glyphicon glyphicon-remove mr-2" />
                    Field {this.props.field ? "edit" : "create"} error. Please
                    try again later.
                  </span>
                ))}
            </Col>
          </Row>
        )}

        <Row className="mb-3 mt-2">
          <Col sm={12} className="d-flex justify-content-end">
            {currentStep < 3 && currentStep > 0 && (
              <Button
                className="mr-auto"
                color="primary"
                outline
                onClick={() => {
                  this.setState({ currentStep: currentStep - 1 });
                }}
              >
                Back
              </Button>
            )}
            {currentStep === 1 && (
              <Button
                onClick={this.onClickClear}
                color="primary"
                className="mr-1"
                outline
              >
                Clean Drawing
              </Button>
            )}
            {currentStep < 2 && (
              <Button
                disabled={!nextEnabled}
                onClick={this.onClickNext}
                color="primary"
                className=""
                outline
              >
                Next
              </Button>
            )}
            {currentStep === 2 && (
              <Button
                onClick={this.onClickSubmit}
                color="primary"
                className="floar-right"
                outline
              >
                Submit
              </Button>
            )}
            {currentStep === 3 && (
              <Button
                onClick={this.onClickClose}
                color="danger"
                className="floar-right"
                outline
              >
                Close
              </Button>
            )}
          </Col>
        </Row>
      </div>
    );
  }
}

export default FormInput;
