import React from "react";
import PropTypes from "prop-types";
import { Button, FormGroup, Input, Form, Alert } from "reactstrap";
import s from "./Register.module.scss";
import { registerU } from "../../actions/cognito";
import Confirm from "./Confirm";
import { EMAIL_REG, PASSWORD_REG, USERNAME_REG } from "../../utilities";

class Register extends React.Component {
  static propTypes = {
    jumpToLogIn: PropTypes.func.isRequired,
    dispatch: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.doSignup = this.doSignup.bind(this);
    this.state = {
      password: "",
      passwordConfirm: "",
      email: "",
      username: "",
      isFetching: false,
      errorMessage: "",
      showSucess: false,
      confirmToggle: false,
    };
  }

  jumpToLogin = () => {
    this.props.jumpToLogIn();
  };

  jumpToConfirm() {
    this.setState({ confirmToggle: true });
  }

  doSignup(e) {
    e.preventDefault();
    this.setState({ isFetching: true });
    this.props
      .dispatch(
        registerU({
          userName: this.state.username,
          password: this.state.password,
          email: this.state.email,
        })
      )
      .then((data) => {
        this.setState({ showSucess: true, errorMessage: "" });
        this.jumpToConfirm();
        // setTimeout(() => {
        //     this.props.jumpToLogIn()
        // }, 1500)
      })
      .catch((errorMessage) => {
        this.setState({ errorMessage, showSucess: false });
      })
      .finally(() => {
        this.setState({ isFetching: false });
      });
  }

  changeVal = (event, key) => {
    this.setState({ [key]: event.target.value });
  };

  checkUsername = () => {
    return !/\s/.test(this.state.username) && USERNAME_REG.exec(this.state.username);
  };

  render() {
    // input form check
    let { username, email, password, passwordConfirm } = this.state;
    const userNameValid = username.length > 0 ? this.checkUsername() : true;
    const emailValid = email.length > 0 ? EMAIL_REG.exec(email) : true;
    const passwordValid = password.length > 0 ? PASSWORD_REG.exec(password) : true;
    const passwordConfirmValid = passwordConfirm.length > 0 ? passwordConfirm === password : true;
    const submitEnabled = userNameValid && emailValid && passwordValid && passwordConfirmValid;

    return (
      <div>
        {this.state.confirmToggle && <Confirm username={username} jumpToLogin={this.jumpToLogin} />}
        {/*<Confirm username={this.state.userName}/>*/}
        <h1 className="mt-0 text-white">Sign Up</h1>
        <p className="fs-sm text-light">Please fill this form to create an account.</p>
        <Form className="mt" onSubmit={this.doSignup}>
          {this.state.errorMessage && (
            <Alert size="sm" className="bg-danger font-weight-bold text-white border-0">
              * {this.state.errorMessage} Please try again.
            </Alert>
          )}
          {this.state.showSucess && (
            <Alert size="sm" className="bg-success font-weight-bold text-white border-0">
              * Register Success. Please confirm your account.
            </Alert>
          )}
          <FormGroup>
            <Input
              className="no-border"
              value={this.state.username}
              onChange={(e) => this.changeVal(e, "username")}
              type="username"
              required
              name="username"
              placeholder="Username"
            />
            {!userNameValid && (
              <span className={s.errorMessage}>
                {" "}
                * Minimum 3 characters, start with letters. <br />
                * Do not include spaces. <br />
                * Start with a letter (either UPPERCASE or lowercase)
                * Can include special characters after 3 characters
              </span>
            )}
          </FormGroup>
          <FormGroup>
            <Input
              className="no-border"
              value={this.state.email}
              onChange={(e) => this.changeVal(e, "email")}
              type="email"
              required
              name="email"
              placeholder="Email"
            />
            {!emailValid && <span className={s.errorMessage}> * Invalid Email Address</span>}
          </FormGroup>
          <FormGroup>
            <Input
              className="no-border"
              value={this.state.password}
              onChange={(e) => this.changeVal(e, "password")}
              type="password"
              required
              name="password"
              placeholder="Password"
            />
            {!passwordValid && (
              <span className={s.errorMessage}>
                {" "}
                * At least 8 characters with lower and upper case letters and numbers.
              </span>
            )}
          </FormGroup>
          <FormGroup>
            <Input
              className="no-border"
              value={this.state.passwordConfirm}
              onChange={(e) => this.changeVal(e, "passwordConfirm")}
              type="password"
              required
              name="password"
              placeholder="Confirm Password"
            />
            {!passwordConfirmValid && <span className={s.errorMessage}> * Password does not match</span>}
          </FormGroup>
          <div className={s.LogIn}>
            Already have an account?
            <span className="ml-1" onClick={this.props.jumpToLogIn}>
              Login here.
            </span>
          </div>
          <div className="d-flex justify-content-between align-items-center">
            <div>
              <Button disabled={!submitEnabled} color="success" size="md" type="submit">
                {this.state.isFetching ? "Loading..." : "Submit"}
              </Button>
            </div>
          </div>
        </Form>
      </div>
    );
  }
}

export default Register;
