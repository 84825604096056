import { combineReducers } from "redux";
import auth from "./auth";
import runtime from "./runtime";
import navigation from "./navigation";
// import posts from "./posts";
import field from "./field";
import sensor from './sensor'

export default combineReducers({
  auth,
  runtime,
  navigation,
  // posts,
  field,
  sensor
});
