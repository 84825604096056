import React from "react";
import { confirmReset, resetPassword } from "../../actions/cognito";
import { ModalBody, Modal, ModalHeader, Form, FormGroup, Input, Button, Alert } from "reactstrap";
import { EMAIL_REG, PASSWORD_REG, USERNAME_REG } from "../../utilities";
import s from "../register/Register.module.scss";
import PropType from "prop-types";

class ResetPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      isFetching: "",
      errorMessage: "",
      confirmErrorMessage: "",
      showSucess: false,
      showConfirmSuccess: false,
      newPassword: "",
      newPasswordConfirm: "",
      code: "",
      userName: "",
      isModalOpen: false,
    };
    this.closeModal = this.closeModal.bind(this);
  }

  changeVal = (event, key) => {
    this.setState({ [key]: event.target.value });
  };

  closeModal = () => {
    this.setState({ isModalOpen: false });
  };

  doReset = (event) => {
    event.preventDefault();
    this.setState({ isFetching: true });
    this.props
      .dispatch(resetPassword({ userName: this.state.userName }))
      .then((data) => {
        this.setState({
          showSucess: true,
          isModalOpen: true,
        });
      })
      .catch((errorMessage) => {
        this.setState({ errorMessage });
      })
      .finally(() => {
        this.setState({ isFetching: false });
      });
  };

  confirmReset = (event) => {
    event.preventDefault();
    this.props
      .dispatch(
        confirmReset({
          email: this.state.email,
          code: this.state.code,
          newPassword: this.state.newPassword,
          userName: this.state.userName,
        })
      )
      .then((data) => {
        this.setState({ showConfirmSuccess: true });
        setTimeout(() => {
          this.props.jumpToLogIn();
        }, 2000);
      })
      .catch((confirmErrorMessage) => {
        this.setState({ confirmErrorMessage });
      })
      .finally(() => {
        this.setState({ isFetching: false });
      });
  };

  render() {
    let {
      userName,
      email,
      isModalOpen,
      newPassword,
      newPasswordConfirm,
      code,
      confirmErrorMessage,
      showConfirmSuccess,
    } = this.state;
    const emailValid = email.length > 0 ? EMAIL_REG.exec(email) : true;
    const userNameValid = userName.length > 0 ? USERNAME_REG.exec(userName) : true;
    const submitEnabled = userNameValid && emailValid;
    const passwordValid = newPassword.length > 0 ? PASSWORD_REG.exec(newPassword) : true;
    const passwordConfirmValid = newPasswordConfirm.length > 0 ? newPasswordConfirm === newPassword : true;
    const confirmEnabled = passwordValid && passwordConfirmValid;
    return (
      <div>
        <Modal isOpen={isModalOpen}>
          <ModalHeader className="modal-header">Set your new password</ModalHeader>
          <ModalBody className="modal-body" onSubmit={this.confirmReset}>
            <Form className="confirm-form">
              {confirmErrorMessage && (
                <Alert size="sm" className="bg-danger font-weight-bold text-white border-0">
                  * {confirmErrorMessage} Please try again.
                </Alert>
              )}
              {showConfirmSuccess && (
                <Alert size="sm" className="bg-success font-weight-bold text-white border-0">
                  * New password confirmed. Please login.
                </Alert>
              )}
              <FormGroup>
                <Input
                  className="no-border"
                  value={code}
                  onChange={(e) => this.changeVal(e, "code")}
                  type="number"
                  required
                  name="code"
                  placeholder="Verification Code"
                />
              </FormGroup>
              <FormGroup>
                <Input
                  className="no-border"
                  value={this.state.newPassword}
                  onChange={(e) => this.changeVal(e, "newPassword")}
                  type="password"
                  required
                  name="newPassword"
                  placeholder="Your new password"
                />
                {!passwordValid && (
                  <span className={s.errorMessage}>
                    {" "}
                    * At least 8 characters with lower and upper case letters and numbers.
                  </span>
                )}
              </FormGroup>
              <FormGroup>
                <Input
                  className="no-border"
                  value={this.state.newPasswordConfirm}
                  onChange={(e) => this.changeVal(e, "newPasswordConfirm")}
                  type="password"
                  required
                  name="confirmPassword"
                  placeholder="Confirm your new password"
                />
                {!passwordConfirmValid && <span className={s.errorMessage}> * Inconsistent password</span>}
              </FormGroup>
              <div className="d-flex justify-content-between align-content-around">
                <Button
                  // disabled={!submitEnabled}
                  color="danger"
                  size="md"
                  type="button"
                  // onClick={() => this.setState({ isModalOpen: false})}
                  onClick={this.closeModal}
                >
                  Cancel
                </Button>
                <Button disabled={!confirmEnabled} color="success" size="md" type="submit">
                  Submit
                </Button>
              </div>
            </Form>
          </ModalBody>
        </Modal>
        <h1 className="mt-0 text-white">Reset Password</h1>
        <p className="fs-sm text-light">Please fill in your Email account.</p>
        <Form className="mt" onSubmit={this.doReset}>
          {this.state.errorMessage && (
            <Alert size="sm" className="bg-danger font-weight-bold text-white border-0">
              * {this.state.errorMessage} Please try again.
            </Alert>
          )}
          {this.state.showSucess && (
            <Alert size="sm" className="bg-success font-weight-bold text-white border-0">
              * Verification code has been sent. Please check your email.
            </Alert>
          )}
          <FormGroup>
            <Input
              className="no-border"
              value={this.state.userName}
              onChange={(e) => this.changeVal(e, "userName")}
              type="username"
              required
              name="username"
              placeholder="Username"
            />
            {!userNameValid && <span className={s.errorMessage}> * Invalid username</span>}
          </FormGroup>
          <FormGroup>
            <Input
              className="no-border"
              value={this.state.email}
              onChange={(e) => this.changeVal(e, "email")}
              type="email"
              required
              name="email"
              placeholder="Email Address"
            />
            {!emailValid && <span className={s.errorMessage}> * Invalid Email Address</span>}
          </FormGroup>

          <div className={s.LogIn}>
            <span className="pull-right" onClick={this.props.jumpToLogIn}>
              Login here.
            </span>
          </div>
          <div className="d-flex justify-content-between align-items-center">
            <div>
              <Button disabled={!submitEnabled} color="success" size="md" type="submit">
                {this.state.isFetching ? "Loading..." : "Submit"}
              </Button>
            </div>
          </div>
        </Form>
      </div>
    );
  }
}

ResetPassword.propTypes = {
  dispatch: PropType.func.isRequired,
  jumpToLogIn: PropType.func.isRequired,
};

export default ResetPassword;
