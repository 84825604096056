import React from "react";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";

import logo from "../../images/AQLogo.png";

import s from "./Sidebar.module.scss";
import LinksGroup from "../LinksGroup";

const Sidebar = ({ links: linksProps }) => (
  <nav className={s.root}>
    <header className={s.logo}>
      <Link to={(location) => {
        let search = new URLSearchParams(location.search);
        if (search.has('detail')) search.delete('detail')
        return {...location, pathname: '/app/Home', search: search.toString(), state: location.state}
      }}>
        <img src={logo} alt="logo" />
      </Link>
    </header>
    <span className={s.title}>Menu</span>
    <ul className={s.nav}>
      {linksProps.map((linkProps, idx) => {
        return (
          <LinksGroup {...linkProps} key={idx} />
        )
      }
      )}
    </ul>
  </nav>
);

function mapStateToProps(store) {
  return {
    sidebarOpened: store.navigation.sidebarOpened,
    sidebarStatic: store.navigation.sidebarStatic,
  };
}

export default withRouter(connect(mapStateToProps)(Sidebar));
