import { AxiosRequestConfig } from "axios";
import axiosInstance from "../axios/global";
import { EMPTY_FIELD_DATA } from "./field";
import { Action, Dispatch } from "redux";
import {
  FETCH_SENSOR_FAILURE,
  FETCH_SENSOR_REQUEST,
  FETCH_SENSOR_SUCCESS,
  SensorReducerType,
} from "src/reducers/sensor";
import { ThunkAction } from "redux-thunk";
import { RootState } from "src/store";

export type Sensor = {
  sensor_id: string;
  gateway_id?: string | null;
  geom?: string;
  field_id?: string | null;
  created?: Date;
  is_active?: boolean;
  username?: string | null;
  sleeping?: number;
  alias?: string | null;
  updated?: Date;
  points: string;
  coordinates: google.maps.LatLngLiteral;
};

export const isSensorMultilayer = (id: string) =>
  /([BCEF])/g.test(id.substring(1, 2));

export const EMPTY_SENSOR_DATA: Partial<Sensor> = {
  sensor_id: "No Sensors",
  field_id: EMPTY_FIELD_DATA.field_id,
} as Sensor;

export const fetchSensorsByField =
  (
    fieldId: string,
    config?: AxiosRequestConfig
  ): ThunkAction<
    void,
    RootState,
    unknown,
    Action & Omit<SensorReducerType, "isFetching">
  > =>
  async (dispatch: Dispatch, getState) => {
    if (getState().sensor.isFetching) return;
    dispatch({ type: FETCH_SENSOR_REQUEST });
    return axiosInstance
      .get<{ data: Sensor[] }>(`/api/v2/sensor/${fieldId}`, config)
      .then((res) => {
        let sensors = res.data.data;
        sensors = sensors.map((sensor) => {
          const point = JSON.parse(sensor.points).coordinates as number[];
          sensor.coordinates = { lat: point[1], lng: point[0] };
          return sensor;
        });
        dispatch({ type: FETCH_SENSOR_SUCCESS, sensors });
      })
      .catch((err) => {
        dispatch({ type: FETCH_SENSOR_FAILURE, message: err });
      });
  };
