import { Reducer } from "redux";
import { Field } from "src/actions/v2/field";

export const FETCH_FIELD_REQUEST = "FETCH_FIELD_REQUEST";
export const FETCH_FIELD_SUCCESS = "FETCH_FIELD_SUCCESS";
export const FETCH_FIELD_FAILURE = "FETCH_FIELD_FAILURE";

export type FieldReducerType = {
  isFetching: boolean;
  farmsWithFields: Record<string, Field[]>;
  fields: Field[];
  farms: string[];
  message: string;
};

export type FieldActionType = {
  type: "FETCH_FIELD_REQUEST"
} | {
  type: "FETCH_FIELD_SUCCESS"
  fields: Record<string, Field[]>
} | {
  type: "FETCH_FIELD_FAILURE"
  message: string
}

const field: Reducer<
  Partial<FieldReducerType>,
  FieldActionType
> = (state = { isFetching: false }, action) => {
  switch (action.type) {
    case FETCH_FIELD_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
      });
    case FETCH_FIELD_SUCCESS:

      return Object.assign({}, state, {
        isFetching: false,
        farmsWithFields: action.fields,
        fields: Object.values(action.fields || {}).flat(),
        farms: Object.keys(action.fields || {})
      });
    case FETCH_FIELD_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        message:
          action.message || "Something wrong happened. Please come back later",
      });
    default:
      return state;
  }
};

export default field;
