import React, { useRef, useEffect, useMemo } from "react";
import { GoogleMap, withScriptjs, withGoogleMap } from "react-google-maps";
import { DetailedMap } from "./DetailedMap";
import { SimplifiedMap } from "./SimplifiedMap";
import { IComponentType } from "src/types";
import { withRouter } from "react-router";

const DisplayMap: IComponentType<{ inDetailPage?: boolean }> = ({ location, inDetailPage }) => {
  const field = useMemo(() => location?.state?.field, [location])
  const mapRef = useRef<GoogleMap>(null);
  // const [zoom, setZoom] = useState(2)

  const autoZoom = (points: google.maps.LatLngLiteral[], map: GoogleMap) => {
    if (points.length > 0) {
      let bounds = new google.maps.LatLngBounds();
      points.forEach((p) => bounds.extend(p));
      map.fitBounds(bounds);
      // setZoom(map.getZoom())
    }
  };

  useEffect(() => {
    if (!mapRef.current) return;
    if (field?.coordinates) {
      autoZoom(field.coordinates, mapRef.current);
      // setCenter(getCenter(field.coordinates));
    }
  }, [field, mapRef, inDetailPage]);

  return (
    <GoogleMap mapTypeId="satellite" ref={mapRef}>
      {inDetailPage ? <DetailedMap map={mapRef} /> : <SimplifiedMap map={mapRef} />}
    </GoogleMap>
  );
};

export default withScriptjs(withGoogleMap<{ inDetailPage?: boolean }>(withRouter(DisplayMap)));