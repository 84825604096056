import React, { useEffect, useMemo, useState } from "react";
import { withRouter } from "react-router";
import { IComponentType } from "src/types";
import FieldFarmSelector from "./selectors/FieldFarmSelector";
import SensorSelectorV2 from "./selectors/SensorSelectorV2";
import { isSensorMultilayer } from "src/actions/v2/sensor";
import {
  fetchMoistureDataBySensor,
  MoistureData,
} from "src/actions/v2/moisture";
import Axios from "axios";
import { Button, Spinner, Table } from "reactstrap";
import { Link } from "react-router-dom";
import { useAppSelector } from "src/store";
const formatMoisture = (val: number) => Math.max(+val.toFixed(1), 0);

const SensorTable: IComponentType<React.HTMLProps<"div">> = ({
  className,
  location,
  history,
  ...props
}) => {
  const [data, setData] = useState<MoistureData>();
  const [isFetching, setIsFetching] = useState(false);
  const [fetched, setFetched] = useState(false);
  const [err, setErr] = useState<string>();
  const sensor = useMemo(() => location.state?.sensor, [location.state]);
  const auth = useAppSelector(state => state.auth)

  useEffect(() => {
    setErr(undefined);
    setData(undefined);
    setIsFetching(false);
    setFetched(false);
  }, [location.state]);

  useEffect(() => {
    if (!sensor) {
      setFetched(true);
      return;
    }

    if (data?.sensor_id === sensor.sensor_id || isFetching || !!err) return;

    setIsFetching(true);
    const source = Axios.CancelToken.source();
    fetchMoistureDataBySensor<{ data: Required<MoistureData>[] }>(
      sensor.sensor_id,
      sensor.field_id!,
      { limit: 1 },
      { cancelToken: source.token }
    )
      .then(({ data }) => {
        if (data.length === 0) setErr('No Sensor Data');
        setData(data[0])
      }
      )
      .catch((err) => {
        console.log(err);
        setErr(auth.userGroup === 'admin' ? err : 'No Sensor Data');
      })
      .finally(() => {
        setIsFetching(false);
        setFetched(true);
      });

    return () => {
      // source.cancel();
    };
  }, [location.state, data, isFetching, sensor, err, auth]);

  return (
    <div className={className}>
      <h3>Latest Record</h3>
      <div>
        <FieldFarmSelector />
        <SensorSelectorV2 />
      </div>

      <div className="d-felx flex-column" style={{ flex: 1 }}>
        {isFetching || fetched === false ? (
          <div className="my-5">
            <Spinner className="mx-auto d-block" />
          </div>
        ) : !data || !sensor || !!err ? (
          <div className="text-secondary mt-5">
            <span className="h4 m-4 text-center d-block">{err}.</span>
            <span className="h4 m-4 text-center d-block">
              Please go to "Management" to check all your configurations.
            </span>
          </div>
        ) : (
          <>
            <Table className="mt-3">
              <tbody>
                <tr>
                  <td>Sensor Id</td>
                  <td>{data!.sensor_id}</td>
                </tr>
                <tr>
                  <td>Alias</td>
                  <td>{location.state?.sensor?.alias || "---"}</td>
                </tr>
                <tr>
                  <td>Time</td>
                  <td>{new Date(data!.time).toString().substring(0, 25)}</td>
                </tr>
                <tr>
                  <td>Battery</td>
                  <td>{data!.battery_vol} V</td>
                </tr>
                {isSensorMultilayer(sensor.sensor_id) ? (
                  <>
                    <tr>
                      <td>Moisture-10cm</td>
                      <td>{formatMoisture(data!.cap50_percent!)} %</td>
                    </tr>
                    <tr>
                      <td>Moisture-100cm</td>
                      <td>{formatMoisture(data!.cap100_percent!)} %</td>
                    </tr>
                    <tr>
                      <td>Moisture-150cm</td>
                      <td>{formatMoisture(data!.cap150_percent!)} %</td>
                    </tr>
                  </>
                ) : (
                  <tr>
                    <td>Moisture</td>
                    <td>{formatMoisture(data!.cap50_percent!)} %</td>
                  </tr>
                )}
                <tr>
                  <td>Temperature</td>
                  <td>{data!.temperature} ℃</td>
                </tr>
              </tbody>
            </Table>
            {/* View Detail Button */}
            <Link to={{ ...location, pathname: "/app/Home/Dashboard" }}>
              <Button color="primary" className="mt-2">
                View Details
              </Button>
            </Link>
          </>
        )}
      </div>
    </div>
  );
};

export default withRouter(SensorTable);
