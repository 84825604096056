import React from "react";
import { Row, Col } from "reactstrap";
import Widget from "src/components/Widget";
import { COMPARED_SENSOR_QUERY_KEY } from "../../v2";
import MoistureDepthChart from "../charts/MoistureDepthChart";
import MoistureTemperatureChart from "../charts/MoistureTempChart";
import s from "../../Dashboard.module.scss";
import { EMPTY_SENSOR_DATA } from "src/actions/v2/sensor";

const MoistureChart = () => {
  return (
    <>
      {/* Moisture Temperature Charts */}
      <>
        <Row className={s.body}>
          <Col sm={12} md={12}>
            <Widget
              className={s.stretch}
              title={
                <h4>Average Soil Moisture/Temperature Variation Chart A</h4>
              }
            >
              <MoistureTemperatureChart />
            </Widget>
          </Col>
        </Row>

        <Row className={s.body}>
          <Col sm={12} md={12}>
            <Widget
              className={s.stretch}
              title={
                <h4>Average Soil Moisture/Temperature Variation Chart B</h4>
              }
            >
              <MoistureTemperatureChart
                queryKey={COMPARED_SENSOR_QUERY_KEY}
              />
            </Widget>
          </Col>
        </Row>
      </>

      {/* Moisture Depth Charts */}
      <>
        <Row className={s.body}>
          <Col sm={12} md={6}>
            <Widget
              className={s.stretch}
              title={<h4>Moisture Depth Chart A</h4>}
            >
              <MoistureDepthChart />
            </Widget>
          </Col>

          <Col sm={12} md={6}>
            <Widget
              className={s.stretch}
              title={<h4>Moisture Depth Chart B</h4>}
            >
              <MoistureDepthChart
                queryKey={COMPARED_SENSOR_QUERY_KEY}
              />
            </Widget>
          </Col>
        </Row>
      </>
    </>
  );
};

export default MoistureChart;
