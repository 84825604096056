import React from "react";
import { Line, LineProps } from "recharts";
type LineWithControllerProps = {
  show: boolean;
};

export class LineWithController extends (Line as React.ComponentClass<
  LineWithControllerProps & LineProps
>) {
  render(): React.ReactNode {
    if (this.props.show) return super.render();
    else return <></>;
  }
}