import React from "react";
import { withRouter } from "react-router";
import s from "./Dashboard.module.scss";
import { Col, Row } from "reactstrap";
import Widget from "src/components/Widget";
import SensorTable from "./components/SensorTable";
import DisplayMap from "./components/DisplayMap";
import { googleMapAPI } from "src/config/secret";
import FieldFarmSelector from "./components/selectors/FieldFarmSelector";
import { IComponentType } from "src/types";

const Dashboard: IComponentType = () => {
  return (
    <div className={s.root}>
      <Row className={s.title}>
        <Col sm={12} md={10}>
          <h1 className="mb-lg">Dashboard</h1>
        </Col>

        {/* Farm-Field Selection*/}
        <Col sm={12} md={2}>
          <FieldFarmSelector className="float-right" flat color="primary" redirectTo='/app/Home/Dashboard' />
        </Col>
      </Row>

      <Row className="mt-4">
        <Col sm="12" md="4">
          <Widget className={s.stretch}>
            <SensorTable className="h-100 d-flex flex-column" />
          </Widget>
        </Col>

        <Col sm="12" md="8">
          <div className={s.MapContainer}>
            <DisplayMap
              containerElement={<div style={{ height: "inherit" }} />}
              mapElement={<div style={{ height: "inherit" }} />}
              googleMapURL={googleMapAPI}
              loadingElement={
                <div style={{ height: "inherit", width: "inherit" }} />
              }
            />
          </div>
        </Col>
      </Row>
    </div>
  )
};

export default withRouter(Dashboard);
