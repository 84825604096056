/**
 * Fetch Sensor Data by field id
 * @param  Post request parameters
 * @return sensor data
 */
export async function fetchSensorByField(params) {
  let keys = Object.keys(params);
  const config = {
    method: "POST",
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
    credentials: "include",
    body: keys.map((k) => `${k}=${params[k]}`).join("&"),
  };
  return fetch("/api/sensor/field", config)
    .then((res) =>
      res.json().then((responseJson) => ({
        sensor: responseJson.data,
        error: responseJson.error,
      }))
    )
    .then((data) => {
      if (data.error) {
        return Promise.reject(data.error);
      }
      return Promise.resolve(data.sensor);
    })
    .catch((err) => console.error("Error: ", err));
}

/**
 * Fetch Sensor Data
 * @param  Post request parameters
 * @return sensor data
 */
export function fetchSensor(params) {
  let keys = Object.keys(params);
  const config = {
    method: "POST",
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
    credentials: "include",
    body: keys.map((k) => `${k}=${params[k]}`).join("&"),
  };

  return (dispatch) => {
    return fetch("/api/sensor", config)
      .then((res) =>
        res.json().then((responseJson) => ({
          sensor: responseJson.data,
          error: responseJson.err,
        }))
      )
      .then((data) => {
        if (data.error) {
          // If there was a problem, we want to
          // dispatch the error condition
          //dispatch(fetchFieldError(data.error));
          return Promise.reject(data.error);
        }
        //dispatch(fetchFieldSuccess(data.fields));
        return Promise.resolve(data.sensor);
      })
      .catch((err) => console.error("Error: ", err));
  };
}

/**
 * Delete Sensor
 * @param  Post request parameters
 * @return result
 */
export function deleteSensor(params) {
  const config = {
    method: "DELETE",
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
    credentials: "include",
  };

  return (dispatch) => {
    return fetch(`/api/sensor/${params.sensorId}`, config)
      .then((res) =>
        res.json().then((responseJson) => ({
          data: responseJson.data,
          error: responseJson.error,
        }))
      )
      .then((data) => {
        if (data.error) {
          return Promise.reject(data.error);
        }
        //dispatch(fetchFieldSuccess(data.fields));
        return Promise.resolve(data);
      })
      .catch((err) => console.error("Error: ", err));
  };
}

/**
 * Add Sensor
 * @param  Post request parameters
 * @return result
 */
export function insertSensor(params) {
  let keys = Object.keys(params);
  const config = {
    method: "POST",
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
    credentials: "include",
    body: keys.map((k) => `${k}=${params[k]}`).join("&"),
  };

  console.log("config: ", config.body);
  return (dispatch) => {
    return fetch("/api/sensor/new", config)
      .then((res) => res.json())
      .then((data) => {
        if (data.error) {
          console.log(data.error);
          return Promise.reject(data.error);
        }
        return Promise.resolve(data.sensor);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  };
}

/**
 * get data for single sensor
 * @param Get request query parameters (id, username, fieldId)
 * @return result
 */
export function fetchSensorById(id, username, fieldId) {
  const config = {
    method: "GET",
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
    credentials: "include",
  };
  return fetch(`/api/sensor/${id}?username=${username}&fieldId=${fieldId}`, config)
    .then((res) => res.json())
    .then((data) => {
      if (data.error) {
        console.log(data.error);
        return Promise.reject(data.error);
      }
      return Promise.resolve(data.sensor);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
}

/**
 * update sensor information
 * @param Put request query parameters (id, username, fieldId, values want to update)
 * @return result
 */
export async function updateSensorData(params) {
  const id = params.sensorId;
  const config = {
    method: "PUT",
    headers: { "Content-Type": "application/json" },
    credentials: "include",
    body: JSON.stringify(params.values),
  };
  return fetch(`/api/sensor/${id}`, config)
    .then((res) => res.json())
    .then((data) => {
      if (data.err) {
        console.log(data.err);
        return Promise.reject(data.err);
      }
      return Promise.resolve(data);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
}

/**
 * insert new type of sensoor
 * @param  Post request parameters
 * @return result
 */
export async function insertNewSensor(params) {
  let keys = Object.keys(params);
  const config = {
    method: "POST",
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
    credentials: "include",
    body: keys.map((k) => `${k}=${params[k]}`).join("&"),
  };

  return fetch("/api/sensor/v2/new", config)
    .then((res) => res.json())
    .then((data) => {
      if (data.error) {
        return Promise.reject(data);
      }
      return Promise.resolve(data.sensor);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
}
