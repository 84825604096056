import React from "react";
import PropTypes from "prop-types";
import { withRouter, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { Alert, Button, FormGroup, Input, Row, Col, Form } from "reactstrap";
import s from "./Login.module.scss";
import Widget from "../../components/Widget";
import Footer from "../../components/Footer/Footer";
import Register from "../register/Register"
import logo from "../../images/AQLogo.png";
import bd1 from "../../images/bd1.jpg";
import bd2 from "../../images/bd2.jpg";
import bd3 from "../../images/bd3.jpg";
import bd4 from "../../images/bd4.jpg";
import bd5 from "../../images/bd5.jpg";
import bd6 from "../../images/bd6.jpg";
import { userLogin } from "../../actions/cognito";
import Confirm from "../register/Confirm";
import ResetPassword from "./ResetPassword";

class Login extends React.Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    isAuthenticated: PropTypes.bool,
    isFetching: PropTypes.bool,
    location: PropTypes.any, // eslint-disable-line
    errorMessage: PropTypes.string,
  };

  static defaultProps = {
    isAuthenticated: false,
    isFetching: false,
    location: {},
    errorMessage: null,
  };

  constructor(props) {
    super(props);

    this.state = {
      login: "",
      password: "",
      SignUpToggle: false,
      ResetPasswordToggle: false,
      confirmToggle: false,
    };

    this.backgroundStyle = this.getBgStyle();
    this.loginBtnRef = React.createRef();
  }

  changeLogin = (event) => {
    this.setState({ login: event.target.value });
  };

  changePassword = (event) => {
    this.setState({ password: event.target.value });
  };

  doLogin = () => {
    this.props
      .dispatch(
        userLogin({
          login: this.state.login,
          password: this.state.password,
        })
      )
      .then((data) => {})
      .catch((error) => {
        this.setState({ errorMessage: error.message });
        switch (error.name) {
          case "UserNotConfirmedException":
            this.setState({ confirmToggle: true });
            break;
          default:
            break;
        }
      });
  };

  // choose background randomly
  getBgStyle = () => {
    // choose background randomly
    let bdArr = [bd1, bd2, bd3, bd4, bd5, bd6];
    let bd = bdArr[Math.floor(Math.random() * bdArr.length)];
    return {
      paddingTop: `5vh`,
      height: `100vh`,
      width: `100%`,
      backgroundImage: `url(${bd})`,
      backgroundPosition: `center`,
      backgroundRepeat: `no-repeat`,
      backgroundSize: `cover`,
    };
  };

  render() {
    const { from } = this.props.location.state || {
      from: { pathname: "/app" },
    };

    if (this.props.isAuthenticated) {
      // cant access login page while logged in
      return <Redirect to={from} />;
    }

    return (
      <div style={this.backgroundStyle}>
        {/* Verify Email for unconfirmed account */}
        {this.state.confirmToggle && (
          <Confirm
            username={this.state.login}
            jumpToLogin={() => {
              this.doLogin();
            }}
          />
        )}

        {/* Background */}
        <Row>
          <Col xs={{ size: 10, offset: 1 }} sm={{ size: 6, offset: 3 }} lg={{ size: 4, offset: 9 }}>
            <img className={s.logo} src={logo} alt="logo" />
          </Col>
        </Row>

        {/* Login form */}
        <Row>
          <Col xs={{ size: 10, offset: 1 }} sm={{ size: 6, offset: 3 }} lg={{ size: 4, offset: 1 }}>
            <Widget className={s.widget}>
              {/*Login Module*/}
              {!this.state.SignUpToggle && !this.state.ResetPasswordToggle && (
                <div>
                  <h1 className="mt-0 text-white">Login</h1>
                  {/* <p className="fs-sm text-light">
                                    Use your email or username, password to sign in
                                </p> */}
                  <Form
                    className="mt"
                    onSubmit={(e) => {
                      e.preventDefault();
                      this.doLogin();
                    }}
                  >
                    {this.props.errorMessage && (
                      <Alert size="sm" className="bg-danger font-weight-bold text-white border-0">
                        * {this.props.errorMessage} Please try again.
                      </Alert>
                    )}
                    <FormGroup className="form-group">
                      <p className="fs-sm text-light">Email or Username:</p>
                      <Input
                        className="no-border"
                        value={this.state.login}
                        onChange={this.changeLogin}
                        type="text"
                        required
                        name="username"
                        placeholder="Enter your email or username"
                      />
                    </FormGroup>
                    <FormGroup>
                      <p className="fs-sm text-light">Password:</p>
                      <Input
                        className="no-border"
                        value={this.state.password}
                        onChange={this.changePassword}
                        type="password"
                        required
                        name="password"
                        placeholder="Enter your password"
                      />
                    </FormGroup>
                    <div className={s.SignUp}>
                      <span
                        onClick={() => {
                          this.setState({ SignUpToggle: true });
                        }}
                      >
                        Sign up now
                      </span>
                      <span
                        className="pull-right"
                        onClick={() => {
                          this.setState({ ResetPasswordToggle: true });
                        }}
                      >
                        Forgot my password
                      </span>
                    </div>
                    <div className="d-flex justify-content-between align-items-center">
                      <div>
                        <Button color="success" size="md" type="submit" ref={this.loginBtnRef}>
                          {this.props.isFetching ? "Loading..." : "Login"}
                        </Button>
                      </div>
                    </div>
                  </Form>
                </div>
              )}
              {/*Register Module*/}
              {this.state.SignUpToggle && (
                <Register
                  dispatch={this.props.dispatch}
                  jumpToLogIn={() => {
                    this.setState({ SignUpToggle: false });
                  }}
                ></Register>
              )}
              {this.state.ResetPasswordToggle && (
                <ResetPassword
                  dispatch={this.props.dispatch}
                  jumpToLogIn={() => {
                    this.setState({ ResetPasswordToggle: false });
                  }}
                ></ResetPassword>
              )}
            </Widget>
          </Col>
        </Row>
        <Footer className="text-center" />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    isFetching: state.auth.isFetching,
    isAuthenticated: state.auth.isAuthenticated,
    errorMessage: state.auth.errorMessage,
  };
}

export default withRouter(connect(mapStateToProps)(Login));
