import {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  LOGOUT_SUCCESS,
} from "../reducers/auth";
import { setAuth } from "./axios/global";
export const LOGOUT_REQUEST = "LOGOUT_REQUEST";
export const LOGOUT_FAILURE = "LOGOUT_FAILURE";
export const FETCH_PROFILE_REQUEST = "FETCH_PROFILE_REQUEST";
export const FETCH_PROFILE_SUCCESS = "FETCH_PROFILE_SUCCESS";
export const FETCH_PROFILE_FAILURE = "FETCH_PROFILE_FAILURE";

function requestLogin(creds) {
  return {
    type: LOGIN_REQUEST,
    isFetching: true,
    isAuthenticated: false,
    creds,
  };
}

export function receiveLogin(user) {
  return {
    type: LOGIN_SUCCESS,
    isFetching: false,
    isAuthenticated: true,
    id_token: user.id_token,
    userName: user.userName,
  };
}

function loginError(message) {
  return {
    type: LOGIN_FAILURE,
    isFetching: false,
    isAuthenticated: false,
    message,
  };
}

function requestLogout() {
  return {
    type: LOGOUT_REQUEST,
    isFetching: true,
    isAuthenticated: true,
  };
}

export function receiveLogout() {
  return {
    type: LOGOUT_SUCCESS,
    isFetching: false,
    isAuthenticated: false,
  };
}

function requestFetchProfile(userName) {
  return {
    type: FETCH_PROFILE_REQUEST,
    isFetching: true,
    isAuthenticated: true,
    userName: userName,
  };
}

function fetchProfileSuccess(profile) {
  return {
    type: FETCH_PROFILE_SUCCESS,
    isFetching: false,
    isAuthenticated: true,
    profile,
  };
}

function fetchProfileError(message) {
  return {
    type: FETCH_PROFILE_FAILURE,
    isFetching: false,
    isAuthenticated: true,
    message,
  };
}

export function fetchProfile(params) {
  const config = {
    method: "POST",
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
    credentials: "include",
    body: `userName=${params.userName}`,
  };
  return (dispatch) => {
    dispatch(requestFetchProfile());
    return fetch("/api/user/profile", config)
      .then((res) =>
        res.json().then((responseJson) => ({
          profile: responseJson.data,
          error: responseJson.err,
        }))
      )
      .then((data) => {
        if (data.error) {
          // If there was a problem, we want to
          // dispatch the error condition
          dispatch(fetchProfileError(data.error));
          return Promise.reject(data.error);
        }
        dispatch(fetchProfileSuccess(data));
        return Promise.resolve(data);
      })
      .catch((err) => console.error("Error: ", err));
  };
}

export function updateProfile(params) {
  let keys = Object.keys(params);
  const config = {
    method: "POST",
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
    credentials: "include",
    body: keys.map((k) => `${k}=${params[k]}`).join("&"),
  };
  return (dispatch) => {
    return fetch("/api/user/update", config)
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if (data.error) return Promise.reject(data.error);
        return Promise.resolve(data);
      });
  };
}

// register a user
export function registerUser(params) {
  let keys = Object.keys(params);
  const config = {
    method: "POST",
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
    credentials: "include",
    body: keys.map((k) => `${k}=${params[k]}`).join("&"),
  };
  return (dispatch) => {
    return fetch("/api/user/register", config)
      .then((res) => res.json())
      .then((data) => {
        if (data.error) return Promise.reject(data.error);
        return Promise.resolve(data);
      });
  };
}

// Logs the user out
export function logoutUser() {
  return (dispatch) => {
    dispatch(requestLogout());
    localStorage.removeItem("id_token");
    localStorage.removeItem("userName");
    document.cookie = "id_token=;expires=Thu, 01 Jan 1970 00:00:01 GMT;";
    //console.log('User Logout')
    dispatch(receiveLogout());
  };
}

export function loginUser(creds) {
  const config = {
    method: "POST",
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
    credentials: "include",
    body: `userName=${creds.login}&password=${creds.password}`,
  };
  return (dispatch) => {
    // We dispatch requestLogin to kickoff the call to the API
    dispatch(requestLogin(creds));
    // if (process.env.NODE_ENV === "development") {
    return fetch("/api/user/login", config)
      .then((response) => response.json().then((user) => ({ user, response })))
      .then(({ user, response }) => {
        if (!response.ok) {
          // If there was a problem, we want to
          // dispatch the error condition
          dispatch(loginError(user.error));
          return Promise.reject(user);
        }
        // in posts create new action and check http status, if malign logout
        // If login was successful, set the token in local storage
        localStorage.setItem("id_token", user.id_token);
        localStorage.setItem("userName", creds.login);
        setAuth(user.id_token)
        // Dispatch the success action
        dispatch(receiveLogin(user));
        return Promise.resolve(user);
      })
      .catch((err) => console.error("Error: ", err));
    // } else {
    //     localStorage.setItem('id_token', appConfig.id_token);
    //     dispatch(receiveLogin({id_token: appConfig.id_token}))
    // }
  };
}
