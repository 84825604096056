export const EMAIL_REG = /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/;
export const PASSWORD_REG = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
export const USERNAME_REG = /^[a-zA-Z][a-zA-Z0-9]{2,}/;
export const PHONE_REG = /\+[0-9]{8,}$/;
export const GATEWAYID_REG = /^AquaTerraGateway[a-zA-Z0-9]{6}$/;

/**
 * Helper Function to Group value by a key
 */
export function groupBy<T>(xs: T[], f: (item: T) => string): Record<string, T[]> {
  return xs.reduce(
    (r, v) => {
      const k = f(v);
      (r[k] || (r[k] = [])).push(v);
      return r;
    }, 
    {} as Record<string, T[]>
  );
}
