import React, { useEffect, useMemo } from "react";
import { withRouter } from "react-router";
import { DropdownItem, DropdownMenu, DropdownToggle } from "reactstrap";
import { fetchFields } from "src/actions/v2/field";
import ButtonDropdownMenu from "src/components/dropdown-menu";
import DropDownSelector from "src/components/DropDownSelector";
import { useAppDispatch, useAppSelector } from "src/store";
import { IComponentType } from "src/types";

const FieldFarmSelector: IComponentType<{
  flat?: boolean;
  className?: string;
  color?: string;
  redirectTo?: string
}> = ({ location, history, flat = false, className, color, redirectTo }) => {
  const dipsatch = useAppDispatch();
  const { fields, farms, farmsWithFields, isFetching } = useAppSelector(
    (state) => state.field
  );

  const search = useMemo(
    () => new URLSearchParams(location.search),
    [location.search]
  );

  useEffect(() => {
    if (!!farmsWithFields) return;
    if (isFetching) return;
    dipsatch(fetchFields());
  }, [farmsWithFields, dipsatch, isFetching]);

  useEffect(() => {
    if (!fields || fields?.length === 0) return;

    if (
      search.has("field") &&
      location.state?.field?.field_id! !== search.get("field")
    ) {
      history.replace({
        ...location,
        state: {
          ...location.state,
          field: fields.find((f) => f.field_id === search.get("field")),
        },
      });
      return;
    }

    if (!search.has("field")) {
      search.set("field", fields[0].field_id!);
      history.replace({ ...location, search: search.toString() });
      return;
    }
  }, [fields, history, location, search]);

  const setField = (fieldId: string | null) => {
    let search = new URLSearchParams(location.search);
    if (!fieldId) search.delete("field");
    else search.set("field", fieldId);

    search.delete("sensor");
    search.delete("compareSensor");

    history.push({ ...location, search: search.toString(), pathname: redirectTo ?? location.pathname });
  };

  const setFarm = (farm: string | null) => {
    if (!farm) setField(null);
    else {
      setField(farmsWithFields?.[farm]?.[0].field_id || null);
    }
  };

  const field = location.state?.field;

  if (flat)
    return (
      <ButtonDropdownMenu className={className}>
        <DropdownToggle
          color={color}
          caret
          disabled={!farms || farms.length === 0}
        >
          <span className="glyphicon glyphicon-th-large mr-2 mt-1"></span>
          {field
            ? field.field_name
            : !!farms && farms.length > 0
              ? "Choose Farm and Field"
              : "No Farms"}
        </DropdownToggle>
        {!!farms && farms.length > 0 && (
          <DropdownMenu>
            {farms.map((farm, index) => (
              <div key={`dropdownMenu-${index}`}>
                <DropdownItem className="font-weight-bold">
                  {farm.toUpperCase()}
                </DropdownItem>
                {!!farmsWithFields &&
                  !!farmsWithFields[farm] &&
                  farmsWithFields[farm].map((field, index) => (
                    <DropdownItem
                      onClick={(e) => {
                        setField(field.field_id!);
                      }}
                      key={`dropdownItem-${index}`}
                    >
                      {field.field_name}
                    </DropdownItem>
                  ))}
                {index < farms.length - 1 && <DropdownItem divider />}
              </div>
            ))}
          </DropdownMenu>
        )}
      </ButtonDropdownMenu>
    );

  return (
    <>
      {!!farms && (
        <DropDownSelector
          chosenItem={{ farm_name: !!field ? field.farm_name! : "No Farm" }}
          items={farms.map((farm) => ({ farm_name: farm }))}
          onSelect={(e) => setFarm(e.currentTarget.ariaValueText)}
          keyForText={"farm_name"}
          keyForValue={"farm_name"}
        />
      )}

      {!!farmsWithFields && (
        <DropDownSelector
          chosenItem={field || { field_name: "No Field", field_id: null }}
          items={!!field ? farmsWithFields[field.farm_name!] : []}
          onSelect={(e) => setField(e.currentTarget.ariaValueText)}
          keyForText={"field_name"}
          keyForValue={"field_id"}
        />
      )}
    </>
  );
};

export default withRouter(FieldFarmSelector);
