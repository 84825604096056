import React, { useMemo, useState } from "react";
import { Marker, InfoWindow, Polygon, Circle, GoogleMap } from "react-google-maps";
import { EMPTY_SENSOR_DATA } from "src/actions/v2/sensor";
import { getCenter } from "../../util";
import { withRouter } from "react-router";
import { IComponentType } from "src/types";

const Map: IComponentType<{ map: React.RefObject<GoogleMap> }> = ({ location, map }) => {
  const { field, sensor } = useMemo(
    () => ({ field: location.state?.field, sensor: location.state?.sensor }),
    [location]
  );
  const [showInfoWindow, setShowInfoWindow] = useState(true);
  const center = useMemo(() => {
    return field?.coordinates
      ? getCenter(field.coordinates)
      : { lat: 0, lng: 0 };
  }, [field]);

  if (!field) return <></>
  if (!sensor) return <></>

  return (
    <>
      <Marker
        icon={{
          url: "https://maps.google.com/mapfiles/ms/icons/blue-pushpin.png",
        }}
        position={center}
        onClick={() => setShowInfoWindow((prev) => !prev)}
      >
        {showInfoWindow && (
          <InfoWindow position={center} options={{ disableAutoPan: true }}>
            <span>{field.field_name}</span>
          </InfoWindow>
        )}
      </Marker>
      <Polygon
        options={{
          strokeColor: "#369BF7",
          fillOpacity: 0,
          zIndex: -1,
        }}
        path={field.coordinates}
      />

      {sensor.sensor_id !== EMPTY_SENSOR_DATA.sensor_id && (
        <Circle
          radius={0.2 * 2 ** (22 - ((map.current?.getZoom() || 17) || 17))}
          options={{
            strokeWeight: 3,
            fillOpacity: 0.8,
            fillColor: "#50C5B7",
            strokeColor: "#50C5B7",
            strokeOpacity: 0.5,
          }}
          center={sensor.coordinates}
        />
      )}
    </>
  );
};

export const SimplifiedMap = withRouter(Map);
