import { createStore, applyMiddleware } from "redux";
import ReduxThunk from "redux-thunk";
import reducers from "../reducers";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
export const store = createStore(reducers, applyMiddleware(ReduxThunk));

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

export const useAppDispatch: () => AppDispatch = useDispatch
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector