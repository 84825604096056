import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import {
  Row,
  Col,
  Table,
  Button,
  Spinner,
  Pagination,
  PaginationItem,
  PaginationLink,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ButtonDropdown,
  DropdownMenu,
  DropdownToggle,
  DropdownItem,
} from "reactstrap";
import Widget from "../../components/Widget/index.tsx";
import s from "./SensorManagement.module.scss";
import { fetchField } from "../../actions/field";
import { deleteSensor, fetchSensorByField } from "../../actions/sensor";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SensorRegistrationModal from "./SensorRegistrationModal/SensorRegistrationModal";

class SensorManagement extends PureComponent {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
  };
  state = {
    farms: [],
    res: {},
    fields: [],
    sensors: [],
    tableloading: false,
    modalOpen: false,
    dropdownOpen: false,
    deleteModalOpen: false,
    updateModalOpen: false,
    createModalOpen: false,
    choosenField: "",
    choosenFieldId: "",
    chosenSensor: "",
    options: {
      position: "top-right",
      autoClose: 5000,
      closeOnClick: false,
      pauseOnHover: false,
      draggable: true,
    },
  };

  deleteSensor(e) {
    console.log("deleteSensor triggered", this.state.chosenSensor);
    this.props
      .dispatch(
        deleteSensor({
          sensorId: this.state.chosenSensor,
          fieldId: this.state.choosenFieldId,
        })
      )
      .then((data) => {
        this.refreshTable();
        this.deleteToggle();
        toast.success(
          <div>
            <span className="glyphicon glyphicon-info-sign ml-2 mr-2" />
            Delete field {this.state.chosenSensor} was successful
          </div>,
          { ...this.state.options }
        );
      })
      .catch((err) => {
        toast.error(
          <div>
            <span className="glyphicon glyphicon-info-sign ml-2 mr-2" />
            Delete field {this.state.chosenSensor} was failed
          </div>,
          { ...this.state.options }
        );
      });
  }

  deleteToggle(sensor) {
    if (typeof sensor == "string") this.setState({ chosenSensor: sensor });
    this.setState({ deleteModalOpen: !this.state.deleteModalOpen });
  }

  loadTable() {
    this.setState({ tableloading: true });
    this.props.dispatch(fetchField({ userName: this.userName })).then((res) => {
      let farms = Object.keys(res);
      this.setState({ farms });
      let fields = [];
      this.setState({ res });
      for (let farm of farms) fields = fields.concat(res[farm]);
      this.setState({ fields });
      this.setState({ tableloading: false });
    });
  }

  refreshTable() {
    this.setState({ tableloading: true });
    fetchSensorByField({
      userName: this.userName,
      fieldId: this.state.choosenFieldId,
    }).then((res) => {
      let sensors = res;
      this.setState({ sensors });
    });
    this.setState({ tableloading: false });
  }

  toggleDropdown() {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen,
    });
  }

  componentDidMount() {
    this.loadTable();
  }

  dropDownSelect = (event) => {
    this.setState(
      {
        choosenField: event.target.innerText,
        choosenFieldId: event.target.value,
      },
      () => {
        this.refreshTable();
      }
    );
  };

  createToggle() {
    this.setState({ createModalOpen: !this.state.createModalOpen });
  }

  updateToggle(sensor) {
    if (sensor) this.setState({ chosenSensor: sensor });
    this.setState({ updateModalOpen: !this.state.updateModalOpen });
  }

  constructor(props) {
    super(props);
    this.deleteToggle = this.deleteToggle.bind(this);
    this.deleteSensor = this.deleteSensor.bind(this);
    this.toggleDropdown = this.toggleDropdown.bind(this);
    this.createToggle = this.createToggle.bind(this);
    this.refreshTable = this.refreshTable.bind(this);
    this.updateToggle = this.updateToggle.bind(this);
    this.userName = localStorage.getItem("userName");
    this.tableKeys = [
      { key: "sensor_id", label: "ID" },
      { key: "alias", label: "Alias" },
      { key: "field_name", label: "Field Name" },
    ];
  }

  render() {
    const { deleteModalOpen, createModalOpen, choosenFieldId, updateModalOpen } = this.state;

    return (
      <div className={s.root}>
        {/* Delete Sensor Modal*/}
        <Modal isOpen={deleteModalOpen} toggle={this.deleteToggle}>
          <ModalHeader toggle={this.deleteToggle}> Sensor Deletion</ModalHeader>
          <ModalBody>
            <span className="text-danger mr-2 ml-2 mt-2 mb-2 text-center">
              <span className="glyphicon glyphicon-warning-sign mr-2" />
              Are you sure you want to delete this sensor ?
            </span>
          </ModalBody>
          <ModalFooter>
            <Button color="danger" onClick={this.deleteSensor} outline>
              Delete
            </Button>{" "}
            <Button color="primary" onClick={this.deleteToggle} outline>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>

        {/* Edit and View Sensor Modal */}
        {updateModalOpen && (
          <SensorRegistrationModal
            toggle={this.updateToggle}
            isOpen={updateModalOpen}
            fieldId={choosenFieldId}
            tableReload={this.refreshTable}
            userName={this.userName}
            sensor={this.state.chosenSensor}
            size="lg"
          />
        )}

        {/* Create Sensor Modal */}
        {createModalOpen && (
          <SensorRegistrationModal
            toggle={this.createToggle}
            isOpen={createModalOpen}
            fieldId={choosenFieldId}
            userName={this.userName}
            tableReload={this.refreshTable}
          />
        )}
        {/* Heading Row*/}
        <Row className={s.title}>
          <Col sm={12} md={9}>
            <h1 className="mb-lg">Sensor Management</h1>
          </Col>
          {choosenFieldId !== "" && (
            <Col sm={12} md={3}>
              <Button onClick={this.createToggle} color="primary" className="floar-right">
                <span className="glyphicon glyphicon-new-window mr-2 mt-1" />
                Create New Sensor
              </Button>
            </Col>
          )}
        </Row>
        {/* Table Row */}
        <Row>
          <Col sm={12} md={11}>
            <Widget className={s.shadow} title={<h3> Sensor List</h3>} settings close>
              {/*farm dropdown list*/}
              <ButtonDropdown className={s.dropDown} isOpen={this.state.dropdownOpen} toggle={this.toggleDropdown}>
                <DropdownToggle color="primary" caret outline>
                  {this.state.choosenField || "Choose A Field"}
                </DropdownToggle>
                <DropdownMenu>
                  {this.state.farms &&
                    this.state.farms.map((farm, index) => {
                      return (
                        <div key={index}>
                          <DropdownItem className={"text-uppercase"} header>
                            {farm}
                          </DropdownItem>
                          {this.state.res[farm] &&
                            this.state.res[farm].map((field, index) => (
                              <DropdownItem key={index} onClick={this.dropDownSelect} value={field.field_id}>
                                {field.field_name}
                              </DropdownItem>
                            ))}
                          {index < this.state.farms.length - 1 && <DropdownItem divider />}
                        </div>
                      );
                    })}
                </DropdownMenu>
              </ButtonDropdown>
              {!this.state.choosenField && (
                <div>
                  <hr />
                  <span>No field selected. Please select a field from the dropdown menu.</span>
                  <hr />
                </div>
              )}
              {this.state.choosenField &&
                (this.state.tableloading ? (
                  <Spinner color="primary" className={s.center} />
                ) : (
                  <div>
                    <Table borderless className={s.mainTable}>
                      <thead>
                        <tr align="center">
                          <th className="hidden-sm-down">#</th>
                          {this.tableKeys.map((key, index) => (
                            <th key={index}>{key.label}</th>
                          ))}
                          <th>actions</th>
                          <th />
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.sensors.map((row, index) => {
                          return (
                            <tr key={index + 1} align="center">
                              <td>{index + 1}</td>
                              {this.tableKeys.map((key, index) => (
                                <td key={index}>{row[key.key]}</td>
                              ))}
                              <td>
                                <Button
                                  onClick={() => {
                                    this.updateToggle(row["sensor_id"]);
                                  }}
                                  className="mr-3"
                                  size="sm"
                                  color="success"
                                  outline
                                >
                                  <span className="glyphicon glyphicon-edit mr-1 mt-1"></span>
                                  Edit
                                </Button>
                                <Button
                                  onClick={() => {
                                    this.deleteToggle(row["sensor_id"]);
                                  }}
                                  size="sm"
                                  color="danger"
                                  outline
                                >
                                  <span className="glyphicon glyphicon-trash mr-1 mt-1" />
                                  Delete
                                </Button>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                    <div className="row d-flex justify-content-center">
                      <Pagination aria-label="Page navigation example">
                        <PaginationItem>
                          <PaginationLink first />
                        </PaginationItem>
                        <PaginationItem>
                          <PaginationLink previous />
                        </PaginationItem>
                        <PaginationItem>
                          <PaginationLink>1</PaginationLink>
                        </PaginationItem>
                        <PaginationItem>
                          <PaginationLink next />
                        </PaginationItem>
                        <PaginationItem>
                          <PaginationLink last />
                        </PaginationItem>
                      </Pagination>
                    </div>
                  </div>
                ))}
            </Widget>
          </Col>
        </Row>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

export default connect(mapStateToProps)(SensorManagement);
