import axios from "axios";
const axiosInstance = axios.create({});

export const setAuth = (token?: string) => {
  if (!token) return
  axiosInstance.defaults.headers.Authorization = `Bearer ${token}`
}

if (localStorage.getItem("id_token")) {
  setAuth(localStorage.getItem('id_token')!)
}

export default axiosInstance;
