import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import {
  Row,
  Col,
  Button,
  Spinner,
  Pagination,
  PaginationItem,
  PaginationLink,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ButtonDropdown,
  DropdownMenu,
  DropdownToggle,
  DropdownItem,
} from "reactstrap";
import Widget from "../../components/Widget";
import s from "./FieldManagement.module.scss";
import { fetchField, deleteField } from "../../actions/field";
import { connect } from "react-redux";
import FormInput from "./FormInput/FormInput";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { deleteFarm, fetchFarm } from "../../actions/farm";
import SensorRegistrationModal from "./SensorRegistrationModal/SensorRegistrationModal";
import { FieldTable } from "./field-table";

const options = {
  position: "top-right",
  autoClose: 5000,
  closeOnClick: false,
  pauseOnHover: false,
  draggable: true,
};

class FieldManagement extends PureComponent {
  constructor(props) {
    super(props);
    this.userName = localStorage.getItem("userName");
  }

  static propTypes = {
    dispatch: PropTypes.func.isRequired,
  };

  state = {
    fields: [],
    farms: [],
    chosenFarm: "",
    tableLoading: false,
    modalOpen: false,
    dropdownOpen: false,
    deleteModalOpen: false,
    deleteFarmModalOpen: false,
    chosenField: {},
    newGatewayModalOpen: false,
    editFieldModalOpen: false,
    gatewayState: false,
    polyline: [],
    editingFieldId: "",
  };

  deleteField = (e) => {
    const { dispatch } = this.props;
    const { chosenField } = this.state;
    dispatch(deleteField({ fieldId: chosenField.field_id }))
      .then((data) => {
        this.loadTable();
        this.deleteFieldToggle();
        toast.success(
          <div>
            <span className="glyphicon glyphicon-info-sign ml-2 mr-2" />
            Delete field {chosenField.field_name} was successful
          </div>,
          { ...options }
        );
      })
      .catch((err) => {
        toast.error(
          <div>
            <span className="glyphicon glyphicon-info-sign ml-2 mr-2" />
            Delete field {chosenField.field_name} was failed
          </div>,
          { ...options }
        );
      });
  };

  deleteFarm = (event) => {
    const { dispatch } = this.props;
    const { chosenFarm } = this.state;
    dispatch(deleteFarm({ userName: this.userName, farmname: chosenFarm }))
      .then((data) => {
        this.loadTable();
        this.deleteFarmToggle();
        this.setState({ chosenFarm: "" });
        toast.success(
          <div>
            <span className="glyphicon glyphicon-info-sign ml-2 mr-2" />
            Delete farm {chosenFarm} was successful
          </div>,
          { ...options }
        );
      })
      .catch((err) => {
        toast.error(
          <div>
            <span className="glyphicon glyphicon-info-sign ml-2 mr-2" />
            Delete farm {chosenFarm} was failed
          </div>,
          { ...options }
        );
      });
  };

  createFieldToggle = () => {
    this.setState({ modalOpen: !this.state.modalOpen });
  };

  editFieldToggle = (field) => {
    this.setState({
      editFieldModalOpen: !this.state.editFieldModalOpen,
      chosenField: field,
    });
  };

  deleteFieldToggle = (field) => {
    this.setState({
      chosenField: field,
      deleteModalOpen: !this.state.deleteModalOpen,
    });
  };

  deleteFarmToggle = () => {
    this.setState({ deleteFarmModalOpen: !this.state.deleteFarmModalOpen });
  };

  newSensorToggle = (field) => {
    const { newGatewayModalOpen } = this.state;
    if (!!field) {
      let points;
      if (field) points = JSON.parse(field.points).coordinates[0];
      try {
        let polyline = points.map((point) => ({
          lng: point[0],
          lat: point[1],
        }));
        this.setState({
          chosenField: field,
          polyline: polyline,
          newGatewayModalOpen: !newGatewayModalOpen,
        });
      } catch (err) {
        console.log("Error in map field points ", err);
      }
    } else {
      this.setState({
        chosenField: "",
        newGatewayModalOpen: !newGatewayModalOpen,
      });
    }
  };

  loadTable() {
    this.setState({ tableLoading: true });
    fetchFarm({ userName: this.userName }).then((res) => {
      let farms = [];
      res.forEach((farm) => {
        farms.push(farm.farm_name);
      });
      this.setState({ farms });
    });
    this.props.dispatch(fetchField({ userName: this.userName })).then((res) => {
      let farms = this.state.farms;
      let fields = [];
      for (let farm of farms) fields = fields.concat(res[farm] || []);
      this.setState({ fields });
      this.setState({ tableLoading: false });
    });
  }

  toggleDropdown = () => {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen,
    });
  };

  componentDidMount() {
    this.loadTable();
  }

  dropDownSelect = (item) => {
    let val = item.target.innerText;
    this.setState({ chosenFarm: val });
  };

  render() {
    const {
      deleteModalOpen,
      deleteFarmModalOpen,
      chosenFarm,
      chosenField,
      farms,
      fields,
      newGatewayModalOpen,
      polyline,
      modalOpen: createFieldModalOpen,
      editFieldModalOpen,
    } = this.state;

    return (
      <div className={s.root}>
        {/* Delete Field Modal*/}
        <Modal isOpen={deleteModalOpen} toggle={this.deleteFieldToggle}>
          <ModalHeader> Field Deletion</ModalHeader>
          <ModalBody>
            <h4 className="text-danger mr-2 ml-2 mt-2 mb-2 text-center">
              <span className="glyphicon glyphicon-warning-sign mr-2" />
              Are you sure you want to delete this field ?
            </h4>
          </ModalBody>
          <ModalFooter>
            <Button color="danger" onClick={this.deleteField} outline>
              Delete
            </Button>{" "}
            <Button color="primary" onClick={this.deleteFieldToggle} outline>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
        {/* Delete farm Modal*/}
        <Modal isOpen={deleteFarmModalOpen} toggle={this.deleteFarmToggle}>
          <ModalHeader> Farm Deletion</ModalHeader>
          <ModalBody>
            <h4 className="text-danger mr-2 ml-2 mt-2 mb-2 text-center">
              <span className="glyphicon glyphicon-warning-sign mr-2" />
              Are you sure you want to delete this farm ?
            </h4>
          </ModalBody>
          <ModalFooter>
            <Button color="danger" onClick={this.deleteFarm} outline>
              Delete
            </Button>{" "}
            <Button color="primary" onClick={this.deleteFarmToggle} outline>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
        {/* Create Field Modal*/}
        <Modal
          isOpen={createFieldModalOpen}
          toggle={this.createFieldToggle}
          size="lg"
        >
          <ModalHeader>Field Registration</ModalHeader>
          <ModalBody>
            <div className="ml-5 mr-5">
              <FormInput
                userName={this.userName}
                modalClose={() => {
                  this.setState({ modalOpen: false });
                }}
                tableReload={() => {
                  this.loadTable();
                }}
                fields={this.state.fields}
                dispatch={this.props.dispatch}
              />
            </div>
          </ModalBody>
        </Modal>
        {/* Edit Field Modal */}
        <Modal
          isOpen={editFieldModalOpen}
          toggle={this.editFieldToggle}
          size="lg"
        >
          <ModalHeader>Edit Field</ModalHeader>
          <ModalBody>
            <div className="ml-5 mr-5">
              <FormInput
                userName={this.userName}
                modalClose={() => this.setState({ editFieldModalOpen: false })}
                tableReload={() => {
                  this.loadTable();
                }}
                dispatch={this.props.dispatch}
                field={chosenField}
                fields={this.state.fields.filter(
                  (f) => f.farm_name === chosenFarm
                )}
              />
            </div>
          </ModalBody>
        </Modal>
        {/* New Sensor Modal*/}
        {newGatewayModalOpen && (
          <SensorRegistrationModal
            toggle={(field) => {
              this.newSensorToggle(field);
            }}
            isOpen={newGatewayModalOpen}
            fieldId={chosenField.field_id}
            polyline={polyline}
            userName={this.userName}
          />
        )}
        {/* Heading Row*/}
        <Row className={s.title}>
          <Col sm={12} md={9}>
            <h1 className="mb-lg">Farm & Field Management</h1>
          </Col>
          <Col sm={12} md={3}>
            <Button
              onClick={this.createFieldToggle}
              color="primary"
              className="floar-right"
            >
              <span className="glyphicon glyphicon-new-window mr-2 mt-1" />
              Create New Farm/Field
            </Button>
          </Col>
        </Row>
        {/* Table Row */}
        <Row>
          <Col sm={12} md={11}>
            <Widget
              className={s.shadow}
              title={
                <div className="d-flex mb-2">
                  <span className="h3 mr-auto ml-1 align-self-center ">
                    {" "}
                    Field List
                  </span>

                  {chosenFarm && (
                    <Button
                      onClick={this.deleteFarmToggle}
                      className={s.deleteButton}
                    >
                      Delete this farm
                    </Button>
                  )}
                  {/*farm dropdown list*/}
                  <ButtonDropdown
                    className={s.dropDown}
                    isOpen={this.state.dropdownOpen}
                    toggle={this.toggleDropdown}
                  >
                    <DropdownToggle color="primary" caret outline>
                      {chosenFarm || "Choose A Farm"}
                    </DropdownToggle>
                    <DropdownMenu>
                      {farms.map((farm, index) => (
                        <DropdownItem onClick={this.dropDownSelect} key={index}>
                          {farm}
                        </DropdownItem>
                      ))}
                    </DropdownMenu>
                  </ButtonDropdown>
                </div>
              }
              settings
              close
            >
              {!chosenFarm && (
                <div>
                  <hr />
                  <h4>
                    No farm selected. Please select a farm from the dropdown
                    menu.
                  </h4>
                  <hr />
                </div>
              )}
              {chosenFarm && (this.state.tableLoading ? (
                <Spinner color="primary" className={s.center} />
              ) : (
                <div>
                  <FieldTable
                    fields={fields.filter(
                      (f) => !!f && (f.farm_name === chosenFarm || !chosenFarm)
                    )}
                    className={s.mainTable}
                    actions={{
                      newSensorToggle: this.newSensorToggle,
                      editFieldToggle: this.editFieldToggle,
                      deleteFieldToggle: this.deleteFieldToggle,
                    }}
                    borderless
                  />
                  <div className="row d-flex justify-content-center">
                    <Pagination aria-label="Page navigation example">
                      <PaginationItem>
                        <PaginationLink first />
                      </PaginationItem>
                      <PaginationItem>
                        <PaginationLink previous />
                      </PaginationItem>
                      <PaginationItem>
                        <PaginationLink>1</PaginationLink>
                      </PaginationItem>
                      <PaginationItem>
                        <PaginationLink next />
                      </PaginationItem>
                      <PaginationItem>
                        <PaginationLink last />
                      </PaginationItem>
                    </Pagination>
                  </div>
                </div>
              ))}
            </Widget>
          </Col>
        </Row>
      </div>
    );
  }
}

function mapStateToProps() {
  return {};
}

export default connect(mapStateToProps)(FieldManagement);
