import { Action, Reducer } from "redux";
import { Sensor } from "src/actions/v2/sensor";

export const FETCH_SENSOR_REQUEST = "FETCH_SENSOR_REQUEST";
export const FETCH_SENSOR_SUCCESS = "FETCH_SENSOR_SUCCESS";
export const FETCH_SENSOR_FAILURE = "FETCH_SENSOR_FAILURE";

export type SensorReducerType = {
  isFetching: boolean;
  fetched: boolean;
  sensors?: Sensor[];
  message?: string;
};

const sensor: Reducer<
  SensorReducerType,
  Action & Omit<SensorReducerType, "isFetching">
> = (state = { isFetching: false, fetched: false }, action) => {
  switch (action.type) {
    case FETCH_SENSOR_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        sensors: undefined
      });
    case FETCH_SENSOR_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        fetched: true,
        sensors: action.sensors,
      });
    case FETCH_SENSOR_FAILURE:
      return Object.assign({}, state, {
        isFetchign: false,
        fetched: true,
        message:
          action.message || "Something went wrong, please come back later",
      });
    default:
      return state;
  }
};

export default sensor
