import { groupBy } from "src/utilities";
import {
  FETCH_FIELD_FAILURE,
  FETCH_FIELD_SUCCESS,
  FETCH_FIELD_REQUEST,
} from "../reducers/field";
import { AppDispatch } from "../store";

export type Field = {
  crop_type: string | null;
  elevation: number | null;
  farm_name: string;
  field_name: string;
  geom: string;
  points: string;
  soil_type: string | null;
  username: string;
  created: Date
  field_id?: string
};

function requestFetchField() {
  return {
    type: FETCH_FIELD_REQUEST,
    isFetching: true,
  };
}

function fetchFieldSuccess(fields: { [farm_name: string]: Field[] }) {
  return {
    type: FETCH_FIELD_SUCCESS,
    isFetching: false,
    fields,
  };
}

function fetchFieldError(message: string) {
  return {
    type: FETCH_FIELD_FAILURE,
    isFetching: false,
    message,
  };
}

export function fetchField(params: { userName: string }) {
  const config: RequestInit = {
    method: "POST",
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
    credentials: "include",
    body: `userName=${params.userName}`,
  };

  return async (dispatch: AppDispatch) => {
    // We dispatch requestFetchField to kickoff the call to the API
    dispatch(requestFetchField());

    return fetch("/api/field", config)
      .then((res) =>
        res.json().then((responseJson) => ({
          fields: responseJson.data,
          error: responseJson.err,
        }))
      )
      .then((data) => {
        if (data.error) {
          // If there was a problem, we want to
          // dispatch the error condition
          dispatch(fetchFieldError(data.error));
          return Promise.reject(data.error);
        }
        let fields = groupBy<Field>(data.fields, (c) => c.farm_name);
        dispatch(fetchFieldSuccess(fields));
        return Promise.resolve(fields);
      })
      .catch((err) => {
        console.error("Error: ", err);
        return Promise.reject(err);
      });
  };
}

/**
 * Delete Field Data
 * @param  Field Name
 */
export function deleteField(params: { fieldId: string }) {
  const config: RequestInit = {
    method: "DELETE",
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
    credentials: "include",
  };

  return (dispatch: AppDispatch) => {
    return fetch(`/api/field/${params.fieldId}`, config)
      .then((res) =>
        res.json().then((responseJson) => ({
          field: responseJson.data,
          error: responseJson.err,
        }))
      )
      .then((data) => {
        if (data.error) {
          return Promise.reject(data.error);
        }
        return Promise.resolve(data.field);
      })
      .catch((err) => console.error("Error: ", err));
  };
}

/**
 * Insert Field Data
 * @param {{field_id?: string }} params request parameters
 * @return Field data
 */
export async function fieldAction(params: {
  username: any;
  userName: any;
  points: any;
  field_id?: string;
  polygon?: string;
}) {
  const { field_id } = params;
  // if field_id is passed, should perform editting, then should delete unchangeable fields
  if (field_id) {
    delete params.field_id;
    delete params.username;
    delete params.polygon;
    delete params.userName;
    delete params.points;
  }
  const config: RequestInit = {
    method: field_id ? "PUT" : "POST",
    headers: { "Content-Type": "application/json" },
    credentials: "include",
    body: JSON.stringify(params),
  };

  let url = field_id ? `/api/field/${field_id}` : "/api/field/addField";

  return fetch(url, config)
    .then((res) =>
      res.json().then((responseJson) => ({
        field: responseJson.data,
        error: responseJson.err,
      }))
    )
    .then((data) => {
      if (data.error) {
        return Promise.reject(data.error);
      }
      return Promise.resolve(data.field);
    })
    .catch((err) => console.error("Error: ", err));
}

export async function fetchFieldById({ fieldId }: { fieldId: string }) {
  const config = {
    method: "GET",
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
    credentials: "include",
  };

  return fetch(`/api/field/${fieldId}`, config as RequestInit)
    .then((res) =>
      res.json().then((responseJson) => ({
        fields: responseJson.data,
        error: responseJson.err,
      }))
    )
    .then((data) => {
      if (data.error) {
        // If there was a problem, we want to
        // dispatch the error condition
        return Promise.reject(data.error);
      }
      return Promise.resolve(data.fields);
    })
    .catch((err) => Promise.resolve(err));
}
