import { useCallback, useMemo } from "react";
import { EMPTY_FIELD_DATA } from "src/actions/v2/field";
import { EMPTY_SENSOR_DATA, Sensor } from "src/actions/v2/sensor";
import { useAppSelector } from "src/store";
import {
  COMPARED_SENSOR_QUERY_KEY,
  FIELD_QUERY_KEY,
  SENSOR_QUERY_KEY,
  DETIAL_PAGE_QUERY_KEY,
} from "../v2";
import { useParamSearch } from "./params";

export const useDashboardHook = () => {
  const { getSearchState, setSearch } = useParamSearch();
  const {
    isFetching: isFetchingField,
    farmsWithFields = {
      [EMPTY_FIELD_DATA.farm_name!]: [EMPTY_FIELD_DATA],
    },
  } = useAppSelector((state) => state.field);

  const { fields, farms } = useMemo(
    () => ({
      fields: Object.values(farmsWithFields).flat(),
      farms: Object.keys(farmsWithFields),
    }),
    [farmsWithFields]
  );

  const chosenFieldId =
    getSearchState(FIELD_QUERY_KEY) || EMPTY_FIELD_DATA.field_id;
  const chosenField =
    fields.filter((f) => f.field_id === chosenFieldId)[0] || EMPTY_FIELD_DATA;

  const {
    isFetching: isFetchingSensor,
    sensors = [EMPTY_SENSOR_DATA as Sensor],
  } = useAppSelector((state) => state.sensor);

  const chosenSensor =
    sensors.filter(
      (s) => s.sensor_id === getSearchState(SENSOR_QUERY_KEY)
    )[0] || (EMPTY_SENSOR_DATA as Sensor);

  const compareSensor =
    sensors.filter(
      (s) => s.sensor_id === getSearchState(COMPARED_SENSOR_QUERY_KEY)
    )[0] || chosenSensor;

  const inDetailPage = true;
  const isFetching = isFetchingField && isFetchingSensor;

  const setField = useCallback(
    (fieldId: string | null) => {
      setSearch(FIELD_QUERY_KEY, fieldId);
    },
    [setSearch]
  );

  const setSensor = useCallback(
    (sensorId: string | null) => setSearch(SENSOR_QUERY_KEY, sensorId),
    [setSearch]
  );

  const setComparedSensor = useCallback(
    (sensorId: string | null) => setSearch(COMPARED_SENSOR_QUERY_KEY, sensorId),
    [setSearch]
  );

  const setInDetailPage = useCallback(
    (inDetailPage: boolean) =>
      setSearch(DETIAL_PAGE_QUERY_KEY, `${inDetailPage}`),
    [setSearch]
  );

  return {
    fields,
    farms,
    farmsWithFields,
    sensors,
    chosenField,
    chosenSensor,
    isFetching,
    isFetchingField,
    isFetchingSensor,
    compareSensor,
    inDetailPage,
    setField,
    setSensor,
    setComparedSensor,
    setInDetailPage,
  };
};
