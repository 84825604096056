import React, { Component } from "react";
import { Alert, Button, Form, FormGroup, Input, Modal, ModalHeader, ModalBody } from "reactstrap";
//import {confirmUser, resendCode} from "../../actions/cognito";
import { toast } from "react-toastify";
import { verifyChangedEmail } from "../../actions/cognito";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

const mapDispatchToProps = (dispatch) => ({
  // confirmUser: (params) => dispatch(confirmUser(params)),
  // resendCode:(params)=>dispatch(resendCode(params))
  verifyChangedEmail: (params) => dispatch(verifyChangedEmail(params)),
});

class Confirm extends Component {

  constructor(props) {
    super(props);
    this.state = {
      showSuccess: false,
      showResendSuccess: false,
      errorMessage: null,
      code: "",
      isModalOpen: true,
      // //
      isFetching: false,
      // phone: '',
      // email: '',
      // address:'',
      // oldPassword: '',
      // newPassword: '',
      // newPasswordConfirm: ''
      // //
    };
  }

  changeVal = (event, key) => {
    this.setState({ [key]: event.target.value });
  };


  doConfirm = (e) => {
    e.preventDefault();
    this.setState({ isFetching: true });
    this.props
      .verifyChangedEmail({
        code: this.state.code,
      })
      .then((data) => {
        this.setState({ showSucess: true });
        this.props.toggleModal();
        // setTimeout(() => {
        //     this.props.jumpToLogin()
        // }, 1500)
        toast.success(
          <div>
            <span className="glyphicon glyphicon-info-sign ml-2 mr-2" />
            Update was successful.
          </div>,
          { ...this.state.options }
        );
      })
      .catch((errorMessage) => {
        this.setState({ errorMessage });
      })
      .finally(() => {
        this.setState({ isFetching: false });
      });
  };

  render() {
    return (
      <Modal isOpen={this.props.isOpen} toggle={this.props.toggleModal}>
        <ModalHeader>Confirm Your Updated Email</ModalHeader>
        <ModalBody>
          <p className="fs-sm text-light">Please enter in the verification code we just sent to your updated email.</p>
          <Form className="mt" onSubmit={this.doConfirm}>
            {this.state.errorMessage && (
              <Alert size="sm" className="bg-danger font-weight-bold text-white border-0">
                * {this.state.errorMessage}
              </Alert>
            )}
            {this.state.showSucess && (
              <Alert size="sm" className="bg-success font-weight-bold text-white border-0">
                * Account Verified.
              </Alert>
            )}
            {this.state.showResendSuccess && (
              <Alert size="sm" className="bg-success font-weight-bold text-white border-0">
                * New verification code has been sent.
              </Alert>
            )}
            <FormGroup>
              <Input
                className="no-border"
                value={this.state.passwordConfirm}
                onChange={(e) => this.changeVal(e, "code")}
                type="code"
                required
                name="code"
                placeholder="Enter verification code"
              />
            </FormGroup>
            <div className="d-flex justify-content-between align-content-around">
              <Button color="primary" size="md" type="button" onClick={this.props.toggleModal}>
                Close
              </Button>
              <Button
                color="success"
                size="md"
                type="submit"
              >
                {this.state.isFetching ? "Loading..." : "Submit"}
              </Button>
            </div>
          </Form>
        </ModalBody>
      </Modal>
    );
  }
}

export default withRouter(connect(undefined, mapDispatchToProps)(Confirm));
