import React, { useEffect, useRef, useState } from "react";
import { Input, Label, Table } from "reactstrap";
import * as XLSX from "xlsx";

import { fetchRecord } from "../../../actions/admin/record";

import "./record.css";

const getDate = (days: number): Date => {
  const today = new Date();
  const date = new Date(today.getTime() + days * 24 * 60 * 60 * 1000);
  return date;
};

const toISOString = (date: Date): string => {
  let year = date.getFullYear();
  const fillStart = (e: number) => {
    return e.toString().padStart(2, "0");
  };
  let month = fillStart(date.getMonth() + 1);
  let day = fillStart(date.getDate());
  let hour = fillStart(date.getHours());
  let minute = fillStart(date.getMinutes());
  return `${year}-${month}-${day}T${hour}:${minute}`;
};

export const RecordTable = () => {
  const [username, setUsername] = useState("testramona");
  const [start, setStart] = useState<string>(toISOString(new Date()));
  const [sensors, setSensors] = useState<string[]>([]);
  const [dates, setDates] = useState<Date[]>([]);
  const [values, setValues] = useState<any[][]>([]);
  const [keys] = useState<string[]>(["prediction", "moisture"]);
  const table = useRef(null);

  const getRecord = () => {
    fetchRecord({ username, start: new Date(start) }).then((data) => {
      let sensors = Object.keys(data);
      setSensors(sensors);

      let dateStrs: string[] = [];
      sensors.forEach((sensor) => {
        dateStrs.push(...Object.keys(data[sensor]));
      });
      dateStrs.sort((a, b) => Date.parse(a) - Date.parse(b));
      let dates = [...new Set(dateStrs)];
      setDates(dates.map((date) => new Date(date)));

      let matrix: any[][] = [];
      sensors.forEach((sensor) => {
        let arr: any[] = [];
        dates.forEach((date) => {
          arr.push(data[sensor][date] || null);
        });
        matrix.push(arr);
      });
      setValues(matrix);
    });
  };

  useEffect(() => {
    document.body.style.overflowX = "auto";
    return () => {
      document.body.style.overflowX = "hidden";
    };
  }, []);

  const handleChangeStart = (e: React.ChangeEvent<HTMLInputElement>) => {
    setStart(e.target.value);
  };

  const downloadXLSX = () => {
    const workbook = XLSX.utils.table_to_book(table.current);

    const excelBuffer = XLSX.write(workbook, {
      type: "buffer",
      bookType: "xlsx",
    });

    const excelFile = new File([excelBuffer], `${username}-${start}.xlsx`, {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });

    const downloadLink = document.createElement("a");
    downloadLink.href = URL.createObjectURL(excelFile);
    downloadLink.download = `${username}-${start}.xlsx`;
    downloadLink.click();
  };

  const copyToClipboard = (id: number, key: string) => {
    let dataToCopy: any[] = [];
    values[id].forEach((dataRow) => {
      dataToCopy.push(dataRow ? (dataRow[key] ? dataRow[key] : "") : "");
    });
    let textToCopy: string = dataToCopy.join("\n");
    const textarea = document.createElement("textarea");
    textarea.value = textToCopy;
    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand("copy");
    document.body.removeChild(textarea);
    console.log("Text copied to clipboard:", textToCopy);
    // navigator.clipboard.writeText(textToCopy)
  };

  return (
    <>
      <div className="center">
        <Label for="username">Username:</Label>
        <Input id="username" type="text" value={username} onChange={(e) => setUsername(e.target.value)} />
        <br />
        <Label for="start-date">Start Date:</Label>
        <Input
          id="start-date"
          type="datetime-local"
          value={start}
          min={toISOString(getDate(-14))}
          max={toISOString(getDate(0))}
          onChange={(e) => handleChangeStart(e)}
        />
        <br />
        <Input type="button" onClick={getRecord} value={"Get Prediction Data"} />
        <br />
        {values.length > 0 && <Input type="button" onClick={downloadXLSX} value={"Download Excel"} />}
      </div>
      <div style={{ display: "flex", flexDirection: "row" }}></div>
      <div style={values.length > 0 ? { overflow: "scroll", maxHeight: "75vh" } : {}}>
        {values.length > 0 && (
          <Table striped border={1} id="table" innerRef={table}>
            <thead>
              {sensors.length > 0 && (
                <tr>
                  <th></th>
                  {sensors.map((s, idx) => (
                    <th key={idx} align="center" colSpan={keys.length}>
                      {s}
                    </th>
                  ))}
                </tr>
              )}
              {keys.length > 0 && (
                <tr>
                  <th></th>
                  {sensors.map((_, is) =>
                    keys.map((k, ik) => (
                      <th
                        key={`th-${is}-${ik}`}
                        onDoubleClick={() => copyToClipboard(is, k)}
                        title="Double click to copy the column"
                      >
                        {k}
                      </th>
                    ))
                  )}
                </tr>
              )}
            </thead>
            <tbody>
              {dates.map((date, id) => (
                <tr key={id}>
                  <th>
                    {date.toLocaleDateString()} {date.toLocaleTimeString()}
                  </th>
                  {values.map((data, idxd) => {
                    let val = data[id];
                    return keys.map((key, ik) => <td key={`${id}-${idxd}-${ik}`}>{(val && val[key]) || ""}</td>);
                  })}
                </tr>
              ))}
            </tbody>
          </Table>
        )}
      </div>
    </>
  );
};
