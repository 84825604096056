/**
 ******************************
 * Amazon Cognito             *
 * User management functions  *
 ******************************
 */

import jwt from "jsonwebtoken";
import config from "../../config";

import { store } from "../../store";
import { cogitoCredentials } from "../../config/secret";
import { setAuth } from "../axios/global";
const { UserPoolId, IdentityPoolId, ClientId } = cogitoCredentials;
const AWS = require("aws-sdk/global");
const AmazonCognitoIdentity = require("amazon-cognito-identity-js");
const poolData = { UserPoolId, ClientId };
const userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);
const AWSCognitoLink = `cognito-idp.ap-southeast-2.amazonaws.com/${UserPoolId}`;

AWS.config.region = "ap-southeast-2";
/**
 * Redux consts
 */
export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";
export const LOGOUT_REQUEST = "LOGOUT_REQUEST";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGOUT_FAILURE = "LOGOUT_FAILURE";
export const FETCH_PROFILE_REQUEST = "FETCH_PROFILE_REQUEST";
export const FETCH_PROFILE_SUCCESS = "FETCH_PROFILE_SUCCESS";
export const FETCH_PROFILE_FAILURE = "FETCH_PROFILE_FAILURE";

function requestLogin(creds) {
  return {
    type: LOGIN_REQUEST,
    isFetching: true,
    isAuthenticated: false,
    userName: creds.username,
  };
}

function receiveLogin(jwtToken, user, subscription) {
  return {
    type: LOGIN_SUCCESS,
    isFetching: false,
    isAuthenticated: true,
    idToken: jwtToken,
    userName: user["username"],
    userGroup: user["cognito:groups"] ? user["cognito:groups"][0] : "user",
    subscription,
  };
}

function loginError(message) {
  return {
    type: LOGIN_FAILURE,
    isFetching: false,
    isAuthenticated: false,
    errorMessage: message,
  };
}

function requestLogout() {
  return {
    type: LOGOUT_REQUEST,
    isFetching: true,
    isAuthenticated: true,
  };
}

export function receiveLogout() {
  return {
    type: LOGOUT_SUCCESS,
    isFetching: false,
    isAuthenticated: false,
  };
}

/**
 * Register a user
 * @param params.userName-required params.password-required
 * @returns {function(*): Promise}
 */
export function registerU(params) {
  let userName = params.userName;
  let password = params.password;
  let attributeList = [];
  let dataEmail = {
    Name: "email",
    Value: params.email,
  };

  // subscript level is full upon registration
  let subscriptionLevel = {
    Name: "custom:subscription_level",
    Value: "full",
  };

  // expiry date will be one year later
  let oneYearLater = new Date();
  oneYearLater.setFullYear(oneYearLater.getFullYear() + 1);
  oneYearLater.setDate(oneYearLater.getDate() + 1);

  // Format the date as "dd-mm-yyyy"
  const day = String(oneYearLater.getDate()).padStart(2, "0");
  const month = String(oneYearLater.getMonth() + 1).padStart(2, "0");
  const year = oneYearLater.getFullYear();
  let subscriptionExpiryDate = {
    Name: "custom:sub_expiry_date",
    Value: `${day}-${month}-${year}`,
  };

  // let ForceAliasCreation=True|False
  let attributeEmail = new AmazonCognitoIdentity.CognitoUserAttribute(
    dataEmail
  );
  attributeList.push(attributeEmail);

  attributeList.push(
    new AmazonCognitoIdentity.CognitoUserAttribute(subscriptionLevel)
  );
  attributeList.push(
    new AmazonCognitoIdentity.CognitoUserAttribute(subscriptionExpiryDate)
  );
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      userPool.signUp(
        userName,
        password,
        attributeList,
        null,
        function (err, result) {
          if (err) {
            return reject(err.message);
          } else {
            return resolve({ userName: result.user.getUsername() });
          }
        }
      );
    });
  };
}

/**
 * Profile: verify the email via confirmation code
 * @param {{code: string}} params code-required
 * @returns {function(*): Promise}
 */
export function verifyChangedEmail(params) {
  // let userName = params.userName
  // let password = params.password
  // let attributeList = [];
  //let attributeName = 'email'
  let { code } = params;
  // let dataEmail = {
  //     Name: 'email',
  //     Value: params.email,
  // };
  // let ForceAliasCreation=True|False
  // let attributeEmail = new AmazonCognitoIdentity.CognitoUserAttribute(dataEmail);
  // attributeList.push(attributeEmail);
  let cognitoUser = userPool.getCurrentUser();

  if (cognitoUser != null) {
    return (dispatch) => {
      return new Promise((resolve, reject) => {
        cognitoUser.getSession(function (err, session) {
          if (err) {
            return reject(err.message);
          }
          cognitoUser.verifyAttribute("email", code, {
            onSuccess() {
              resolve();
            },
            onFailure(err) {
              reject(err.message);
            },
          });
        });
      });
    };
  }
}

/**
 * Confirm user by verification code
 * @param params.userName-required params.code-required
 * @returns {function(*): Promise<unknown>}
 */
export function confirmUser(params) {
  let userName = params.userName;
  let code = params.code;
  let userData = {
    Username: userName,
    Pool: userPool,
  };
  let cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData);
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      //confirmRegistration(confirmationCode, forceAliasCreation, callback, clientMetadata)
      //forceAliasCreation: boolean. 'true': override previous email; 'false': throwing error if the same email is verified before.
      cognitoUser.confirmRegistration(code, false, function (err, result) {
        if (err) {
          return reject(err.message);
        } else {
          return resolve({ result: result });
        }
      });
    });
  };
}

/**
 * User login function
 * @param params.login-required params.password-required
 * @returns {function(*=): Promise}
 */
export function userLogin(params) {
  let userName = params.login;
  let password = params.password;

  let authenticationData = {
    Username: userName,
    Password: password,
  };
  let authenticationDetails = new AmazonCognitoIdentity.AuthenticationDetails(
    authenticationData
  );
  let userData = {
    Username: userName,
    Pool: userPool,
  };
  let cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData);

  return (dispatch) => {
    dispatch(requestLogin(params));

    return new Promise((resolve, reject) => {
      cognitoUser.authenticateUser(authenticationDetails, {
        onSuccess: function (result) {
          let accessToken = result.getAccessToken().getJwtToken();
          let payload = result.getAccessToken().decodePayload();

          let adminLogin = payload["cognito:groups"]
            ? payload["cognito:groups"].includes("admin")
            : false;
          let Logins = {};
          Logins[AWSCognitoLink] = result.getIdToken().getJwtToken();
          let credentialParams = {
            IdentityPoolId,
            Logins,
          };
          let credentials = new AWS.CognitoIdentityCredentials(
            credentialParams
          );
          //clearCachedId is an instance method and it doesn't clear the instance's information you've got to re-initialize the instance after clearing the cache
          credentials.clearCachedId();
          credentials = new AWS.CognitoIdentityCredentials(credentialParams);
          AWS.config.credentials = credentials;
          //refreshes credentials using AWS.CognitoIdentity.getCredentialsForIdentity()
          AWS.config.credentials.refresh((err) => {
            if (err) {
              dispatch(loginError(err.message));
              return reject(err.message);
            } else {
              // Instantiate aws sdk service objects now that the credentials have been updated.
              // example: var s3 = new AWS.S3();
              cognitoUser.getUserAttributes((err, attributes) => {
                if (err) {
                  dispatch(loginError(err.message));
                  return reject(err.message);
                }

                let subscription = {};
                attributes.forEach((attribute) => {
                  // expired date
                  if (attribute.getName() === "custom:sub_expiry_date")
                    subscription.expiredDate = attribute.getValue();
                  // subscription type
                  if (attribute.getName() === "custom:subscription_level")
                    subscription.type = attribute.getValue();
                });
                localStorage.setItem("id_token", accessToken);
                localStorage.setItem("userName", cognitoUser.getUsername());
                localStorage.setItem("adminLogin", adminLogin);
                localStorage.setItem(
                  "subscription",
                  JSON.stringify(subscription)
                );
                setAuth(accessToken)
                dispatch(receiveLogin(accessToken, payload, subscription));
                return resolve({
                  userName: cognitoUser.getUsername(),
                  id_token: accessToken,
                });
              });
            }
          });
        },

        onFailure: function (err) {
          dispatch(loginError(err.message));
          return reject(err);
        },
      });
    });
  };
}

/**
 * Resend verification code
 * @param params.userName-required
 * @returns {function(*): Promise}
 */
export function resendCode(params) {
  let userName = params.userName;

  let userData = {
    Username: userName,
    Pool: userPool,
  };
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      let cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData);
      cognitoUser.resendConfirmationCode(function (err, result) {
        if (err) {
          reject(err.message);
        }
        resolve(result);
      });
    });
  };
}

/**
 * Retrive user profile
 * Require: User authenticated
 * @returns {Promise<unknown>}
 */
export function retriveUser() {
  let cognitoUser = userPool.getCurrentUser();
  if (cognitoUser != null) {
    return new Promise((resolve, reject) => {
      cognitoUser.getSession(function (err, session) {
        if (err) {
          alert(err.message || JSON.stringify(err));
          return reject(err.message);
        }

        // NOTE: getSession must be called to authenticate user before calling getUserAttributes
        cognitoUser.getUserAttributes(function (err, attributes) {
          if (err) {
            return reject(err);
          } else {
            let data = { userName: cognitoUser.getUsername() };
            attributes.forEach((attribute) => {
              if (attribute.getName() === "email")
                data.email = attribute.getValue();
              if (attribute.getName() === "phone_number")
                data.phone = attribute.getValue();
              if (attribute.getName() === "address")
                data.address = attribute.getValue();
              // expired date
              if (attribute.getName() === "birthdate")
                data.expiredDate = attribute.getValue();
              // subscription type
              if (attribute.getName() === "custom:subscription_level")
                data.subscriptionType = attribute.getValue();
            });
            return resolve(data);
          }
        });
        let Logins = {};
        Logins[AWSCognitoLink] = session.getIdToken().getJwtToken();
        AWS.config.credentials = new AWS.CognitoIdentityCredentials({
          IdentityPoolId, // your identity pool id here
          Logins,
        });
        // Instantiate aws sdk service objects now that the credentials have been updated.
        // example: var s3 = new AWS.S3();
      });
    });
  }
}

/**
 * Update user profile
 * @param params.phone params.address params.oldPassword params.newPassword
 * @returns {Promise<unknown>}
 */

export function updateUser(params) {
  let attributeList = [];
  let updateAttribute = false;
  if (params.phone) {
    let attribute = new AmazonCognitoIdentity.CognitoUserAttribute({
      Name: "phone_number",
      Value: params.phone,
    });
    attributeList.push(attribute);
    updateAttribute = true;
  }
  if (params.expiredDate) {
    let attribute = new AmazonCognitoIdentity.CognitoUserAttribute({
      Name: "custom:sub_expiry_date",
      Value: params.expiredDate,
    });
    attributeList.push(attribute);
    updateAttribute = true;
  }
  if (params.subscriptionType) {
    let attribute = new AmazonCognitoIdentity.CognitoUserAttribute({
      Name: "custom:subscription_level",
      Value: params.subscriptionType,
    });
    attributeList.push(attribute);
    updateAttribute = true;
  }
  if (params.address) {
    let attribute = new AmazonCognitoIdentity.CognitoUserAttribute({
      Name: "address",
      Value: params.address,
    });
    attributeList.push(attribute);
    updateAttribute = true;
  }

  if (params.email) {
    let attribute = new AmazonCognitoIdentity.CognitoUserAttribute({
      Name: "email",
      Value: params.email,
    });
    attributeList.push(attribute);
    updateAttribute = true;
  }

  let oldPassword, newPassword;
  let updatePassword = false;
  if (params.oldPassword && params.newPassword) {
    oldPassword = params.oldPassword;
    newPassword = params.newPassword;
    updatePassword = true;
  }

  let cognitoUser = userPool.getCurrentUser();

  if (!cognitoUser) return Promise.reject("No login");
  return new Promise((resolve, reject) => {
    cognitoUser.getSession(getSessionCallback);
    /**
     * @param {Error} err
     * @param {AmazonCognitoIdentity.CognitoUserSession} session
     */
    async function getSessionCallback(err, session) {
      if (err) {
        return reject(err.message);
      }

      if (params.email) {
        try {
          let AccessToken = session.getAccessToken().getJwtToken();
          let payload = session.getIdToken().decodePayload();
          if (payload.email_verified && payload.email === params.email) {
            throw new Error("Email provided is not a new one")
          }

          const resp = await fetch("/api/cognito/verify-email", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            credentials: "include",
            body: JSON.stringify({
              AccessToken,
              email: params.email,
            }),
          });
          const isValid = await resp.text();
          if (isValid !== "true") throw new Error("Email has been taken")
        } catch (err) {
          return reject(err.message);
        }
      }

      if (updateAttribute) {
        cognitoUser.updateAttributes(attributeList, function (err, result) {
          if (err) {
            return reject(err.message);
          }
          return resolve(result);
        });
      }
      if (updatePassword) {
        cognitoUser.changePassword(
          oldPassword,
          newPassword,
          function (err, result) {
            if (err) {
              return reject(err.message);
            }
            return resolve(result);
          }
        );
      }
      // resolve(result);
    }
  });
}

/**
 * User logout
 * @returns {function(...[*]=)}
 */
export function logout() {
  return (dispatch) => {
    dispatch(requestLogout());
    let cognitoUser = userPool.getCurrentUser();

    if (cognitoUser != null) {
      return new Promise((resolve, reject) => {
        cognitoUser.getSession(function (err, session) {
          if (err) {
            reject(err.message);
          }
          cognitoUser.signOut();
        });

        localStorage.removeItem("id_token");
        localStorage.removeItem("userName");
        localStorage.removeItem("adminLogin");
        localStorage.removeItem("subscription");
        document.cookie = "id_token=;expires=Thu, 01 Jan 1970 00:00:01 GMT;";
        dispatch(receiveLogout());
        resolve();
      });
    }
  };
}

/**
 * Reset Password
 * @param params.userName
 * @returns {function(*): Promise<unknown>}
 */
export function resetPassword(params) {
  let userName = params.userName;
  let userData = {
    Username: userName,
    Pool: userPool,
  };
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      let cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData);
      cognitoUser.forgotPassword({
        onSuccess: function (data) {
          // successfully initiated reset password request
          resolve(data);
        },
        onFailure: function (err) {
          alert(err.message || JSON.stringify(err));
          reject(err.message);
        },
      });
    });
  };
}

/**
 * Confirm reset password
 * @param params.userName params.code params.newPassword
 * @returns {function(*): Promise<unknown>}
 */
export function confirmReset(params) {
  let userName = params.userName;
  let userData = {
    Username: userName,
    Pool: userPool,
  };
  let cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData);
  let code = params.code;
  let newPassword = params.newPassword;
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      cognitoUser.confirmPassword(code, newPassword, {
        onSuccess() {
          resolve();
        },
        onFailure(err) {
          reject(err.message);
        },
      });
    });
  };
}

export function isAuthenticated() {
  if (store.getState().auth.isAuthenticated) return true;
  let token = localStorage.getItem("id_token");
  if (!config.isBackend && token) return true;
  if (!token) return false;
  const date = new Date().getTime() / 1000;
  const data = jwt.decode(token);
  return date < data.exp;
}

export function isAdmin() {
  if (store.getState().auth.userGroup === "admin") return true;
  return localStorage.getItem("adminLogin") === "true";
}

export function isValidSubscription() {
  // check global state
  let subscription =
    store.getState().auth.subscription ||
    JSON.parse(localStorage.getItem("subscription"));

  // check local storage
  /** @type {{expiredDate: string}} */
  if (!subscription) return false;
  let { expiredDate } = subscription;

  if (!expiredDate) return false;

  let currentDate = new Date().getTime();
  let parts = expiredDate.split("-");
  let expiry = new Date(parts[2], parts[1] - 1, parts[0]).getTime();
  return currentDate < expiry;
}
