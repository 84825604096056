import Axios, { CancelTokenSource } from "axios";
import React, { useEffect, useMemo } from "react";
import { withRouter } from "react-router";
import { Spinner } from "reactstrap";
import { fetchSensorsByField, Sensor } from "src/actions/v2/sensor";
import DropDownSelector from "src/components/DropDownSelector";
import { useAppDispatch, useAppSelector } from "src/store";
import { IComponentType, RouteState } from "src/types";

type SensorSelectorProps = {
  classname?: string;
  queryKey?: keyof Pick<RouteState, "sensor" | "compareSensor">;
};

const SensorSelector: IComponentType<SensorSelectorProps> = ({
  history,
  location,
  classname,
  queryKey = "sensor",
}) => {
  const dispatch = useAppDispatch();
  const { isFetching, sensors } = useAppSelector((state) => state.sensor);
  const search = useMemo(
    () => new URLSearchParams(location.search),
    [location.search]
  );

  useEffect(() => {
    if (
      (location.state?.[queryKey] as Sensor)?.sensor_id !==
      (search.get(queryKey) || undefined)
    ) {
      history.replace({
        ...location,
        state: {
          ...location.state,
          [queryKey]: sensors?.find(
            (s) => s.sensor_id === search.get(queryKey)
          ),
        },
      });
    }

    if (!sensors || sensors.some((s) => s.field_id !== search.get("field")))
      return;

    if (sensors.length === 0) {
      if (search.has(queryKey)) {
        search.delete(queryKey);
        history.replace({ ...location, search: search.toString() });
      }
    } else if (!search.has(queryKey)) {
      search.set(queryKey, sensors[0].sensor_id);
      history.replace({ ...location, search: search.toString() });
    } else if (
      sensors.find((s) => s.sensor_id === search.get(queryKey))?.field_id !==
      search.get("field")
    ) {
      search.set(queryKey, sensors[0].sensor_id);
      history.replace({ ...location, search: search.toString() });
    }
  }, [history, location, search, sensors, queryKey]);

  useEffect(() => {
    const source = Axios.CancelToken.source();;
    if (!search.has("field")) return;

    let fieldId = search.get("field");
    if (!fieldId) return;
    if (location.state?.field?.field_id === fieldId) return;
    if (isFetching) return;
    if (!sensors || !sensors.some((s) => s.field_id === fieldId)) {
      dispatch(fetchSensorsByField(fieldId, { cancelToken: source.token }));
    }

    // return () => source.cancel();
    // TODO: find a way to cancel double fetching

  }, [search, location.state, sensors, dispatch, isFetching]);

  if (isFetching) return <Spinner />;

  if (!sensors) return <></>;

  if (sensors.length === 0)
    return (
      <DropDownSelector
        chosenItem={{ sensor_id: "", alias: "No Sensors" }}
        disabled
        items={[{ sensor_id: "", alias: "No Sensors" }]}
        keyForText={"alias"}
        keyForValue={"sensor_id"}
        onSelect={() => { }}
      />
    );

  const chosenSensor = location.state?.[queryKey] as Sensor;

  const onSensorSelect = (sensorId: string | null) => {
    if (sensorId) search.set(queryKey || "sensor", sensorId);
    else search.delete(queryKey);
    history.push({ ...location, search: search.toString() });
  };

  return (
    <DropDownSelector
      chosenItem={chosenSensor || { sensor_id: "", alias: null }}
      items={sensors.filter((s) => s.sensor_id !== chosenSensor?.sensor_id)}
      // disabled={chosenSensor.sensor_id === EMPTY_SENSOR_DATA.sensor_id}
      onSelect={(e) => {
        onSensorSelect(e.currentTarget.ariaValueText);
      }}
      keyForText={"alias"}
      keyForValue={"sensor_id"}
      classname={classname}
    />
  );
};

export default withRouter(SensorSelector);
