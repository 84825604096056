import Axios, { AxiosRequestConfig } from "axios";
import axiosInstance from "../axios/global";
import { EMPTY_FIELD_DATA } from "./field";
import { PredictionData } from "./prediction";

export type MoistureData = {
  battery_vol: number;
  temperature: number;

  cap50: number;
  cap100: number;
  cap150: number;
  cap50_percent: number;
  cap100_percent: number;
  cap150_percent: number;

  geom?: string;
  point: { type: string; coordinates: number[] };
  latitude?: number;
  longitude?: number;

  time: string;
  sensor_id: string;
};

export type SupportedFormat = "csv" | "data";

export const fetchMoistureDataBySensor = async <T>(
  sensor: string,
  field: string,
  query?: {
    limit?: number;
    queryCols?: (keyof MoistureData)[];
    start?: string; // epochtime
    end?: string; // epochtime
    format?: SupportedFormat; // csv or data
  },
  config?: AxiosRequestConfig
) => {
  return axiosInstance
    .get<T>(
      `api/v2/moisture/${field}/${sensor}`,
      { ...config, params: query }
    )
    .then((res) => Promise.resolve(res.data))
    .catch((err) => Promise.reject(err.response.data.err));
};

export type MoistureDataContent = Omit<MoistureData, "sensor_id">;

export type FieldMoistureData = {
  [sensorId: string]: {
    moisture: MoistureDataContent[];
    prediction: PredictionData[];
  };
};

/**
 * Data returned by this action is in the ascending order in terms of time
 * @param fieldId
 * @param config
 * @returns
 */
export const fetchMoistureDataByField = async (
  fieldId: string,
  config?: AxiosRequestConfig
) => {
  if (fieldId === EMPTY_FIELD_DATA.field_id) return Promise.resolve({} as FieldMoistureData);
  return Axios.get<{ data: FieldMoistureData }>(
    `/api/v2/moisture/${fieldId}`,
    config
  )
    .then(({ data }) => Promise.resolve(data.data))
    .catch((err) => Promise.reject(err.response.data.err));
};
