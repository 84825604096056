import React, { useEffect, useState } from "react";
import { useDashboardHook } from "../../hooks/useDashboardHooks";
import {
  EvaporationDataEntryType,
  fetchEvaporation,
} from "src/actions/v2/evaporation";
import {
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  XAxis,
  YAxis,
  Tooltip,
} from "recharts";
import Axios from "axios";
import { Spinner } from "reactstrap";
import { EMPTY_FIELD_DATA } from "src/actions/v2/field";

const EvaporationChart = () => {
  const { chosenField } = useDashboardHook();
  const [data, setData] = useState<EvaporationDataEntryType[]>();
  const [isFetching, setFetching] = useState(false);

  useEffect(() => {
    if (chosenField === EMPTY_FIELD_DATA) return;
    const source = Axios.CancelToken.source();
    setFetching(true);
    fetchEvaporation(chosenField.field_id!, {}, { cancelToken: source.token })
      .then(setData)
      .catch((err) => console.log(err))
      .finally(() => setFetching(false));
    return () => source.cancel();
  }, [chosenField]);

  return (
    <ResponsiveContainer height={400} width={"100%"}>
      {isFetching || !data ? (
        <div className="d-flex flex-column h-100 justify-content-center ">
          <Spinner className="mx-auto d-block" size="lg" />
        </div>
      ) : (
        <LineChart
          data={data.map((d) => {
            let [m, day, y] = d.date.split('-').map(Number)
            return {
              value: d.evaporation,
              time: new Date(Date.UTC(y, m - 1, day)).toISOString().split("T")[0]
            }
          })}
          margin={{ top: 50, right: 20, left: 20, bottom: 0 }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="time" height={80} />
          <YAxis width={80} yAxisId="left" unit="mm" />
          <YAxis yAxisId="right" orientation="right" />
          <Line
            yAxisId="left"
            type="monotone"
            dataKey="value"
            stroke="#6445b6"
            strokeWidth={2}
            dot={false}
            name="Evaporation"
          />
          <Tooltip
            formatter={(value, _) =>
              typeof value === "number" ? value.toFixed(2) : value
            }
          />
        </LineChart>
      )}
    </ResponsiveContainer>
  );
};

export default EvaporationChart;
