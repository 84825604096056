import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

import s from "./Footer.module.scss";

class Footer extends React.Component {
  static propTypes = {
    className: PropTypes.string,
  };

  static defaultProps = {
    className: "",
  };

  render() {
    return (
      <footer className={cx(s.root, this.props.className)}>
        <div className={s.container}>
          <span>© {new Date().getFullYear()} &nbsp;AquaTerra Tech. </span>
          <span className={s.spacer}>·</span>
          <a href="https://aquaterra.cloud/" target="_blank" rel="noopener noreferrer">
            About Us
          </a>
          <span className={s.spacer}>·</span>
          <a href="mailto:support@aquaterra.cloud">Support</a>
        </div>
      </footer>
    );
  }
}

export default Footer;
