import React, { useState } from "react";
import { ButtonDropdown } from "reactstrap";

interface DropdownMenuInterface {
  children?: React.ReactNode;
  className?: string;
}

const ButtonDropdownMenu: React.FC<DropdownMenuInterface> = ({ children, className }) => {
  const [isOpen, setOpen] = useState(false);
  return (
    <ButtonDropdown
      isOpen={isOpen}
      toggle={() => {
        setOpen((prev) => !prev);
      }}
      className={className || ""}
    >
      {children}
    </ButtonDropdown>
  );
};

export default ButtonDropdownMenu;
