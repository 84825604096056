import React, { Component } from "react";
import { Alert, Button, Form, FormGroup, Input, Modal, ModalHeader, ModalBody } from "reactstrap";
import { confirmUser, resendCode } from "../../actions/cognito";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

const mapDispatchToProps = (dispatch) => ({
  confirmUser: (params) => dispatch(confirmUser(params)),
  resendCode: (params) => dispatch(resendCode(params)),
});

class Confirm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showSuccess: false,
      showResendSuccess: false,
      errorMessage: null,
      code: "",
      isModalOpen: true,
      isFetching: false,
    };
    this.toggleModal = this.toggleModal.bind(this);
  }

  changeVal = (event, key) => {
    this.setState({ [key]: event.target.value });
  };

  toggleModal() {
    this.setState({ isModalOpen: !this.state.isModalOpen });
  }

  doConfirm = (e) => {
    e.preventDefault();
    this.setState({ isFetching: true });
    this.props
      .confirmUser({
        userName: this.props.username,
        // userName: "test",
        code: this.state.code,
      })
      .then((data) => {
        this.setState({ showSucess: true });
        this.toggleModal();
        setTimeout(() => {
          this.props.jumpToLogin();
        }, 1500);
      })
      .catch((errorMessage) => {
        this.setState({ errorMessage });
      })
      .finally(() => {
        this.setState({ isFetching: false });
      });
  };

  doResend = (e) => {
    e.preventDefault();
    this.setState({ isFetching: true });
    this.props
      .resendCode({
        userName: this.props.username,
        // userName: "test",
      })
      .then((data) => {
        this.setState({ showResendSuccess: true });
        setTimeout(() => {
          this.setState({ showResendSuccess: false });
        }, 3000);
      })
      .catch((errorMessage) => {
        this.setState({ errorMessage });
      })
      .finally(() => {
        this.setState({ isFetching: false });
      });
  };

  render() {
    return (
      <Modal isOpen={this.state.isModalOpen}>
        <ModalHeader>Confirm Your Account</ModalHeader>
        <ModalBody>
          <p className="fs-sm text-light">Please enter in the verification code we just sent to your email.</p>
          <Form className="mt" onSubmit={this.doConfirm}>
            {this.state.errorMessage && (
              <Alert size="sm" className="bg-danger font-weight-bold text-white border-0">
                * {this.state.errorMessage}
              </Alert>
            )}
            {this.state.showSucess && (
              <Alert size="sm" className="bg-success font-weight-bold text-white border-0">
                * Account Verified.
              </Alert>
            )}
            {this.state.showResendSuccess && (
              <Alert size="sm" className="bg-success font-weight-bold text-white border-0">
                * New verification code has been sent.
              </Alert>
            )}
            <FormGroup>
              <Input
                className="no-border"
                value={this.state.passwordConfirm}
                onChange={(e) => this.changeVal(e, "code")}
                type="code"
                required
                name="code"
                placeholder="Verification Code"
              />
            </FormGroup>
            <div className="d-flex justify-content-between align-content-around">
              <Button color="primary" size="md" type="button" onClick={this.toggleModal}>
                Close
              </Button>
              <Button
                // disabled={!submitEnabled}
                color="danger"
                size="md"
                type="button"
                onClick={this.doResend}
              >
                Resend
              </Button>
              <Button
                // disabled={!submitEnabled}
                color="success"
                size="md"
                type="submit"
              >
                {this.state.isFetching ? "Loading..." : "Submit"}
              </Button>
            </div>
          </Form>
        </ModalBody>
      </Modal>
    );
  }
}

export default withRouter(connect(undefined, mapDispatchToProps)(Confirm));
