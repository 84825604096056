import React, { useState, useMemo, useEffect, useCallback } from "react";
import { ShowingControlType } from "./DetailedMap";
import { useFieldMoistureDataContext } from "../../hooks/useFieldMoistureDataContext";
import { Circle, GoogleMap, InfoWindow, Marker, Polygon } from "react-google-maps";
import { COLOR_SCALE } from "./MapControls";
import { MoistureDataContent } from "src/actions/v2/moisture";
import { useParamSearch } from "../../hooks/params";
import { useAppSelector } from "src/store";
import { IComponentType } from "src/types";
import { withRouter } from "react-router";
import { getCenter } from "../../util";

const ValueRange: Record<ShowingControlType, number[]> = {
  battery_vol: [0, 4],
  temperature: [-40, 60],
  cap50_percent: [0, 100],
  cap100_percent: [0, 100],
  cap150_percent: [0, 100],
  info: [],
};

const formatter = {
  battery_vol: (val) => val,
  temperature: (val) => val,
  cap50_percent: (val: number) => Math.max(+val.toFixed(1), 0),
  cap100_percent: (val: number) => Math.max(+val.toFixed(1), 0),
  cap150_percent: (val: number) => Math.max(+val.toFixed(1), 0),
} as Record<ShowingControlType, <T>(val: T) => T>;

const units = {
  battery_vol: "V",
  temperature: "℃",
  cap50_percent: "%",
  cap100_percent: "%",
  cap150_percent: "%",
} as Record<ShowingControlType, string>;

const Map: IComponentType<{
  showingControl: ShowingControlType;
  timeToken: number;
  map: React.RefObject<GoogleMap>;
}> = ({ showingControl, timeToken, map, location }) => {
  const { data, zones, getMoistureData } = useFieldMoistureDataContext();
  const sensors = useAppSelector((state) => state.sensor.sensors);
  const { sensor, field } = useMemo(() => location.state, [location])
  const [showSensorInfoWindow, setShowSensorInfoWindow] = useState<{
    [k: string]: boolean;
  }>({});
  const { setSearch } = useParamSearch();

  const valuesOnMap = useMemo(() => {
    let result = Object.entries(data).map(([sensorId, sensorData]) => {
      return [
        sensorId,
        sensorData.moisture.findLast(
          (v) => new Date(v.time) < new Date(timeToken)
        ),
      ];
    });
    return Object.fromEntries(result) as {
      [sensorId: string]: MoistureDataContent;
    };
  }, [data, timeToken]);

  const showInfoForSensor = useCallback(
    (sensorId?: string) =>
      Object.fromEntries(Object.keys(data).map((k) => [k, k === sensorId])),
    [data]
  );

  useEffect(() => {
    if (!sensor) return;
    setShowSensorInfoWindow(showInfoForSensor(sensor.sensor_id));
  }, [showInfoForSensor, sensor]);

  const onSensorCircleClick = (sensorId: string) => {
    setSearch("sensor", sensorId);
  };

  if (showingControl === "info") return <></>;
  if (!sensors) return <></>;
  if (!field) return <></>

  return (
    <>
      <Polygon
        options={{
          strokeColor: "#369BF7",
          fillOpacity: 0,
          zIndex: -1,
        }}
        path={field.coordinates}
      />
      {Object.entries(valuesOnMap).map(([sensorId, data], i) => {
        if (!data) return <React.Fragment key={i}></React.Fragment>;

        let colorIndex = -1;
        const val = data[showingControl];
        const zone = getMoistureData(sensorId).zoneProfile;
        if (showingControl.startsWith("cap") && !!zone) {
          const range = (
            {
              cap50_percent: {
                wp: zone.wpoint_50,
                fc: zone.fcapacity_50,
                sat: zone.saturation_50,
              },
              cap100_percent: {
                wp: zone.wpoint_100,
                fc: zone.fcapacity_100,
                sat: zone.saturation_100,
              },
              cap150_percent: {
                wp: zone.wpoint_150,
                fc: zone.fcapacity_150,
                sat: zone.saturation_150,
              },
            } as Record<
              ShowingControlType,
              { wp: number; fc: number; sat: number }
            >
          )[showingControl];
          if (val < range.wp) colorIndex = 0;
          else if (val < range.fc) colorIndex = 1;
          else if (val < range.sat) colorIndex = 2;
          else colorIndex = 3;
        } else {
          const range = ValueRange[showingControl];
          colorIndex = Math.floor(
            (val - range[0]) / ((range[1] - range[0]) / 4)
          );
        }

        const center = {
          lat: data.point.coordinates[1]!,
          lng: data.point.coordinates[0]!,
        };
        return (
          <React.Fragment key={i}>
            <Circle
              radius={0.2 * 2 ** (22 - (map.current?.getZoom() || 17))}
              options={{
                fillColor: COLOR_SCALE[showingControl][colorIndex],
                fillOpacity: 0.8,
                strokeColor: COLOR_SCALE[showingControl][colorIndex],
                strokeWeight: 3,
              }}
              center={center}
              onClick={() => onSensorCircleClick(sensorId)}
            />
            {showSensorInfoWindow[sensorId] && (
              <InfoWindow
                position={center}
                onCloseClick={() =>
                  setShowSensorInfoWindow((prev) => ({
                    ...prev,
                    [sensorId]: false,
                  }))
                }
              >
                <span>
                  {sensors.find((s) => s.sensor_id === sensorId)?.alias ||
                    sensorId}
                  <br />
                  {`${formatter[showingControl](val)}${units[showingControl]}`}
                </span>
              </InfoWindow>
            )}
          </React.Fragment>
        );
      })}

      {zones.map((zone, i) => (
        <Polygon
          key={i}
          options={{
            strokeColor: "#bd377a",
            fillOpacity: 0,
            zIndex: -1,
          }}
          path={zone.points}
        />
      ))}
    </>
  );
};

export const MoistureDataMap = withRouter(Map);