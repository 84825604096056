import { Reducer } from "redux";
import { TOGGLE_SIDEBAR, OPEN_SIDEBAR, CLOSE_SIDEBAR } from "../constants";

const initialState = {
  sidebarOpened: false,
  sidebarStatic: false,
};

const runtime: Reducer<typeof initialState, any> = (state=initialState, action) => {
  switch (action.type) {
    case TOGGLE_SIDEBAR:
      return {
        ...state,
        sidebarOpened: !state.sidebarOpened,
      };
    case OPEN_SIDEBAR:
      return {
        ...state,
        sidebarOpened: true,
      };
    case CLOSE_SIDEBAR:
      return {
        ...state,
        sidebarOpened: false,
      };
    default:
      return state;
  }
} 

export default runtime