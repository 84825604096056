import React, { useState, useMemo, useEffect } from "react";
import { useFieldMoistureDataContext } from "../../hooks/useFieldMoistureDataContext";
import { MoistureData } from "src/actions/moisture";
import { FieldInfoMap } from "./FieldInfoMap";
import { TopCenterMapControl, SliderControl } from "./MapControls";
import { MoistureDataMap } from "./MoistureDataMap";
import { GoogleMap } from "react-google-maps";

export type ShowingControlType =
  | keyof Pick<
    MoistureData,
    | "battery_vol"
    | "cap50_percent"
    | "cap100_percent"
    | "cap150_percent"
    | "temperature"
  >
  | "info";

export const DetailedMap = ({ map }: { map: React.RefObject<GoogleMap> }) => {

  const { data } = useFieldMoistureDataContext();
  const [showingDataType, setShowingDataType] =
    useState<ShowingControlType>("cap50_percent");

  const [sliderValue, setSliderValue] = useState(-1);
  const timelines = useMemo(() => {
    if (!data) return [];

    return Object.values(data)
      .map((sensorData) =>
        sensorData.moisture.map((d) => new Date(d.time).getTime())
      )
      .flat()
      .sort();
  }, [data]);

  useEffect(() => {
    if (sliderValue === -1) setSliderValue(timelines.length - 1);
  }, [timelines, sliderValue]);

  return (
    <>
      <TopCenterMapControl
        showingDataType={showingDataType}
        setShowingDataType={setShowingDataType}
      />

      {!(showingDataType === "info") && (
        timelines.length > 0 && <SliderControl
          current={sliderValue !== -1 ? sliderValue : timelines.length - 1}
          setCurrent={setSliderValue}
          placeholder={new Date(
            timelines[sliderValue !== -1 ? sliderValue : timelines.length - 1]
          )
            .toString()
            .substring(0, 25)}
          max={timelines.length - 1}
        />
      )}

      {showingDataType === "info" ? (
        <FieldInfoMap />
      ) : (
        <MoistureDataMap
          showingControl={showingDataType}
          timeToken={timelines[sliderValue]}
          map={map}
        />
      )}
    </>
  );
};
